<template>
    <button
        class="btn-base btn-base_normal"
        :class="[disabledBtn, animatedBtn, defaultBtn, smallBtn, largeBtn, hoverBtn, classes]"
        :style="{width, height, minWidth: width}"
        :type="type"
        :tabindex="tabindex"
        @click="buttonClick"
    >
        <template v-if="loading">
            <button-loader
                :message="loadingMessage"
            />
            <span>
                {{ loadingMessage }}
            </span>
        </template>
        <template v-else>
            <slot />
        </template>
    </button>
</template>

<script>
import ButtonLoader from '@/components/common/ButtonLoader.vue';

export default {
    name: 'CustomButton',
    components: {
        ButtonLoader
    },
    props: {
        tabindex: {},
        loading: {
            type: Boolean
        },
        loadingMessage: {
            type: String,
            required: false
        },
        height: {
            type: String
        },
        width: {
            type: String
        },
        type: {
            type: String
        },
        classes: {
            type: String
        },
        disabled: Boolean,
        animated: Boolean,
        default: Boolean,
        bordered: Boolean,
        small: Boolean,
        large: Boolean,
        hover: Boolean
    },
    computed: {
        disabledBtn() {
            return this.disabled ? 'btn-base_normal btn-base_disabled' : ''
        },
        animatedBtn() {
            return this.animated ? 'btn-base_orange cart-btn-empty cart-btn-animated' : ''
        },
        defaultBtn() {
            return this.default ? 'default-button' : ''
        },
        smallBtn() {
            return this.small ? 'small-btn' : ''
        },
        largeBtn() {
            return this.large ? '' : ''
        },
        hoverBtn() {
            return this.hover ? 'hovered' : ''
        }
    },
    methods: {
        buttonClick() {
            this.$emit('on-btn-click')
        }
    }
}
</script>

<style lang="scss">
    .btn-base {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .btn-base.hovered {
        transition: .4s;
        &:hover {
            color: #fff;
            background: #ff7426 !important;
            border: 2px solid #ff7426;
            svg {
                color: #fff;
            }
        }
    }
    .small-btn {
        margin: 5px 5px 5px auto;
        background: transparent!important;
        border: 1px solid #e9e9e9;
        padding: 0;
        svg {
            width: 15px;
            color: #ff7426;
        }
    }
    .default-button {
        min-width: 150px;
        height: 48px;
        border-radius: 4px;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
        border: none !important;
        color: #000;
        background-color: #efefef;
    }
    .blue-button-outline {
        color: #1976D2;
        border: 1px solid #1976D2 !important;
        background: #fff;
    }
    .black-button-outline {
        color: #4F4F4F;
        border: 1px solid #4F4F4F !important;
        background: #fff;
    }
    .blue-button {
        background: #1976D2;
        color: #FFFFFF;
    }
    .grey-button {
        background: #F2F2F2;
        color: #1F2939;
    }
    .orange-button {
        background: $orange;
        color: #fff;
    }
    .orange-button-outline {
        background: #fff;
        color: $orange;
        border: 1px solid $orange !important;
    }
    .text-button {
        &.color-orange:hover {
            color: $orange;
        }
        &.color-blue:hover {
            color: #1976D2;
        }
    }

</style>
