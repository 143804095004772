<template>
    <tooltip-component :text="row.filename">
        <div class="row-filename">
            <span>
                {{ row.filename }}
            </span>
            <a
                :href="downloadLink"
                :download="filename"
                target="_blank"
                class="download_btn"
            >
                <Download />
            </a>
        </div>
    </tooltip-component>
</template>

<script>
import Download from 'mdi-vue/Download';
import TooltipComponent from '@/components/TooltipComponent';

export default {
    name: 'RowFileName',
    components: {
        TooltipComponent,
        Download
    },
    props: {
        row: Object
    },
    computed: {
        downloadLink() {
            return `${process.env.VUE_CDN_URL}/file/${this.row.file_id}`
        }
    }
}
</script>

<style lang="scss">
.row-filename {
    max-width: 100%;
    span {
        display: inline-block;
        max-width: 80%;
        white-space: nowrap;
        overflow-x: hidden;
        line-height: 22px;
        text-overflow: ellipsis;
        padding-right: 10px;
    }
}
.download_btn{
    border: none;
    background: transparent;
    color: #000;
    &:visited{
        color: #000;
    }
}
</style>
