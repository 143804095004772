export const SET_LOADING = 'SET_LOADING'

export const SET_TASKS_LIST = 'SET_TASKS_LIST'
export const SET_STATUSES_LIST = 'SET_STATUSES_LIST'
export const SET_PRIORITIES_LIST = 'SET_PRIORITIES_LIST'

export const SET_TASK = 'SET_TASK'
export const CLEAR_FORM_DATA = 'CLEAR_FORM_DATA'

export const CREATE_TASK = 'CREATE_TASK'
export const DELETE_TASKS = 'DELETE_TASKS'
export const UPDATE_TASKS_ITEMS = 'UPDATE_TASKS_ITEMS'
export const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA'
export const UPDATE_TASK = 'UPDATE_TASK'

export const SET_COMMENT = 'SET_COMMENT'
export const DELETE_COMMENT = 'DELETE_COMMENT'
export const EDIT_COMMENT = 'EDIT_COMMENT'

export const TAKE_TASK = 'TAKE_TASK'
export const FINALIZE_TASK = 'FINALIZE_TASK'
export const REMOVE_IS_UPDATE = 'REMOVE_IS_UPDATE'
