export const LOGIN_ADMIN = 'LOGIN_ADMIN'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const SET_ADMIN_TIMEZONE = 'SET_ADMIN_TIMEZONE'
export const LOGOUT_ADMIN = 'LOGOUT_ADMIN'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const UPDATE_USER_CATEGORIES = 'UPDATE_USER_CATEGORIES'
export const CREATE_USER_CATEGORIES = 'CREATE_USER_CATEGORIES'
export const DELETE_USER_CATEGORIES = 'DELETE_USER_CATEGORIES'

export const RESET = 'RESET'
export const SET_PHONE_CLIENT = 'SET_PHONE_CLIENT'
export const SET_PHONE_CODE_AND_COUNTRY = 'SET_PHONE_CODE_AND_COUNTRY'
export const UPDATE_ADMIN_DATA = 'UPDATE_ADMIN_DATA'
