export default [
    {
        icon: 'account-circle',
        text: 'Profile',
        route: 'user-profile'
    },
    {
        permission: 'menu/manage',
        icon: 'pencil',
        text: 'Menu Editor',
        route: 'menu-editor'
    },
    {
        permission: 'menu/manage',
        icon: 'pencil',
        text: 'Activity log',
        route: 'activity-log'
    }
]
