<template>
    <div class="navbar">
        <div class="navbar-logo_block">
            <div
                class="navbar-burger"
                @click="toggleSidebar"
            >
                <close v-if="isShowedSidebar" />
                <burger v-else />
            </div>
            <div class="navbar-logo">
                <img
                    :src="`/img/logos/${getterLogo}`"
                    alt=""
                >
            </div>
        </div>
        <div class="navbar-title">
            <span>
                <slot name="title">
                    {{ currentContentType }}
                    <span
                        v-if="recordsCounter"
                        class="navbar-title-counter"
                    >
                        {{ recordsCounter }}
                    </span>
                    <span
                        v-if="link && link.routeAdd && (!link.edit_permission || permissions.includes(link.edit_permission))"
                        class="navbar-title__subheading__icon navbar-title-panel__subheading-add"
                        @click="createLinkPost(link)"
                    >
                        <span>
                            <cross />
                        </span>
                    </span>
                    <span
                        v-if="link && findUnfinishedSession(link.name) && (!link.edit_permission || permissions.includes(link.edit_permission))"
                        class="navbar-title__subheading__icon navbar-title-panel__subheading-draft"
                        @click="toUnfinishedSession(link)"
                    >
                        <span>
                            <outline />
                        </span>
                    </span>
                </slot>
            </span>
        </div>
        <div class="navbar-buttons">
            <nav-buttons />
            <div class="bell">
                <bell
                    :width="30"
                    :height="27"
                />
            </div>
            <div class="navbaravatar">
                <img
                    v-if="user && user.avatar_url"
                    :src="user.avatar_url"
                    alt=""
                >
                <div
                    v-else
                    class="navbaravatar__letters"
                >
                    {{ avatarLetter }}
                </div>
                <div class="navbaravatar__menu">
                    <ul>
                        <li @click="toProfile">
                            <div class="menu__image">
                                <account-circle />
                            </div>
                            <div class="menu__content">
                                Profile
                            </div>
                        </li>
                        <li @click="openLogoutModal">
                            <div class="menu__image">
                                <logout />
                            </div>
                            <div class="menu__content">
                                SIGN OUT
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <confirm-modal
                :title="`Signout`"
                :content="'Do you really want to sign out?'"
                name="logout-modal"
                @onConfirmClick="logout('logout-modal')"
            />
            <confirm-modal
                :title="`Create and clear draft?`"
                :content="'The draft will be cleared. Are you sure?'"
                :name="'clear-draft-modal'"
                @onConfirmClick="clearDraft"
                @onCancelClick="cancelCreate"
            />
        </div>
    </div>
</template>

<script>
import Burger from '@/icons/Burger'
import Close from 'mdi-vue/Close'
import AccountCircle from 'mdi-vue/AccountCircle.vue'
import Logout from 'mdi-vue/Logout.vue'
import Bell from 'mdi-vue/Bell.vue'
import Cross from '@/icons/Cross'
import Outline from '@/icons/Outline';

import {
    ACTION_LOGOUT_ADMIN
} from '@/store/modules/auth/action-types';
import { createNamespacedHelpers, mapGetters, mapState } from 'vuex';
import modalsMixins from '@/mixins/modals/modalsMixins'
import NavButtons from './navbar/NavButtons'

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('auth');

export default {
    name: 'Navbar',
    components: {
        Burger,
        Close,
        AccountCircle,
        Logout,
        Bell,
        NavButtons,
        Cross,
        Outline
    },
    mixins: [modalsMixins],
    data() {
        return {
            recordsCounter: 0,
            currentContentType: '',
            isShowedSidebar: true,
            modalLink: null
        }
    },
    computed: {
        ...mapGetters('auth', {
            permissions: 'getterUserPermissions'
        }),
        ...mapState('auth', ['user']),
        avatarLetter() {
            if (this.user && this.user.firstname && this.user.lastname) {
                return this.user.firstname[0] + this.user.lastname[0]
            }
            return ''
        },
        getterLogo() {
            return `${process.env.VUE_APP_DOMAIN}.svg`
        },
        link() {
            return this.$route.meta?.link || {}
        },
        resetMutationModuleName() {
            if (!this.modalLink) {
                return ''
            }
            const contentType = this.modalLink.name[0].toUpperCase() + this.modalLink.name.substring(1)
            return `content${contentType}Edit`
        }
    },
    watch: {
        $route(val, oldVal) {
            if (val.name === oldVal.name) {
                return
            }
            this.recordsCounter = 0
            if (!val.meta?.contentType) {
                return
            }
            this.currentContentType = val.meta.contentType
        }
    },
    created() {
        this.$bus.$on('confirm', this.logout)
        this.$bus.$on('tableLoading', (val) => {
            this.recordsCounter = val
        })
    },
    methods: {
        ...mapAdminActions([ACTION_LOGOUT_ADMIN]),
        toggleSidebar() {
            this.isShowedSidebar = !this.isShowedSidebar
            this.$bus.$emit('toggleSidebar', this.isShowedSidebar)
        },
        toProfile() {
            this.$router.push({ name: 'user-profile' })
        },
        openLogoutModal() {
            this.$bus.$emit('openModal', 'logout-modal')
        },
        async logout(name) {
            if (name !== 'logout-modal') { return }
            this[ACTION_LOGOUT_ADMIN]()
                .then(() => {
                    window.localStorage.removeItem('vuex')
                    window.location.replace(`${window.location.origin}/login`)
                })
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        findUnfinishedSession(name) {
            const storeContentModule = name && this.$store.state[`content${this.capitalizeFirstLetter(name)}Edit`]
            if (storeContentModule && storeContentModule[name]) {
                return storeContentModule[name].unfinished_session === true
            }
            return false
        },
        findUnfinishedSessionUrl(name) {
            const storeContentModule = name && this.$store.state[`content${this.capitalizeFirstLetter(name)}Edit`]
            if (storeContentModule && storeContentModule[name]) {
                return storeContentModule[name].unfinished_url
            }
            return ''
        },
        toUnfinishedSession(link) {
            this.$router.push({ path: this.findUnfinishedSessionUrl(link.name) })
        },
        createLinkPost(link) {
            this.modalLink = null
            if (!this.findUnfinishedSessionUrl(link.name)) {
                this.$router.push({
                    name: link.routeAdd,
                    params: link.addParam
                })
                return
            }
            this.modalLink = link
            this.$bus.$emit('openModal', 'clear-draft-modal')
        },
        clearDraft() {
            this.$store.commit(`${this.resetMutationModuleName}/RESET_EDIT_FORM_DATA`)
            this.$store.commit(`${this.resetMutationModuleName}/RESET_CREATED_FORM_DATA`)
            this.$router.push({
                name: this.modalLink.routeAdd,
                params: this.modalLink.addParam
            })
        },
        cancelCreate() {
            this.modalLink = null
        }
    }
}
</script>

<style lang="scss">
.navbar-title__subheading__icon {
    position: relative;
    padding: 7px 10px;
    background: #eee;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
    & > span {
        color: #000 !important;
        display: inline-block;
    }
    path {
        fill: #000!important;
    }
}

.navbar-title-panel__subheading-add{
    & > span {
        transform: rotate(-45deg);
    }
}

.navbar-title-panel__subheading-draft {
    & > span {
        transform: translateY(4px);
    }
}

.navbar-edit__buttons {
    display: flex;
    align-items: center;
    button {
        margin-right: 10px;
    }
}
.bell, .navbaravatar {
    cursor: pointer;
    width: 70px;
    height: 56px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-top: 3px transparent solid;
    position: relative;
    &:hover {
        border-top: 3px #f44336 solid;
        box-shadow: 0 -15px 20px rgba(0,0,0,.15);
    }
}

.bell{
    color: #ffb334;
    &:after{
        content: '1';
        position: absolute;
        bottom: 13px;
        right: 16px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #ff2d2d;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        font-weight: 400;
    }
}
.navbaravatar {
    &:hover {
        .navbaravatar__menu {
            display: block;
        }
    }
    img, &__letters {
        border-radius: 50%;
        width: 36px;
    }
    &__letters{
        height: 36px;
        background: #f44336;
        color: #fff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
        font-weight: 400;
        letter-spacing: normal;
    }
    &__menu {
        width: 300px;
        left: calc(50% - 264px);
        position: absolute;
        top: 100%;
        background: #fff;
        border-radius: 0 0 4px 4px;
        height: 115px;
        border-bottom: 4px #f44336 solid;
        display: none;
        ul{
            padding-top: 10px;
            li{
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: left;
                &:first-of-type {
                    border-bottom: 1px #ccc solid;
                }
            }
        }
    }
    .menu {
        &__image {
            color: #4f4f4f;
            margin: auto 25px;
        }
        &__content {
            font-family: Mark Pro;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
        }
    }
}
</style>
