<template>
    <div class="navbar-edit__buttons">
        <custom-button
            v-if="!editing && showRevisionButton"
            default
            class="blue-button"
            @on-btn-click="createRevision"
        >
            Create revision
        </custom-button>
        <custom-button
            v-if="!editing && showEditButton"
            default
            class="blue-button"
            @on-btn-click="edit"
        >
            Edit
        </custom-button>
        <custom-button
            v-if="editing"
            default
            class="blue-button"
            @on-btn-click="create"
        >
            Save
        </custom-button>
        <custom-button
            v-if="editing"
            default
            class="grey-button"
            @on-btn-click="reset"
        >
            Reset
        </custom-button>
    </div>
</template>

<script>
export default {
    name: 'EditButtons',
    data() {
        return {
            editing: false,
            showRevisionButton: true,
            showEditButton: true
        }
    },
    mounted() {
        this.$bus.$on('updateNavEdit', (data) => {
            if (this.$route.name === data.routeName) this.editing = data.editing
        })
        this.$bus.$on('postWasSaved', () => {
            this.editing = false
        })
        this.$bus.$on('editingEnabled', () => {
            this.editing = true
        })
        this.$bus.$on('setRevisionVisible', (val) => {
            this.showRevisionButton = val
        })
        this.$bus.$on('setEditVisible', (val) => {
            this.showEditButton = val
        })
    },
    beforeDestroy() {
        this.$bus.$off('updateNavEdit')
    },
    methods: {
        createRevision() {
            this.$bus.$emit('createRevision', this.$route.name)
        },
        edit() {
            this.$bus.$emit('editPost', this.$route.name)
        },
        create() {
            this.$bus.$emit('createPost', this.$route.name)
        },
        reset() {
            this.$bus.$emit('resetPost', this.$route.name)
        }
    }
}
</script>

<style scoped>

</style>
