const TablePbn = () => import(
    '@/views/Seo/Pbn'
)

const CreatePbn = () => import(
    '@/views/Seo/CreatePbnPage'
)

const TableOutreach = () => import(
    '@/views/Seo/Outreach'
)

const CreateOutreach = () => import(
    '@/views/Seo/CreateOutreachPage'
)

export default [
    { // pbn-links-list
        path: '/admin/dashboard/view/seo-pages/pbn-links',
        component: TablePbn,
        name: 'posts-list-pbn',
        meta: {
            requiresAuth: true,
            permission: 'content/manage',
            contentType: 'pbn',
            link: {
                routeAdd: 'create-pbn'
            }
        },
        props: (route) => ({
            contentType: route.meta.contentType
        })
    },
    { // create-pbn
        path: '/admin/dashboard/create/post/pbn',
        component: CreatePbn,
        name: 'create-pbn',
        props: (route) => ({
            mode_type: route.meta.mode_type,
            contentType: route.meta.contentType
        }),
        meta: {
            requiresAuth: true,
            permission: 'content/manage',
            mode: 'create',
            contentType: 'pbn',
            link: {
                routeAdd: 'create-pbn'
            }
        }
    },
    { // pbn-links-list
        path: '/admin/dashboard/view/seo-pages/outreach-links',
        component: TableOutreach,
        name: 'posts-list-outreach',
        meta: {
            requiresAuth: true,
            permission: 'content/manage',
            contentType: 'outreach',
            link: {
                routeAdd: 'create-outreach'
            }
        },
        props: (route) => ({
            contentType: route.meta.contentType
        })
    },
    { // create-pbn
        path: '/admin/dashboard/create/outreach/pbn',
        component: CreateOutreach,
        name: 'create-outreach',
        props: (route) => ({
            mode_type: route.meta.mode_type,
            contentType: route.meta.contentType
        }),
        meta: {
            requiresAuth: true,
            permission: 'content/manage',
            mode: 'create',
            contentType: 'outreach',
            link: {
                routeAdd: 'create-outreach'
            }
        }
    }
]
