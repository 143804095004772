import Api from '@/helpers/api/index.js'

import {
    ACTION_GET_PBN_LIST,
    ACTION_GET_OUTREACH_LIST,
    DELETE_PBN,
    PING_OUTREACH,
    CHANGE_PRICE_OUTREACH,
    PING_PBN,
    DELETE_OUTREACH,
    PAY_OUTREACH,
    CHANGE_PAYMENT_TYPE,
    SET_BULK_SENDING_STATUS
} from './action-types'

import {
    SET_PBN_LIST,
    SET_OUTREACH_LIST,
    SET_LOADING,
    UPDATE_BULK_SENDING_STATUS,
    UPDATE_LIST_ITEM
} from './mutation-types'

export const actions = {
    async [ACTION_GET_PBN_LIST]({ commit }, payload) {
        commit(SET_LOADING, true)
        try {
            let filteredQuery = Object.entries(payload)
                // eslint-disable-next-line no-unused-vars
                .filter(([key, value]) => value)
            filteredQuery = Object.assign({}, ...filteredQuery.map(([key, value]) => ({ [key]: value })))
            const response = await Api.get('/api/seo/pbn/list', filteredQuery)
            response.data.data = response.data.data.map((item) => ({
                ...item,
                checked: false
                // firstname: item.owner.firstname
            }))
            commit(SET_PBN_LIST, response.data)
        } catch (e) {
            console.log(e)
        } finally {
            commit(SET_LOADING, false)
        }
    },
    async [ACTION_GET_OUTREACH_LIST]({ commit }, payload) {
        commit(SET_LOADING, true)
        try {
            const response = await Api.get('/api/links/outreach/list', payload)
            const data = response.data.data.map((item) => ({
                ...item,
                checked: false,
                firstname: item.owner?.firstname
            }))
            commit(SET_OUTREACH_LIST, { ...response.data, data })
        } catch (e) {
            console.log(e)
        } finally {
            commit(SET_LOADING, false)
        }
    },
    [SET_BULK_SENDING_STATUS]({ commit }, text) {
        commit(UPDATE_BULK_SENDING_STATUS, text)
    },
    async [DELETE_PBN]({ state, commit }, payload) {
        try {
            await Api.delete('/api/seo/pbn/delete', payload)
            const data = state.pbn.data.filter((item) => !payload.items.includes(item.id))
            commit(SET_PBN_LIST, { ...state.pbn, data })
        } catch (e) {
            console.log(e)
        }
    },
    // eslint-disable-next-line consistent-return
    async [PING_OUTREACH]({ commit }, payload) {
        const resp = await Api.post('/api/links/outreach/ping', payload)
        resp.data.pinged_outreach.forEach((item) => {
            commit(UPDATE_LIST_ITEM, {
                name: 'outreach',
                data: item,
                save_position: true
            })
        })
        commit(SET_LOADING, false)
        return resp
    },
    async [CHANGE_PAYMENT_TYPE]({ state, commit }, payload) {
        const resp = await Api.put('/api/links/outreach/update-payment-information', payload)
        const currentLink = state.outreach.data.find((item) => item.id === payload.id)
        const { info_payment = '', type_payment = '' } = payload
        commit(UPDATE_LIST_ITEM, {
            name: 'outreach',
            data: { ...currentLink, info_payment, type_payment },
            save_position: true
        })
        commit(SET_LOADING, false)
        return resp
    },
    async [PAY_OUTREACH]({ commit }, payload) {
        const resp = await Promise.all(payload.checked_items.map((id) => Api.put('/api/links/outreach/update-paid', {
            id,
            paid: '1'
        })))
        if (Array.isArray(resp)) {
            resp.forEach((item) => {
                commit(UPDATE_LIST_ITEM, {
                    name: 'outreach',
                    data: item.data,
                    save_position: true
                })
            })
        }
        commit(SET_LOADING, false)
        return resp
    },
    async [PING_PBN]({ commit }, payload) {
        const response = await Api.put('/api/seo/pbn/ping', payload)
        response.data.forEach((item) => {
            commit(UPDATE_LIST_ITEM, {
                name: 'pbn',
                data: item,
                save_position: true
            })
        })
        commit(SET_LOADING, false)
        return response
    },
    async [CHANGE_PRICE_OUTREACH](ctx, payload) {
        try {
            await Api.put('/api/links/outreach/update-price', payload)
        } catch (e) {
            console.log(e)
        }
    },
    async [DELETE_OUTREACH]({ state, commit }, payload) {
        try {
            await Api.delete('/api/links/outreach/delete', payload)
            const data = state.pbn.data.filter((item) => !payload.items.includes(item.id))
            commit(SET_PBN_LIST, { ...state.pbn, data })
        } catch (e) {
            console.log(e)
        }
    }
}
