export class PbnLink {
    constructor({
        url = '',
        url_in_bulk = ''
    } = {}) {
        this.url = url
        this.url_in_bulk = url_in_bulk
    }
}

export function createPbnLink(data) {
    return Object.freeze(new PbnLink(data))
}
