import Api from '@/helpers/api/index.js'

import {
    ACTION_GET_ACTIVITY_LOG,
    ACTION_GET_USERS,
    ACTION_GET_ACTIVITY_LOG_BY_ID
} from './action-types'

import {
    SET_ACTIVITY_LOG_FROM_ID,
    SET_ACTIVITY_LOG
} from './mutation-types'

export const actions = {
    async [ACTION_GET_ACTIVITY_LOG]({ commit }, payload) {
        const response = await Api.get('/api/logs/list', payload)
        commit(SET_ACTIVITY_LOG, response.data)
    },
    // eslint-disable-next-line no-empty-pattern
    async [ACTION_GET_USERS]({}) {
        const response = await Api.get('/api/users/short-list')
        return Object.entries(response.data).map(([value, text]) => ({
            value,
            text
        }))
    },
    async [ACTION_GET_ACTIVITY_LOG_BY_ID]({ commit }, payload) {
        const filters = {
            ...payload
        }
        if (filters.sort_by === 'user') {
            filters.sort_by = 'causer_id'
        }
        const response = await Api.get('/api/logs/fetch-log-by-subject-id', filters)
        commit(SET_ACTIVITY_LOG_FROM_ID, response)
    }
}
