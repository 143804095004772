export const ACTION_GET_PERMISSIONS_LIST = 'ACTION_GET_LANDING_LIST'
export const DELETE_PERMISSIONS = 'DELETE_PERMISSIONS'
export const ACTION_GET_ROLES_LIST = 'ACTION_GET_ROLES_LIST'
export const DELETE_ROLES = 'DELETE_ROLES'
export const ACTION_GET_USERS_LIST = 'ACTION_GET_USERS_LIST'
export const UPDATE_USER_ROLES = 'UPDATE_USER_ROLES'
export const UPDATE_ROLE_PERMISSIONS = 'UPDATE_ROLE_PERMISSIONS'
export const ACTION_USER_LOCK = 'ACTION_USER_LOCK'
export const ACTION_USER_UNLOCK = 'ACTION_USER_UNLOCK'
export const ACTION_DELETE_USERS = 'ACTION_DELETE_USERS'
