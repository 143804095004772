export const getSection = (items, isChecked) => items.reduce((acc, item) => {
    const text = isChecked ? item.name : item
    const arr = text.split('/')
    const currentItem = acc.find((item) => item.name === arr[0]) || { name: arr[0], items: [] }
    currentItem.items.push(item)
    if (currentItem.items.length === 1) {
        acc.push(currentItem)
    }
    return acc
}, [])
