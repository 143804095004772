export default [
    {
        // permission: 'seo/pbn/manage',
        permission: 'seo/outreach/manage',
        icon: 'link-icon',
        text: 'PBN List',
        route: 'posts-list-pbn',
        routeAdd: 'create-pbn'
    },
    {
        permission: 'seo/outreach/manage',
        icon: 'outreach-links',
        text: 'Outreach Links',
        route: 'posts-list-outreach',
        routeAdd: 'create-outreach'
    }
]
