<template>
    <div class="custom-table__row--ip">
        {{ row.ip }}
    </div>
</template>

<script>
export default {
    name: 'RowIp',
    props: {
        row: Object
    }
}
</script>

<style scoped>

</style>
