import { getField } from 'vuex-map-fields'
import { permissionMock } from './permissionMock'

const adminRoles = ['super-admin']

export const getters = {
    getField,
    getterClientTokenDate(state) {
        return state.admin_data.expires_date
    },
    getterIsAuth(state) {
        return state.auth
    },
    getterUserId(state) {
        return state.admin_data.id
    },
    getterUserIsAdmin(state) {
        return state.user.roles.filter((item) => adminRoles.includes(item.name)).length > 0
    },
    getterUser(state) {
        const currentUser = {
            id: state.admin_data.id,
            avatar_url: state.admin_data.avatar_url,
            firstname: state.admin_data.firstname,
            lastname: state.admin_data.lastname
        }
        return currentUser
    },
    getterUserPermissions() {
        // return state.user.permissions
        return permissionMock
    }
}
