const Files = () => import(
    '@/views/Resources/Files'
)

export default [
    { // files-list
        path: '/admin/dashboard/view/files-legacy',
        component: Files,
        name: 'files-legacy-list',
        meta: {
            requiresAuth: true,
            permission: 'content/manage',
            contentType: 'files'
        },
        props: (route) => ({
            contentType: route.meta.contentType
        })
    }
]
