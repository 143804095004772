<template>
    <div class="form-radio">
        <label class="form-radio_label">
            <input
                type="radio"
                :name="name"
                :value="data"
                :checked="isChecked"
                class="form-radio_input"
                @input="$emit('input', $event.target.value)"
            >
            <span class="form-radio_circle" />
            {{ text }}
        </label>
    </div>
</template>

<script>
export default {
    name: 'FormRadio',
    props: {
        text: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        data: {
            type: String,
            required: true
        },
        isChecked: {
            type: Boolean,
            defaul: false
        }
    }
}
</script>

<style lang="scss">
    .form-radio{
        overflow: hidden;
        display: flex;
        align-items: center;
        position: relative;
        &_input{
            opacity: 0;
            position: absolute;
            left: -9999px;
            &:checked + .form-radio_circle {
                display: inline-block;
                border-color: $orange;
                &:after{
                    opacity: 1;
                }
            }
        }
        &_circle{
            display: inline-block;
            min-width: 22px;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            border: 2px solid rgba(0,0,0,.6);
            position: relative;
            margin-right: 15px;
            &:after{
                content: '';
                opacity: 0;
                transition: .3s all;
                position: absolute;
                top: 3px;
                left: 50%;
                width: 12px;
                height: 12px;
                background: $orange;
                border-radius: 50%;
                transform: translate(-50%);
            }
        }
        &_label{
            display: flex;
            align-items: flex-start;
            cursor: pointer;
        }
    }
</style>
