const Guidelines = () => import(
    '@/views/Guidelines'
)

export default [
    {
        path: '/guidelines',
        component: Guidelines,
        name: 'guidelines',
        meta: {
            requiresAuth: true,
            contentType: 'guidelines'
        },
        props: (route) => ({
            contentType: route.meta.contentType
        })
    }
]
