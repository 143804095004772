const EssayRevisions = () => import(
    '@/views/Content/Essay/EssayRevisions'
)

export default [
    {
        path: '/content/revisions/essay/:id',
        component: EssayRevisions,
        name: 'revisions-list-essay',
        meta: {
            requiresAuth: true,
            permission: 'content/manage',
            contentType: 'essay',
            link: {
                name: 'essay',
                routeAdd: 'create-post-essay',
                edit_permission: 'content/essay/change'
            }
        },
        props: (route) => ({
            contentType: route.meta.contentType
        })
    }
]
