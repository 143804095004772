import { Task } from '@/models/Task'

export const state = {
    loading: true,
    task: {
        form_data: new Task(),
        editing: false,
        default_form_data: new Task(),
        unfinished_session: false,
        unfinished_url: null,
        unfinished_type: null,
        mode_type: ''
    },
    priorities: [],
    statuses: []
}
