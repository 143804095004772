<template>
    <div
        class="custom-table__row-in-menu"
    >
        <div class="custom-table__row-cell__status">
            <status-ok
                v-if="row.is_in_menu===true"
                class="custom-table__row-cell__status-ok"
            />
            <status-error
                v-else
                class="custom-table__row-cell__status-off"
            />
        </div>
    </div>
</template>

<script>
import eventsMixin from '@/mixins/content/eventsMixin'

export default {
    name: 'RowIsInMenu',
    mixins: [
        eventsMixin
    ],
    props: {
        row: {
            type: Object
        }
    }
}
</script>
