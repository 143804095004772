<template>
    <div
        class="custom-table__row-created-at custom-table__row-cell__data"
    >
        <template v-if="date_info_type === 'small-info'">
            <span v-if="value">{{ value | moment_from }} ago</span>
            <span v-else> Never </span>
        </template>
        <template v-else-if="date_info_type === 'large-info'">
            <div
                v-if="value"
                style="text-align: left"
            >
                <p>{{ value | moment_short_text }}</p>
                <p>{{ value | moment_from }} ago</p>
            </div>
            <span v-else> Never </span>
        </template>
    </div>
</template>

<script>
import eventsMixin from '@/mixins/content/eventsMixin'
import filtersMixin from '@/mixins/filtersMixin'

export default {
    name: 'RowCreatedAt',
    mixins: [
        eventsMixin,
        filtersMixin
    ],
    props: {
        value: {
            type: String
        },
        date_info_type: {
            type: String,
            default: 'small-info'
        }
    }
}
</script>

<style lang="scss">
    .custom-table__row-created-at {
        //font-family: Roboto,sans-serif!important;
        font-weight: 300;
    }
</style>
