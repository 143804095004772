export const initialState = {
    pbn: {
        data: []
    },
    outreach: {
        data: []
    },
    bulk_status: '',
    loading: false
}

export const state = { ...initialState }
