export const initialState = {
    essay: {
        unfinished_session: false,
        unfinished_url: null,
        mode_type: ''
    },
    loading: true

}

export const state = { ...initialState }
