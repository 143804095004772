<template>
    <div
        class="custom-table__row-link"
    >
        <tooltip-component
            v-if="value"
            :text="link"
        >
            <a
                ref="link"
                :href="link"
                target="_blank"
            > {{ hideCurrentDomain(value) }} </a>
            <span
                style="cursor: pointer; margin-right: 20px"
                @click="copy"
            > <checkbox-multiple-blank-outline /> </span>
        </tooltip-component>
    </div>
</template>

<script>
import CheckboxMultipleBlankOutline from 'mdi-vue/CheckboxMultipleBlankOutline.vue'
import TooltipComponent from '@/components/TooltipComponent'

export default {
    name: 'RowLink',
    components: { CheckboxMultipleBlankOutline, TooltipComponent },
    props: {
        value: {
            type: String
        }
    },
    computed: {
        link() {
            return this.value.includes('https://') ? this.value : `https://${this.value}`
        }
    },
    methods: {
        copy() {
            const range = document.createRange()
            range.selectNode(this.$refs.link)
            window.getSelection().removeAllRanges()
            window.getSelection().addRange(range)
            document.execCommand('copy')
            window.getSelection().removeAllRanges()
            this.$bus.$emit('showSnackBar', 'Link copied to clipboard', 'success')
        },
        hideCurrentDomain(link) {
            if (link.includes(process.env.VUE_APP_OUTREACH_URL)) {
                const strings = link.split('/').filter((item) => !!item)
                const domainIndex = strings.findIndex((item) => item.includes(process.env.VUE_APP_OUTREACH_URL))
                return `.../${strings.slice(domainIndex + 1, strings.length).join('/')}`
            }
            return link
        }
    }
}
</script>

<style scoped>
a {
    display:inline-block;
    max-width: 180px;
    overflow: hidden !important;
    line-height: 24px;
    margin-right: 10px;
    color: #1976d2;
    font-weight: 500;
    word-break: break-all;
    text-align: left;
}
</style>
