export const ACTION_GET_CATEGORY_GROUP = 'ACTION_GET_CATEGORY_GROUP'
export const ACTION_CREATE_CATEGORY_GROUP = 'ACTION_CREATE_CATEGORY_GROUP'
export const ACTION_UPDATE_CATEGORY_GROUP = 'ACTION_UPDATE_CATEGORY_GROUP'
export const ACTION_DELETE_CATEGORY_GROUP = 'ACTION_DELETE_CATEGORY_GROUP'

export const ACTION_GET_CATEGORY = 'ACTION_GET_CATEGORY'
export const ACTION_CREATE_CATEGORY = 'ACTION_CREATE_CATEGORY'
export const ACTION_UPDATE_CATEGORY = 'ACTION_UPDATE_CATEGORY'
export const ACTION_DELETE_CATEGORY = 'ACTION_DELETE_CATEGORY'

export const ACTION_GET_CATEGORY_LIST_FOR_SELECT = 'ACTION_GET_CATEGORY_LIST_FOR_SELECT'

export const ACTION_VALIDATE_LANDING_CATEGORY = 'ACTION_VALIDATE_LANDING_CATEGORY'
export const ACTION_VALIDATE_BLOG_CATEGORY = 'ACTION_VALIDATE_BLOG_CATEGORY'
export const ACTION_VALIDATE_ESSAY_CATEGORY = 'ACTION_VALIDATE_ESSAY_CATEGORY'
