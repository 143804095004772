<template>
    <div
        v-click-outside="clickOutside"
        class="form-select"
    >
        <fieldset :class="openListItem ? 'form-select__fieldset active' : 'form-select__fieldset'">
            <legend
                class="form-select__legend"
                :class="{ 'error': validationError }"
            >
                {{ label }}
            </legend>
            <img
                v-if="value == 2"
                src="@/assets/img/svg/priority_high.svg"
                alt=""
                class="form-input__prepend-icon"
            >
            <img
                v-if="value == 1"
                src="@/assets/img/svg/priority_normal.svg"
                alt=""
                class="form-input__prepend-icon"
            >
            <img
                v-if="value == 0"
                src="@/assets/img/svg/priority_low.svg"
                alt=""
                class="form-input__prepend-icon"
            >
            <input
                :class="{ 'error-border': validationError, 'success-border' : setValue, 'grey-border' : openListItem }"
                class="form-select__input"
                :style="{ paddingLeft: '40px'}"
                :value="setValue"
                type="text"
                :name="name"
                autocomplete="off"
                :placeholder="'Select ' + name"
                :disabled="disabled"
                @click.self="openListItem = !openListItem"
                @focus="$event.target.select()"
                @input="onInputChanged($event)"
            >
            <ul
                v-if="openListItem"
                class="form-select__list"
            >
                <li
                    v-for="(item, index) in items"
                    :key="index"
                    class="form-select__list-item"
                    @click="selectItem(item.value)"
                >
                    <img
                        v-if="item.text === 'high'"
                        src="@/assets/img/svg/priority_high.svg"
                        alt=""
                    >
                    <img
                        v-if="item.text === 'medium'"
                        src="@/assets/img/svg/priority_normal.svg"
                        alt=""
                    >
                    <img
                        v-if="item.text === 'low'"
                        src="@/assets/img/svg/priority_low.svg"
                        alt=""
                    >
                    {{ item.text }}
                </li>
            </ul>
            <div
                v-if="!disabled"
                class="select-arrow"
                @click.self="openListItem = !openListItem"
            >
                <arrow />
            </div>
            <div
                v-if="validationError"
                class="form-select__error"
            >
                {{ validationError }}
            </div>
        </fieldset>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import Arrow from '@/icons/Arrow';

export default {
    name: 'FormSelectPriority',
    components: {
        Arrow
    },
    directives: {
        ClickOutside
    },
    props: {
        value: {},
        items: { type: Array, default: () => [] },
        label: { type: String },
        errors: { type: String },
        escape: { type: Boolean },
        name: {
            required: true,
            type: String
        },
        disabled: Boolean
    },
    data() {
        return {
            openListItem: false,
            search: ''
        };
    },
    computed: {
        setValue() {
            return this.items[this.value]?.text || '';
        },
        validationError() {
            return this.errors ? this.errors : '';
        }
    },
    created() {
        this.setInitValue();
    },
    methods: {
        selectItem(value) {
            this.$emit('input', value);
            this.search = value
            this.openListItem = false;
        },
        onInputChanged(event) {
            this.openListItem = true;
            this.search = event.target.value;
            if (this.search === '') {
                this.$emit('input', '');
            }
        },
        setInitValue() {
            this.search = this.value;
        },
        clickOutside() {
            this.openListItem = false
        }
    }
};
</script>

<style lang="scss">
.form-select {
    outline: none;
    // border: 1px solid #27d91f ;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px !important;
    max-height: 48px!important;
    width: 100%;
    position: relative;

    .select-arrow {
        position: absolute;
        right: 16px;
        top: 50%;
        margin-top: -11px;
        cursor: pointer;
        svg {
            transition: transform .2s ease;
            transform-origin: center center;
            width: 8px;
            transform: rotate(-90deg);
        }
    }
    &.success-border {
        border: 1px solid #27d91f;
    }
    &.grey-border {
        border: 1px solid #D3D9E2;
    }
    &__icon {
        position: absolute;
        top: 10px;
        left: 12px;
    }

    &__fieldset {
        position: relative;
        border: none ;
        outline: none;

    }

    &__legend {
        display: table;
        color: #b3bbc6;
        caret-color: #b3bbc6;
        padding: 2px 5px 2px 5px;
        font-size: 80%;
        position: absolute;
        left: 6px;
        top: -8px;
        background-color: white;
        border: none;
    }

    &__input {
        text-transform: capitalize;
        box-sizing: border-box;
        display: inline-block;
        width: 100%;
        cursor: pointer;
        border: 1px solid #D3D9E2;
        outline: none;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px ;
        font-family: 'Mark Pro', sans-serif ;
        font-size: 14px;
        max-height: 48px;
        height: 48px;
        padding: 1px 10px;
    }

    &__list {
        overflow-y: scroll;
        max-height: 270px;
        background-color: white;
        position: absolute;
        z-index: 10;
        top: 55px;
        text-align: left;
        width: 100%;
        margin: 0;
        padding: 0;
        -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
        border-radius: 4px;
    }

    &__list-item {
        padding: 13px;
        background-color: #fff;
        margin-bottom: 1px;
        cursor: pointer;
        text-transform: capitalize;
        img{
            margin-right: 10px;
        }
        &:hover {
            background-color: lightgrey;
            //color: #FFF;
        }
    }
    &__error {
        display: inline;
        width: auto;
        text-align: left;
        color: #ff5252;
        caret-color: #ff5252;
        font-size: 12px;
        min-height: 14px;
        position: absolute;
        left: 13px;
        bottom: -7px;
        background: white;
        padding: 0 3px;
    }
}

.active {
    .select-arrow svg {
        transform: rotate(90deg);
    }
}

.form-select__input.success-border {
    border: 1px solid #27d91f;
}
</style>
