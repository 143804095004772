<template>
    <div class="custom-table__row-name">
        {{ value }}
    </div>
</template>

<script>
export default {
    name: 'RowName',
    props: ['value']
}
</script>

<style scoped>

</style>
