import { forEach, findIndex } from 'lodash'
import { eventBus } from '@/helpers/event-bus'

export default {
    methods: {
        async eventActionCompleted(obj) {
            const vm = this
            // update table row(s)
            forEach(obj.items, (item) => {
                console.log(`done with${item}`)
                const index = findIndex(vm.templateData.posts.data, (o) => o.id === item.id)
                switch (obj.action) {
                case 'delete':
                    vm.templateData.posts.data.splice(index, 1)
                    break
                default:
                    vm.$set(vm.templateData.posts.data, index, item)
                    break
                }
            })

            // mutate vuex
            this.$store.commit('admin/admin_posts/resetCheckedItems', {
                contentType: obj.contentType
            })
            // important !!! - resets local data variable that saves checkboxes
            this.checkboxList = []

            // snackbar message
            eventBus.$emit('showSnackBar', obj.message, 'success');
        }
    }
}
