import { getField } from 'vuex-map-fields'

export const getters = {
    getField,
    getBlog(state) {
        return state.blog.form_data
    },
    getBlogEditMode(state) {
        return state.blog.editing
    }
}
