<template>
    <div>
        <create-buttons v-if="isCreatePage" />
        <edit-buttons v-if="isEditPage && showEditButtons" />
    </div>
</template>

<script>
import EditButtons from './EditButtons'
import CreateButtons from './CreateButtons'

export default {
    name: 'NavButtons',
    components: { EditButtons, CreateButtons },
    data() {
        return {
            createPages: [
                'create-post-landing',
                'create-post-blog',
                'create-post-essay',
                'create-post-task'
            ],
            editPages: [
                'edit-post-landing',
                'edit-post-blog',
                'edit-post-essay',
                'test-posts-blog-edit',
                'edit-post-task',
                'blog-editor',
                'edit-post-landing-preview',
                'edit-post-landing-preview',
                'edit-post-blog-preview',
                'edit-post-essay-preview',
                'edit-post-blog-revision',
                'edit-post-landing-revision',
                'edit-post-essay-revision'
            ],
            showEditButtons: true
        }
    },
    computed: {
        isEditPage() {
            return this.editPages.find((pageName) => this.$route.name === pageName)
        },
        isCreatePage() {
            return this.createPages.find((pageName) => pageName === this.$route.name)
        }
    },
    created() {
        this.$bus.$on('showEditButtons', () => {
            this.showEditButtons = true
        })
        this.$bus.$on('hideEditButtons', () => {
            this.showEditButtons = false
        })
    }
}
</script>

<style scoped>

</style>
