<template>
    <div
        role="radiogroup"
        errors=""
        :class="classList"
    >
        <slot />
        <p class="radio_group__error">
            {{ validationError }}
        </p>
    </div>
</template>

<script>
export default {
    name: 'FormRadioGroup',
    props: {
        isValid: {
            type: Boolean
        },
        errors: {
            type: String
        },
        classes: {
            type: String
        }
    },
    computed: {
        validationError() {
            return this.errors ? this.errors : '';
        },
        classList() {
            let classList = `radio_group ${this.classes}`
            if (this.validationError) {
                classList += ' error-font-color'
            }
            return classList
        }
    },
    methods: {
        onChange(event) {
            this.$emit('update:field', event.target.value);
        }
    }
}
</script>
<style lang="scss">
    .radio_group{
        position: relative;
        &.error-font-color{
            color: #ff5252;
            .form-radio{
                color: #ff5252;
                &_circle{
                    border-color: #ff5252;
                }
            }
        }
        &__error{
            font-size: 12px;
            margin-top: 15px;
        }
    }
</style>
