export const ACTION_GET_PBN_LIST = 'ACTION_GET_PBN_LIST'
export const DELETE_PBN = 'DELETE_PBN'
export const ACTION_GET_OUTREACH_LIST = 'ACTION_GET_OUTREACH_LIST'
export const PING_OUTREACH = 'PING_OUTREACH'
export const CHANGE_PRICE_OUTREACH = 'PING_OUTREACHCHANGE_PRICE_OUTREACH'
export const PING_PBN = 'PING_PBN'
export const DELETE_OUTREACH = 'DELETE_OUTREACH'
export const PAY_OUTREACH = 'PAY_OUTREACH'
export const CHANGE_PAYMENT_TYPE = 'CHANGE_PAYMENT_TYPE'
export const SET_BULK_SENDING_STATUS = 'SET_BULK_SENDING_STATUS'
