import Api from '@/helpers/api/index.js'

import {
    ACTION_CREATE_POST,
    ACTION_GET_POST,
    ACTION_UPDATE_POST,
    ACTION_VALIDATE_SLUG,
    ACTION_CREATE_REVISION,
    ACTION_VALIDATE_REDIRECT
} from '../action-types'

import {
    RESET_CREATED_FORM_DATA,
    RESET_UNFINISHED_URL,
    SET_BLOG_POST,
    SET_LOADING,
    UPDATE_DEFAULT_FORM_DATA,
    RESET_EDIT_FORM_DATA
} from './mutation-types'

import {
    UPDATE_LIST_ITEM
} from '../../content-list/mutation-types'
import { ACTION_GET_BLOG_LIST } from '../../content-list/action-types';

export const actions = {
    async [ACTION_GET_POST]({ commit }, payload) {
        commit(SET_LOADING, true)
        try {
            const response = await Api.get('/api/content/posts/blog/fetch-post', {
                id: payload.id
            })
            commit(SET_BLOG_POST, response.data)
            commit(SET_LOADING, false)
            return response
        } catch (e) {
            console.error(e)
            commit(SET_LOADING, false)
            return e
        }
    },
    async [ACTION_UPDATE_POST]({
        commit,
        dispatch,
        rootState
    }, payload) {
        try {
            const response = await Api.put('/api/content/posts/blog/update', {
                post_id: payload.id,
                category_id: payload.category_id,
                locale: payload.locale,
                post_slug: payload.post_slug,
                redirect_to: payload.redirect_to,
                cover_image_url: payload.cover_image_url
            })
            response.data.checked = false
            if (!rootState.contentList.blogs) {
                await dispatch(`contentList/${ACTION_GET_BLOG_LIST}`, {}, { root: true })
            }
            commit(`contentList/${UPDATE_LIST_ITEM}`, {
                name: 'blogs',
                data: response.data
            }, { root: true })
            // commit(UPDATE_DEFAULT_FORM_DATA, { name: 'blog', response: response.data })
            dispatch(ACTION_GET_POST, payload)
            commit(RESET_UNFINISHED_URL, { name: 'blog' })
            commit(RESET_EDIT_FORM_DATA, { name: 'blog' })
            return response
        } catch (e) {
            console.error(e)
            return e
        }
    },
    async [ACTION_VALIDATE_SLUG](ctx, payload) {
        const response = await Api.get('/api/content/posts/blog/is-unique-slug', payload, { dialog: false })
        return response.data
    },
    async [ACTION_VALIDATE_REDIRECT](ctx, payload) {
        const response = await Api.get('/api/content/posts/blog/is-valid-redirect-to', payload, { dialog: false })
        return response
    },

    async [ACTION_CREATE_REVISION]({ commit }, payload) {
        try {
            const response = await Api.put('/api/content/posts/blog/create-revision', {
                post_id: payload.id,
                post_content: payload.post_content,
                post_excerpt: payload.post_excerpt,
                post_meta_title: payload.post_meta_title,
                post_meta_description: payload.post_meta_description,
                post_title: payload.post_title
            })
            commit(`contentList/${UPDATE_LIST_ITEM}`, {
                name: 'blogs',
                data: response.data.post
            }, { root: true })
            commit(UPDATE_DEFAULT_FORM_DATA, {
                name: 'blog',
                response: response.data.post
            })
            commit(RESET_UNFINISHED_URL, { name: 'blog' })
            commit(RESET_EDIT_FORM_DATA, { name: 'blog' })
            return response
        } catch (e) {
            console.error(e)
            return e
        }
    },
    async [ACTION_CREATE_POST]({ commit, dispatch, rootState }, payload) {
        try {
            const response = await Api.post('/api/content/posts/blog/create', { ...payload })
            if (!rootState.contentList.blogs) {
                await dispatch(`contentList/${ACTION_GET_BLOG_LIST}`, {}, { root: true })
            }
            commit(`contentList/${UPDATE_LIST_ITEM}`, { name: 'blogs', data: response.data.post }, { root: true })
            if (response.data) {
                await commit(RESET_CREATED_FORM_DATA, { name: 'blog' })
            }
            await commit(RESET_UNFINISHED_URL, { name: 'blog' })
            return response
        } catch (e) {
            console.error(e)
            return e
        }
    }
}
