import { updateField } from 'vuex-map-fields';

import { cloneDeep } from 'lodash'

import {
    RESET_CREATED_FORM_DATA
} from './mutation-types'

export const mutations = {
    updateField,
    [RESET_CREATED_FORM_DATA](state, { name }) {
        switch (name) {
        case 'permission':
            state.permission.form_data = cloneDeep(state.permission.default_form_data)
            break;
        case 'role':
            state.role.form_data = cloneDeep(state.role.default_form_data)
            break;
        case 'user':
            state.user.form_data = cloneDeep(state.user.default_form_data)
            break;
        default:
            break;
        }
    }
}
