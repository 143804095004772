export const ACTION_RESET_PASSWORD = 'ACTION_RESET_PASSWORD'
export const ACTION_LOGIN_ADMIN = 'ACTION_LOGIN_ADMIN'
export const ACTION_VALIDATE_RESET_TOKEN = 'ACTION_CHECK_RESET_TOKEN'
export const ACTION_ADMIN_RESET_PASSWORD_SET_NEW = 'ACTION_ADMIN_RESET_PASSWORD_SET_NEW'
export const ACTION_ADMIN_CHECK_EMAIL = 'ACTION_ADMIN_CHECK_EMAIL'
export const ACTION_ADMIN_CHECK_PHONE = 'ACTION_ADMIN_CHECK_PHONE'
export const ACTION_CREATE_ADMIN = 'ACTION_CREATE_ADMINT'
export const ACTION_LOGOUT_ADMIN = 'ACTION_LOGOUT_ADMIN'
export const ACTION_REFRESH_TOKEN = 'ACTION_REFRESH_TOKEN'
export const ACTION_GET_CURRENT_USER = 'ACTION_GET_CURRENT_USER'

// export const ACTION_ADMIN_UPDATE_TIMEZONE_BACKEND = 'ACTION_ADMIN_UPDATE_TIMEZONE_BACKEND'
// export const ACTION_CHECK_EMAIL = 'ACTION_CHECK_EMAIL'
// export const ACTION_CREATE_ADMIN = 'ACTION_CREATE_ADMIN'
// export const ACTION_LOGOUT_ADMIN = 'ACTION_LOGOUT_ADMIN'
// export const ACTION_UPDATE_ADMIN_DATA = 'ACTION_UPDATE_ADMIN_DATA'
// export const ACTION_ADMIN_UPDATE_EMAIL = 'ACTION_ADMIN_UPDATE_EMAIL'
// export const ACTION_ADMIN_ASK_PASSWORD = 'ACTION_ADMIN_ASK_PASSWORD'
