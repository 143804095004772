<template>
    <svg
        width="11"
        height="13"
        viewBox="0 0 11 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0.785714 11.5556C0.785714 12.35 1.49286 13 2.35714 13H8.64286C9.50714 13 10.2143 12.35 10.2143 11.5556V2.88889H0.785714V11.5556ZM11 0.722222H8.25L7.46429 0H3.53571L2.75 0.722222H0V2.16667H11V0.722222Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: 'Garbage'
}
</script>

<style scoped>

</style>
