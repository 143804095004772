import { eventBus } from '@/helpers/event-bus/'

import Auth from '@/helpers/auth'

import { mapGetters, createNamespacedHelpers } from 'vuex'
import {
    ACTION_LOGOUT_ADMIN
} from '@/store/modules/auth/action-types';

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('auth');

// this mixin contains the logic for session expiry
export default {
    data: () => ({
        authTimer: null
    }),
    computed: {
        ...mapGetters('auth', [
            'getterClientTokenDate',
            'getterIsAuth'
        ])
    },
    watch: {
        getterIsAuth(newVal) {
            if (newVal) {
                this.startAuthTimer()
            } else {
                clearInterval(this.authTimer)
            }
        }
    },
    created() {
        // set timer for authentication popup (extend token expiry)
        if (this.getterIsAuth) {
            this.startAuthTimer()
        } else {
            clearInterval(this.authTimer)
        }
    },
    mounted() {
        window.addEventListener('offline', () => {
            const errorString = 'There is a problem with the internet connection, try refreshing the page please.'
            eventBus.$emit('showSnackBar', errorString, 'error')
        })
    },
    methods: {
        ...mapAdminActions([ACTION_LOGOUT_ADMIN]),
        startAuthTimer() {
            this.authTimer = setInterval(() => {
                try {
                    const result = Auth.shouldDisplayPopup()
                    if (result) {
                        eventBus.$emit('showAuthPopup', Auth.getTimeToExpiryInSeconds())
                    }
                } catch (error) {
                    // Clear client data
                    this[ACTION_LOGOUT_ADMIN]()
                        .then(() => {
                            this.$router.push('/login')
                            eventBus.$emit('resetPopupFlag')
                            clearInterval(this.authTimer)
                        })
                }
            }, 5000)
        }
    }
}
