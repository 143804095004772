import { SeoLink } from '@/models/SeoLink'
import { PbnLink } from '@/models/PbnLink'

export const initialState = {
    pbn: {
        form_data: new PbnLink(),
        default_form_data: new PbnLink(),
        unfinished_session: false,
        unfinished_url: null
    },
    outreach: {
        form_data: new SeoLink(),
        default_form_data: new SeoLink(),
        unfinished_session: false,
        unfinished_url: null
    },
    loading: false
}

export const state = { ...initialState }
