<template>
    <modal-layout
        v-if="dialog"
        :name="name"
        :size="'medium'"
        :hidden="hidden"
        :style="{ 'z-index': order }"
        @closeModal="cancelModal()"
        @hideModal="hideModal(name)"
    >
        <template #header>
            <h2>User Info</h2>
        </template>
        <template #content>
            <div class="profile__body__fields">
                <div
                    v-for="(field, index) in fields"
                    :key="index"
                    class="profile__body__fields__item"
                >
                    <div class="profile__body__fields__item_data">
                        <div class="profile__body__fields__item__name">
                            {{ field.name }}:
                        </div>
                        <div class="profile__body__fields__item__value">
                            <span v-if="field.value">{{ field.value }}</span>
                            <span v-else> {{ field.name }} is empty  </span>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </modal-layout>
</template>

<script>
import modalsEventBusMixins from '@/mixins/modals/modalsEventBusMixins'

export default {
    name: 'UserModal',
    mixins: [modalsEventBusMixins],
    props: {
        user: {
            type: Object,
            required: true
        },
        name: String
    },
    data() {
        return {
            fields: []
        }
    },
    watch: {
        user: {
            deep: true,
            handler() {
                this.getFields()
            }
        }
    },
    methods: {
        getFields() {
            this.fields = [
                {
                    name: 'Account name',
                    value: `${this.user.firstname} ${this.user.lastname}`
                },
                {
                    name: 'User nickname',
                    value: this.user.nickname
                },
                {
                    name: 'Email',
                    value: this.user.email
                },
                {
                    name: 'Phone',
                    value: `(${this.user.phone_code}) ${this.user.phone}`
                },
                {
                    name: 'Biography',
                    value: this.user.biography
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
    .profile__body {
        &__fields {
            &__item{
                display: flex;
                justify-content: space-between;
                margin: 30px auto;
                &_data{
                    width: 100%;
                    display: flex;
                    align-items: center;
                }
                &__name, &__value {
                    font-size: 14px;
                    display: flex;
                    min-width: 140px;
                    align-items: center;
                    justify-content: flex-start;
                }
                &__value {
                    min-width: calc(100% - 140px - 32px);
                    font-weight: 500;
                    color: #1f2939;
                    text-align: left;
                    span {
                        white-space: break-spaces;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                }
                &__name {
                    font-weight: 400;
                    color: #818181;
                }
                &__link {
                    width: 32px;
                    height: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    transition: all .2s ease;
                    &:hover {
                        background: rgba(79, 79, 79, 0.1);
                        color: $orange;
                    }
                }
            }
        }
    }
</style>
