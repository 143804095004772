<template>
    <div
        class="custom-table__row-link custom-table__row-link--outreach"
    >
        <tooltip-component
            v-if="differenceOutreachURL(value).length"
            :text="differenceOutreachURL(value).join(' ')"
        >
            <span
                style="cursor: pointer; margin-right: 20px; color:red"
            > <ClipboardAlert /> </span>
        </tooltip-component>
        <tooltip-component
            v-if="value"
            :text="value"
        >
            <a
                ref="link"
                :href="value"
                target="_blank"
            > {{ stripDomain(value) }} </a>
            <span
                style="cursor: pointer; margin-right: 20px"
                @click="copy"
            > <checkbox-multiple-blank-outline /> </span>
        </tooltip-component>
    </div>
</template>

<script>
import CheckboxMultipleBlankOutline from 'mdi-vue/CheckboxMultipleBlankOutline.vue'
import ClipboardAlert from 'mdi-vue/ClipboardAlert.vue'
import TooltipComponent from '@/components/TooltipComponent'

export default {
    name: 'RowLinkOutreachAcceptor',
    components: { CheckboxMultipleBlankOutline, TooltipComponent, ClipboardAlert },
    props: {
        value: {
            type: String
        }
    },
    methods: {
        copy() {
            this.$clipboard(this.value)
            this.$bus.$emit('showSnackBar', 'Link copied to clipboard', 'success')
        },
        stripDomain(link) {
            return link.replace(process.env.VUE_APP_OUTREACH_URL, '')
        },
        differenceOutreachURL(link) {
            const OUTREACH_URL = new URL(process.env.VUE_APP_OUTREACH_URL)
            try {
                const ROW_LINK = new URL(link)
                const result = []
                if (OUTREACH_URL.host !== ROW_LINK.host) {
                    result.push('host does not match')
                }
                if (OUTREACH_URL.protocol !== ROW_LINK.protocol) {
                    result.push('protocol does not match')
                }
                return result
            } catch {
                return ''
            }
        }
    }
}
</script>

<style scoped lang="scss">
.custom-table__row-link--outreach{
    display: flex;
    justify-content: flex-start !important;
}
a {
    display:inline-block;
    max-width: 180px;
    overflow: hidden !important;
    line-height: 24px;
    margin-right: 10px;
    color: #1976d2;
    font-weight: 500;
    word-break: break-all;
    text-align: left;
}
</style>
