import Api from '@/helpers/api/index.js'

import {
    ACTION_GET_PERMISSIONS_LIST,
    DELETE_PERMISSIONS,
    ACTION_GET_ROLES_LIST,
    ACTION_GET_USERS_LIST,
    DELETE_ROLES,
    UPDATE_USER_ROLES,
    UPDATE_ROLE_PERMISSIONS,
    ACTION_USER_LOCK,
    ACTION_USER_UNLOCK,
    ACTION_DELETE_USERS
} from './action-types'

import {
    SET_PERMISSIONS_LIST,
    SET_ROLES_LIST,
    SET_USERS_LIST,
    SET_LOADING,
    SET_ALL_PERMISSIONS_CHECK_LIST,
    SET_ALL_ROLES_CHECK_LIST,
    UPDATE_LIST_ITEM
} from './mutation-types'

export const actions = {
    async [ACTION_GET_PERMISSIONS_LIST]({ commit }, payload) {
        commit(SET_LOADING, true)
        try {
            const response = await Api.get('/api/permissions/permission/list', payload)
            response.data.data.forEach((item) => { item.checked = false })
            commit(SET_PERMISSIONS_LIST, response.data)
        } catch (e) {
            console.log(e)
        } finally {
            commit(SET_LOADING, false)
        }
    },
    async [DELETE_PERMISSIONS]({ state, commit }, payload) {
        try {
            await Api.delete('/api/permissions/permission/delete', payload)
            const data = state.permissions.data.filter((item) => !payload.items.includes(item.id))
            commit(SET_PERMISSIONS_LIST, { ...state.permissions, data })
        } catch (e) {
            console.log(e)
        }
    },
    async [ACTION_GET_ROLES_LIST]({ commit }, payload) {
        commit(SET_LOADING, true)
        try {
            const response = await Api.get('/api/permissions/role/role-with-permissions', payload)
            response.data.roles.data.forEach((item) => { item.checked = false })
            commit(SET_ROLES_LIST, response.data.roles)
            commit(SET_ALL_PERMISSIONS_CHECK_LIST, Object.keys(response.data.permission).map((name) => ({
                id: response.data.permission[name],
                name
            })))
        } catch (e) {
            console.log(e)
        } finally {
            commit(SET_LOADING, false)
        }
    },
    async [DELETE_ROLES]({ state, commit }, payload) {
        try {
            await Api.delete('/api/permissions/role/delete', payload)
            const data = state.roles.data.filter((item) => !payload.items.includes(item.id))
            commit(SET_ROLES_LIST, { ...state.roles, data })
        } catch (e) {
            console.log(e)
        }
    },
    async [ACTION_GET_USERS_LIST]({ commit }, payload) {
        commit(SET_LOADING, true)
        try {
            const response = await Api.get('/api/users/list', payload)
            response.data.users.data.forEach((item) => {
                item.checked = false
                item.lastname = `${item.lastname} ${item.firstname}`
                item.is_active = +!item.is_active
                item.user_permissions = item.roles.reduce((acc, role) => [...acc, ...role.permissions], [])
                    .map((permission) => permission.name)
            })
            commit(SET_USERS_LIST, response.data.users)
            commit(SET_ALL_ROLES_CHECK_LIST, Object.keys(response.data.roles).map((name) => ({
                id: response.data.roles[name],
                name
            })))
        } catch (e) {
            console.log(e)
        } finally {
            commit(SET_LOADING, false)
        }
    },
    async [UPDATE_USER_ROLES](ctx, payload) {
        try {
            await Api.put('/api/users/assign-roles', payload)
        } catch (e) {
            console.log(e)
        }
    },
    async [UPDATE_ROLE_PERMISSIONS](ctx, payload) {
        try {
            await Api.put('/api/permissions/role/update-permission', payload)
        } catch (e) {
            console.log(e)
        }
    },
    async [ACTION_USER_UNLOCK]({ state, commit }, payload) {
        try {
            const resp = await Api.put('/api/users/activate', payload)
            resp.data.forEach((item, index) => {
                const currentUser = state.users.data.find((item) => item.id === payload.users[index])
                commit(UPDATE_LIST_ITEM, {
                    name: 'users',
                    data: { ...currentUser, is_active: +!item.is_active },
                    save_position: true
                })
            })
            return resp
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    async [ACTION_USER_LOCK]({ state, commit }, payload) {
        try {
            const resp = await Api.put('/api/users/deactivate', payload)
            resp.data.forEach((item, index) => {
                const currentUser = state.users.data.find((item) => item.id === payload.users[index])
                commit(UPDATE_LIST_ITEM, {
                    name: 'users',
                    data: { ...currentUser, is_active: +!item.is_active },
                    save_position: true
                })
            })
            return resp
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    // eslint-disable-next-line no-unused-vars
    async [ACTION_DELETE_USERS]({ commit }, payload) {
        try {
            return await Api.delete('/api/users/delete', payload)
        } catch (e) {
            console.log(e)
            return e
        }
    }
}
