export const SET_LANDING_LIST = 'SET_LANDING_LIST'
export const UPDATE_LIST_ITEMS = 'UPDATE_LIST_ITEMS'
export const UPDATE_LIST_ITEM = 'UPDATE_LIST_ITEM'
export const UPDATE_LIST_ITEM_REVISIONS = 'UPDATE_LIST_ITEM_REVISIONS'
export const UPDATE_LIST_ITEM_REVIEWS = 'UPDATE_LIST_ITEM_REVIEWS'
export const SET_BLOG_LIST = 'SET_BLOG_LIST'

export const SET_ESSAY_LIST = 'SET_ESSAY_LIST'

export const SET_LOADING = 'SET_LOADING'
