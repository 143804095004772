<template>
    <svg
        width="22"
        height="18"
        viewBox="0 0 22 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M20 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H7V18H15V16H20C21.1 16 21.99 15.1 21.99 14L22 2C22 0.9 21.1 0 20 0ZM20 14H2V2H20V14ZM18 5H7V7H18V5ZM18 9H7V11H18V9ZM6 5H4V7H6V5ZM6 9H4V11H6V9Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: 'Desktop'
}
</script>
