import { BlogPost } from '@/models/Content/BlogPost';

export const state = {
    loading: true,
    blog: {
        form_data: new BlogPost(),
        editing: false,
        default_form_data: new BlogPost(),
        unfinished_session: false,
        unfinished_url: null,
        unfinished_type: null,
        mode_type: ''
    }
}
