import { BasePost } from './BasePost';

export class BlogPost extends BasePost {
    constructor(payload = {}) {
        super(payload)
        this.name = payload.name || ''
        this.category_id = payload.category_id || null
        this.post_title = payload.post_title || ''
        this.post_content = payload.post_content || ''
        this.redirect = payload.redirect || false
        this.post_excerpt = payload.post_excerpt || ''
        this.url = payload.url || ''
        this.revisions = payload.revisions || null
        this.published = payload.published || 0
        this.cover_image_url = payload.cover_image_url || null
    }
}

export function createBlog(data) {
    return Object.freeze(new BlogPost(data));
}
