import { createHelpers } from 'vuex-map-fields';

import { state } from './state.js';
import { getters } from './getters.js';
import { mutations } from './mutations.js';
import { actions } from './actions.js';

export const {
    mapFields: mapReviewsFields
} = createHelpers({
    getterType: 'contentReviews/getField',
    mutationType: 'contentReviews/updateField'
});

export const reviews = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
