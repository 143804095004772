import Api from '@/helpers/api/index.js'

import {
    ACTION_CREATE_PBN_POST,
    ACTION_CREATE_OUTREACH_POST
} from './action-types'

import {
    RESET_CREATED_FORM_DATA, SET_LOADING
} from './mutation-types'

import {
    UPDATE_LIST_ITEM,
    UPDATE_BULK_SENDING_STATUS
} from '../seo-list/mutation-types'

import { ACTION_GET_OUTREACH_LIST, ACTION_GET_PBN_LIST } from '../seo-list/action-types';

export const actions = {
    async [ACTION_CREATE_PBN_POST]({ commit, dispatch, rootState }, payload) {
        commit(SET_LOADING, true)
        try {
            const { data } = payload
            data.url_in_bulk = data.url_in_bulk.split('\n').filter((item) => !!item)
            const response = await Api.post('/api/seo/pbn/create-bulk', data)
            commit(RESET_CREATED_FORM_DATA, { name: 'pbn' })
            if (!rootState.seoList.pbn) {
                await dispatch(`seoList/${ACTION_GET_PBN_LIST}`)
            }
            response.data.forEach((item) => {
                commit(`seoList/${UPDATE_LIST_ITEM}`, {
                    name: 'pbn',
                    data: item
                }, { root: true })
            })
            commit(SET_LOADING, false)
            return response
        } catch (e) {
            commit(SET_LOADING, false)
            throw e
        }
    },
    async [ACTION_CREATE_OUTREACH_POST]({ commit, dispatch, rootState }, payload) {
        try {
            const {
                data,
                type
            } = payload
            const urls = {
                absent: 'create-absent-link',
                bulk: 'create-bulk'
            }
            if (type === 'bulk') {
                data.url_in_bulk = data.url_in_bulk.split('\n').filter((item) => !!item)
            }
            commit(SET_LOADING, true)
            const response = await Api.post(`/api/links/outreach/${urls[type]}`, data)
            let models = null
            if (type === 'bulk') {
                const hasSuccess = response.data.reduce((acc, record) => acc || record.status, false)
                if (!hasSuccess) {
                    // eslint-disable-next-line no-throw-literal
                    throw {
                        response: {
                            data: {
                                errors: response.data.map((record) => [record.error])
                            }
                        }
                    }
                } else {
                    models = response.data.filter((record) => record.status).map((record) => record.model)
                }
            }
            commit(RESET_CREATED_FORM_DATA, { name: 'outreach' })
            if (!rootState.seoList.outreach) {
                await dispatch(`seoList/${ACTION_GET_OUTREACH_LIST}`)
            }
            if (!models) {
                commit(`seoList/${UPDATE_LIST_ITEM}`, {
                    name: 'outreach',
                    data: response.data
                }, { root: true })
            } else {
                models.forEach((item) => {
                    commit(`seoList/${UPDATE_LIST_ITEM}`, {
                        name: 'outreach',
                        data: item
                    }, { root: true })
                })
                commit(SET_LOADING, false)

                // Filter response only item with errror status
                const errorsLinks = response.data.filter((record) => !record.status)

                // Create HTML list
                const list = document.createElement('ul')

                // Add item to list
                errorsLinks.forEach((link) => {
                    const listItem = document.createElement('li');
                    listItem.textContent = `${link.url}`
                    list.appendChild(listItem)
                })

                const msg = `Your was created success: ${models.length} outreach links. Failed ${response.data.length - models.length} links: ${list.outerHTML}`
                const snackbarMessage = `Your was created success: ${models.length} outreach links. Failed ${response.data.length - models.length}`
                commit(`seoList/${UPDATE_BULK_SENDING_STATUS}`, msg, { root: true })
                return snackbarMessage
            }

            commit(SET_LOADING, false)
            return 'Your outreach link was created'
        } catch (e) {
            commit(SET_LOADING, false)
            throw e
        }
    }
}
