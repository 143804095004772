<template>
    <div class="custom-table__row-status">
        <div class="custom-table__row-cell__status">
            <status-ok
                v-if="value"
                class="custom-table__row-cell__status-ok"
            />
            <status-error
                v-else
                class="custom-table__row-cell__status-off"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'RowPublished',
    props: {
        value: {}
    }
}
</script>

<style lang="scss">
    .custom-table__row-cell__status {
        &-ok, &-off {
            width: 28px;
            height: 28px;
        }
    }
</style>
