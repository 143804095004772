import moment from 'moment'

import Api from '@/helpers/api/index.js'

import {
    UPDATE_TASK,
    REMOVE_IS_UPDATE
} from '@/store/modules/tasks/mutation-types'

import {
    ACTION_CREATE_POST,
    ACTION_GET_POST,
    ACTION_UPDATE_POST
} from '../action-types'

import {
    ACTION_GET_TASKS_STATUSES,
    ACTION_GET_TASKS_PRIORITIES
} from './actions-types'

import {
    RESET_CREATED_FORM_DATA,
    RESET_UNFINISHED_URL,
    SET_TASK_POST,
    SET_LOADING,
    SET_STATUSES_LIST,
    SET_PRIORITIES_LIST,
    CREATE_TASK
} from './mutation-types'

const formatDeadlineToBackend = (dateStr) => moment(dateStr).format('YYYY-MM-DD')

const getRequestFormst = (payload) => ({
    ...payload,
    deadline_at: formatDeadlineToBackend(payload.deadline_at),
    status: payload.status + 1,
    priority: payload.priority + 1
})

const getResponseFormat = (response) => ({
    ...response,
    status: response.status - 1,
    priority: response.priority - 1
})

export const actions = {
    async [ACTION_GET_POST]({ commit }, payload) {
        commit(SET_LOADING, true)
        try {
            const response = await Api.get('/api/content/tasks/fetch-task', payload)
            commit(SET_TASK_POST, getResponseFormat(response.data[0]))
            commit(`tasks/${REMOVE_IS_UPDATE}`, { id: payload.id }, { root: true })
            commit(SET_LOADING, false)
            return response
        } catch (e) {
            console.error(e)
            commit(SET_LOADING, false)
            return e
        }
    },
    async [ACTION_UPDATE_POST]({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Api.put('/api/content/tasks/update', getRequestFormst(payload), false)
                .then((response) => {
                    commit(`tasks/${UPDATE_TASK}`, getRequestFormst(payload), { root: true })
                    commit(RESET_UNFINISHED_URL, { name: 'task' })
                    resolve(response)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    async [ACTION_CREATE_POST]({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Api.post('/api/content/tasks/create', getRequestFormst(payload), false)
                .then((response) => {
                    commit(`tasks/${CREATE_TASK}`, { data: response.data }, { root: true })
                    if (response.data) {
                        commit(RESET_CREATED_FORM_DATA, { name: 'task' })
                    }
                    commit(RESET_UNFINISHED_URL, { name: 'task' })
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    async [ACTION_GET_TASKS_STATUSES]({ commit }, payload) {
        try {
            const response = await Api.get('/api/content/tasks/list-statuses', payload)
            commit(SET_STATUSES_LIST, Object.values(response.data).map((text, value) => ({
                text, value
            })))
        } catch (e) {
            throw new Error(e)
        }
    },
    async [ACTION_GET_TASKS_PRIORITIES]({ commit }, payload) {
        try {
            const response = await Api.get('/api/content/tasks/list-priorities', payload)
            commit(SET_PRIORITIES_LIST, Object.values(response.data).map((text, value) => ({
                text, value
            })))
        } catch (e) {
            throw new Error(e)
        }
    }
}
