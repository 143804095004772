import { Permission } from '@/models/Permission.js'
import { Role } from '@/models/Role.js'
import { User } from '@/models/User'

export const initialState = {
    permission: {
        form_data: new Permission(),
        default_form_data: {},
        unfinished_session: false,
        unfinished_url: null
    },
    role: {
        form_data: new Role(),
        default_form_data: {},
        unfinished_session: false,
        unfinished_url: null
    },
    user: {
        form_data: new User(),
        default_form_data: {},
        unfinished_session: false,
        unfinished_url: null
    }

}

export const state = { ...initialState }
