import { updateField } from 'vuex-map-fields';

import {
    SET_FILES,
    SET_LOADING,
    UPDATE_LIST_ITEM,
    SET_COMMENT,
    DELETE_COMMENT,
    EDIT_COMMENT,
    SET_FILE_ID
} from './mutation-types'

export const mutations = {
    updateField,
    [SET_LOADING](state, payload) {
        state.loading = payload
    },
    [SET_FILES](state, payload) {
        state.files = payload
    },
    [UPDATE_LIST_ITEM](state, { data }) {
        const index = state.files.data.findIndex((item) => item.id === data.id)
        if (index !== -1) {
            state.files.data.splice(index, 1)
        }
        state.files.data.unshift({ ...data, is_updated: true, checked: false })
    },

    [SET_COMMENT](state, payload) {
        payload.checked_items.forEach((file) => {
            state.files.data = state.files.data.map((item) => {
                if (item.id === file) {
                    const owner = {
                        avatar_url: payload.user?.avatar_url,
                        firstname: payload.user.firstname,
                        lastname: payload.user.lastname
                    }
                    item.comments.unshift({ ...payload, owner })
                    item.comments_count += 1
                }
                return item
            })
        });
    },
    [DELETE_COMMENT](state, payload) {
        const task = state.tasks.data.find((item) => item.id === payload.task_id)
        task.comments = task.comments.filter((item) => item.id !== payload.id)
    },

    [EDIT_COMMENT](state, payload) {
        const task = state.tasks.data.find((item) => item.id === payload.task_id)
        const comment = task.comments.find((item) => item.id === payload.id)
        comment.comment = payload.comment
    },
    [SET_FILE_ID](state, payload) {
        state.filesId = payload
    }
}
