<template>
    <div class="custom-table__row-email">
        {{ row.email }}
    </div>
</template>

<script>
export default {
    name: 'RowEmail',
    props: {
        row: Object
    }
}
</script>

<style scoped>

</style>
