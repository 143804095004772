import menuContent from './content.js'
import menuSeo from './seo.js'
import menuFilesLegacy from './resources.js'
import menuUserManagement from './user-management.js'
import menuSettings from './settings.js'

const items = [
    ...menuContent,
    ...menuSeo,
    ...menuFilesLegacy,
    ...menuUserManagement,
    ...menuSettings
]
export { items }
