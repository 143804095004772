export default [
    {
        permission: 'permissions/manage',
        icon: 'users',
        text: 'Users',
        route: 'posts-list-users',
        // routeAdd: 'create-post-user',
        params: {
            page: 1,
            per_page: 10,
            sort_by: 'email'
        }
    },
    {
        permission: 'permissions/manage',
        icon: 'users',
        text: 'Roles',
        route: 'posts-list-roles',
        routeAdd: 'create-post-role'
    },
    {
        permission: 'permissions/manage',
        icon: 'lock',
        text: 'Permissions',
        route: 'posts-list-permission',
        routeAdd: 'create-post-permission',
        params: {
            page: 1,
            per_page: 10,
            sort_by: 'name'
        }
    }
]
