<template>
    <div class="row_category">
        <span class="row_category-title">
            {{ row.title }}
        </span>
    </div>
</template>

<script>
export default {
    name: 'RowCategoryName',
    props: {
        row: Object
    }
}
</script>

<style scoped lang="scss">
.row_category-title {
    line-height: 1.3;
    max-width: 300px;
    padding-right: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
