<template>
    <div
        class="custom-table__row-reviews"
    >
        <p
            v-if="row.active_revision_reviews_count"
            @click="openReviewModal"
        >
            <span>
                {{ row.active_revision_reviews_count }}
            </span>
        </p>
    </div>
</template>

<script>
export default {
    name: 'RowReviews',
    props: {
        row: {
            type: Object
        },
        contentType: String
    },
    data() {
        return {
            types: {
                landing: 'landing_revisions',
                blog: 'blog_revisions',
                essay: 'essay_revisions'
            }
        }
    },
    methods: {
        openReviewModal() {
            this.$bus.$emit('openModal', 'review-modal', {
                post: {
                    ...this.row,
                    rev_id: this.row.revision_id,
                    type: this.types[this.contentType]
                }
            })
        }
    }
}
</script>

<style lang="scss">
    .custom-table__row-reviews {
        width: 68px;
        & > span {
            cursor: pointer;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background: rgba(255, 0, 0, 0.1);
            margin-left: auto;
            margin-right: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color: #FF0000;
            font-weight: 700;
        }
    }
</style>
