const LandingRevisions = () => import(
    '@/views/Content/Landing/LandingRevisions'
)
export default [
    {
        path: '/content/revisions/landing/:id',
        component: LandingRevisions,
        name: 'revisions-list-landing',
        meta: {
            requiresAuth: true,
            permission: 'content/manage',
            contentType: 'landing',
            link: {
                name: 'landing',
                routeAdd: 'create-post-landing',
                edit_permission: 'content/landing/change'
            }
        },
        props: (route) => ({
            contentType: route.meta.contentType
        })
    }
]
