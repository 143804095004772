import {
    required,
    numeric,
    minLength,
    maxLength
} from 'vuelidate/lib/validators';

export const validationRules = {
    country: {
        required,
        objectStructure(value) {
            if (!value.phone_code || !value.iso) { return false }
            return true
        }
    },
    phone_number: {
        required,
        numeric,
        minLength: minLength(6),
        maxLength: maxLength(10),
        async isUniquePhoneNumber() {
            if (!this.country.phone_code || !this.phone_number) return true
            if (this.phone_number.length <= 5) return true
            this.phone_validation_loading = true
            const response = await this.$store.dispatch('auth/ACTION_ADMIN_CHECK_PHONE', {
                phone_code: this.country.phone_code,
                phone: this.phone_number
            })
            this.phone_validation_loading = false
            return Boolean(await response.status)
        }
    }
}
