<template>
    <svg
        width="12"
        height="20"
        viewBox="0 0 12 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.67 1.76999L9.9 -7.62939e-06L0 9.89999L9.9 19.8L11.67 18.03L3.54 9.89999L11.67 1.76999Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: 'Arrow'
}
</script>

<style scoped>

</style>
