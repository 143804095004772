import { updateField } from 'vuex-map-fields';
import { initialState } from './state';
import {
    LOGIN_ADMIN,
    LOGOUT_ADMIN,
    SET_ADMIN_TIMEZONE,
    SET_CURRENT_USER,
    UPDATE_USER_CATEGORIES,
    CREATE_USER_CATEGORIES,
    DELETE_USER_CATEGORIES
} from './mutation-types'

export const mutations = {
    updateField,
    [UPDATE_USER_CATEGORIES](state, { categoryName, data }) {
        const index = state.user.category[categoryName].findIndex((cat) => cat.value === data.value)
        if (index !== -1) state.user.category[categoryName].splice(index, 1, data)
    },
    [CREATE_USER_CATEGORIES](state, { categoryName, data }) {
        state.user.category[categoryName].push(data)
    },
    [DELETE_USER_CATEGORIES](state, { categoryName, id }) {
        const index = state.user.category[categoryName].findIndex((cat) => cat.value === id)
        if (index !== -1) state.user.category[categoryName].splice(index, 1)
    },
    [SET_CURRENT_USER](state, payload) {
        payload.locale = ['en', 'ae'] // DELETE AFTER BACK-END CHANGES
        state.user = { ...payload }
    },

    [LOGIN_ADMIN](state, payload) {
        state.auth = true
        // Object.assign({}, state.form_data, payload)
        state.admin_data = { ...state.admin_data, ...payload }
    },
    [SET_ADMIN_TIMEZONE](state) {
        state.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    [LOGOUT_ADMIN](state) {
        Object.assign(state, initialState)
    }
}
