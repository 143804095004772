<template>
    <div>
        {{ row.log_name }}
    </div>
</template>

<script>
export default {
    name: 'RowLogName',
    props: {
        row: {
            type: Object
        }
    }
}
</script>

<style scoped>

</style>
