export class BasePost {
    constructor({
        id = 0,
        locale = 'en',
        post_meta_title = '',
        post_meta_description = '',
        post_slug = ''
    }) {
        this.id = id
        this.locale = locale
        this.post_meta_title = post_meta_title
        this.post_meta_description = post_meta_description
        this.post_slug = post_slug
    }
}
