import { updateField } from 'vuex-map-fields';

import {
    SET_PERMISSIONS_LIST,
    SET_ROLES_LIST,
    SET_USERS_LIST,
    SET_LOADING,
    SET_ALL_PERMISSIONS_CHECK_LIST,
    SET_ALL_ROLES_CHECK_LIST
} from './mutation-types'
import { UPDATE_LIST_ITEM } from '../../content/content-list/mutation-types';

export const mutations = {
    updateField,
    [SET_PERMISSIONS_LIST](state, payload) {
        state.permissions = payload
    },
    [SET_ALL_PERMISSIONS_CHECK_LIST](state, payload) {
        state.fullPermissions = payload
    },
    [SET_ALL_ROLES_CHECK_LIST](state, payload) {
        state.fullRoles = payload
    },
    [SET_ROLES_LIST](state, payload) {
        state.roles = payload
    },
    [SET_USERS_LIST](state, payload) {
        state.users = payload
    },
    [SET_LOADING](state, value) {
        state.loading = value
    },
    [UPDATE_LIST_ITEM](state, { name, data, save_position = false }) {
        const index = state[name].data.findIndex((item) => item.id === data.id)
        if (save_position) {
            state[name].data.splice(index, 1, { ...data, is_updated: true, checked: false })
            return
        }
        if (index !== -1) {
            state[name].data.splice(index, 1)
        }
        data && state[name].data.unshift({ ...data, is_updated: true, checked: false })
    }
}
