const TableLanding = () => import(
    '@/views/Content/Landing/LandingPages'
)

const EditLanding = () => import(
    '@/views/Content/Landing/EditLandingPage'
)

const UpdatePost = () => import(
    '@/components/CustomEditor/UpdatePost'
)

export default [
    {
        path: '/content/post/list/landing',
        component: TableLanding,
        name: 'posts-list-landing',
        meta: {
            requiresAuth: true,
            permission: 'content/manage',
            contentType: 'landing',
            link: {
                name: 'landing',
                routeAdd: 'create-post-landing',
                edit_permission: 'content/landing/change'
            }
        },
        props: (route) => ({
            contentType: route.meta.contentType
        })
    },
    {
        path: '/content/post/landing/create',
        component: EditLanding,
        name: 'create-post-landing',
        props: (route) => ({
            mode_type: route.meta.mode_type,
            contentType: route.meta.contentType
        }),
        meta: {
            mode_type: 'create',
            contentType: 'landing',
            requiresAuth: true,
            permission: 'content/manage',
            link: {
                name: 'landing',
                routeAdd: 'create-post-landing',
                edit_permission: 'content/landing/change'
            }
        },
        children: [
            {
                path: '',
                name: 'create-post-landing',
                component: UpdatePost,
                props: (route) => ({
                    mode_type: route.meta.mode_type,
                    contentType: route.meta.contentType
                }),
                meta: {
                    requiresAuth: true,
                    permission: 'content/manage',
                    mode_type: 'create',
                    contentType: 'landing',
                    link: {
                        name: 'landing',
                        routeAdd: 'create-post-landing',
                        edit_permission: 'content/landing/change'
                    }
                }
            }
        ]
    }
]
