import { updateField } from 'vuex-map-fields';
import { Task } from '@/models/Task'

import {
    SET_LOADING,
    SET_TASKS_LIST,
    SET_STATUSES_LIST,
    SET_PRIORITIES_LIST,
    SET_TASK,
    CLEAR_FORM_DATA,
    DELETE_TASKS,
    UPDATE_TASKS_ITEMS,
    UPDATE_FORM_DATA,
    UPDATE_TASK,
    SET_COMMENT,
    DELETE_COMMENT,
    EDIT_COMMENT,
    TAKE_TASK,
    FINALIZE_TASK,
    CREATE_TASK,
    REMOVE_IS_UPDATE
} from './mutation-types'

export const mutations = {
    updateField,

    [SET_LOADING](state, payload) {
        state.loading = payload
    },

    [SET_TASKS_LIST](state, payload) {
        if (state.tasks) {
            const updated = state.tasks.data.filter((item) => item.is_updated);
            updated.forEach((item) => {
                const tmp = payload.data.find((task) => task.id === item.id)
                tmp && (tmp.is_updated = true)
            })
        }
        state.tasks = payload
    },

    [SET_STATUSES_LIST](state, payload) {
        state.statuses = payload
    },

    [SET_PRIORITIES_LIST](state, payload) {
        state.priorities = payload
    },

    [SET_TASK](state, payload) {
        console.log(payload);
        state.form_data = { ...state.form_data, ...payload }
    },

    [CLEAR_FORM_DATA](state) {
        state.form_data = new Task()
    },

    [UPDATE_FORM_DATA](state, payload) {
        state.form_data = new Task(payload)
        console.log(state.form_data);
    },

    [DELETE_TASKS](state, payload) {
        payload.tasks.forEach((id) => {
            const tmp = state.tasks.data.filter((task) => task.id !== id)
            state.tasks.data = tmp
        });
    },

    [CREATE_TASK](state, payload) {
        payload.data.is_updated = true
        payload.data.checked = false
        state.tasks.data.unshift(payload.data)
    },

    [UPDATE_TASKS_ITEMS](state, payload) {
        state.tasks.data.forEach((item) => {
            if (payload.tasks.includes(item.id)) {
                if (payload.name === 'post_type') {
                    item[payload.name] = payload[payload.name]
                } else {
                    item[payload.name] = Number(payload[payload.name])
                }
                item.is_updated = true
                item.checked = false
            }
        });
    },

    [UPDATE_TASK](state, payload) {
        state.tasks.data = state.tasks.data.map((item) => {
            if (item.id === payload.id) {
                item = { ...item, ...payload, is_updated: true }
                return item
            }
            return item
        })
    },

    [REMOVE_IS_UPDATE](state, payload) {
        state.tasks.data = state.tasks.data.map((item) => {
            if (item.id === payload.id) {
                item.is_updated = false
                return item
            }
            return item
        })
    },

    [SET_COMMENT](state, payload) {
        const owner = {
            avatar_url: payload.user?.avatar_url,
            firstname: payload.user.firstname,
            lastname: payload.user.lastname,
            id: payload.user.id
        }
        state.tasks.data = state.tasks.data.map((item) => {
            if (item.id === payload.task_id) {
                item.comments.push({ ...payload, owner })
                item.comments_count += 1
                return item
            }
            return item
        })
    },
    [DELETE_COMMENT](state, payload) {
        const task = state.tasks.data.find((item) => item.id === payload.data.task_id)
        task.comments = task.comments.filter((item) => item.id !== payload.data.id)
        task.comments_count -= 1
    },

    [EDIT_COMMENT](state, payload) {
        const task = state.tasks.data.find((item) => item.id === payload.data.task_id)
        const comment = task.comments.find((item) => item.id === payload.data.id)
        comment.comment = payload.data.comment
    },

    [TAKE_TASK](state, payload) {
        const task = state.tasks.data.find((item) => item.id === payload.id)
        task.assignee_id = payload.user.id
        task.assignee_id = payload.user.id
        task.is_updated = true
        task.status = 2
        task.assagnee = payload.user
    },

    [FINALIZE_TASK](state, payload) {
        const task = state.tasks.data.find((item) => item.id === payload.id)
        task.is_updated = true
        task.status = 3
    }

}
