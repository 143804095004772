<template>
    <div
        class="custom-table__row-created-at custom-table__row-cell__data"
    >
        {{ row.description }}
    </div>
</template>

<script>
export default {
    name: 'RowDescription',
    props: {
        row: {
            type: Object
        }
    }
}
</script>

<style lang="scss">
    .custom-table__row-created-at {
        //font-family: Roboto,sans-serif!important;
        font-weight: 300;
    }
</style>
