export class Role {
    constructor({
        name = ''
    } = {}) {
        this.name = name
    }
}

export function createRole(data) {
    return Object.freeze(new Role(data));
}
