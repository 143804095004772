<template>
    <tooltip-component :text="row.filetype">
        <div class="row-filename">
            {{ row.filetype }}
        </div>
    </tooltip-component>
</template>

<script>
import TooltipComponent from '@/components/TooltipComponent';

export default {
    name: 'RowFileType',
    components: {
        TooltipComponent
    },
    props: {
        row: Object
    }
}
</script>

<style lang="scss">
.row-filename {
    max-width: 300px;
    white-space: nowrap;
    overflow-x: hidden;
    line-height: 22px;
    text-overflow: ellipsis;
    padding-right: 10px;
}
</style>
