<template>
    <div
        class="custom-table__row-in-menu"
    >
        <div class="custom-table__row-cell__status">
            <lock
                v-if="value===1"
                color="red"
            />
            <unlock
                v-else
                style="color: green"
            />
        </div>
    </div>
</template>

<script>
import Unlock from 'mdi-vue/LockOpen'

export default {
    name: 'RowLocked',
    components: {
        Unlock
    },
    props: {

        value: {}
    }
}
</script>

<style lang="scss" scoped>
.custom-table__row-cell__status {
    color: red;
    .mdi-lock-open {
        color: rgb(33, 150, 83);
    }
}
</style>
