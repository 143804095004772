const BlogCategory = () => import(
    '@/views/Content/Blog/BlogCategory'
)

const CreateBlogCategory = () => import(
    '@/views/Content/Blog/CreateBlogCategory'
)

const EditBlogCategory = () => import(
    '@/views/Content/Blog/EditBlogCategory'
)

export default [
    {
        path: '/content/category/list/blog',
        component: BlogCategory,
        name: 'content-blog-categories',
        meta: {
            requiresAuth: true,
            permission: 'content/manage',
            contentType: 'blog-categories',
            link: {
                routeAdd: 'content-blog-categories-create',
                edit_permission: 'content/blog/change'
            }
        },
        props: (route) => ({
            contentType: route.meta.contentType
        })
    },
    {
        path: '/admin/dashboard/view/posts/blog-categories/create',
        component: CreateBlogCategory,
        name: 'content-blog-categories-create',
        meta: {
            requiresAuth: true,
            permission: 'content/manage',
            contentType: 'blog-categories',
            link: {
                routeAdd: 'content-blog-categories-create',
                edit_permission: 'content/blog/change'
            }
        },
        props: (route) => ({
            contentType: route.meta.contentType
        })
    },
    {
        path: '/admin/dashboard/view/posts/blog-categories/edit/:id',
        component: EditBlogCategory,
        name: 'content-blog-categories-edit',
        meta: {
            requiresAuth: true,
            permission: 'content/manage',
            contentType: 'blog-categories',
            link: {
                routeAdd: 'content-blog-categories-create',
                edit_permission: 'content/blog/change'
            }
        },
        props: (route) => ({
            contentType: route.meta.contentType
        })
    }
]
