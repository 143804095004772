import CloseIcon from 'mdi-vue/Close'
import Error from '@/icons/Error';
import Success from '@/icons/Success';
import Loading from '@/icons/Loading';
import StatusOk from '@/icons/StatusOk';
import StatusError from '@/icons/StatusError';
import StatusUnknown from '@/icons/StatusUnknown';
import Lock from '@/icons/Lock'

const Icons = {
    install(Vue) {
        Vue.component(CloseIcon.name, CloseIcon)
        Vue.component(Error.name, Error)
        Vue.component(Success.name, Success)
        Vue.component(Loading.name, Loading)
        Vue.component(StatusOk.name, StatusOk)
        Vue.component(StatusError.name, StatusError)
        Vue.component(StatusUnknown.name, StatusUnknown)
        Vue.component(Lock.name, Lock)
    }
}

export default Icons
