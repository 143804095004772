import { updateField } from 'vuex-map-fields';
import { EssayPost } from '@/models/Content/EssayPost'

import { cloneDeep } from 'lodash'

import {
    RESET_CREATED_FORM_DATA,
    RESET_EDIT_FORM_DATA,
    SET_ESSAY_POST,
    SET_LOADING,
    RESET_UNFINISHED_URL,
    UPDATE_DEFAULT_FORM_DATA
} from './mutation-types'

export const mutations = {
    updateField,
    [SET_LOADING](state, payload) {
        state.loading = payload
    },
    [UPDATE_DEFAULT_FORM_DATA](state, { response }) {
        state.essay.default_form_data = response
    },
    [RESET_CREATED_FORM_DATA](state) {
        state.essay.form_data = new EssayPost()
        state.essay.default_form_data = new EssayPost()
    },
    [RESET_EDIT_FORM_DATA](state) {
        state.essay.form_data = cloneDeep(state.essay.default_form_data)
    },
    [SET_ESSAY_POST](state, payload) {
        state.essay.form_data = cloneDeep(payload)
        state.essay.default_form_data = cloneDeep(payload)
    },
    [RESET_UNFINISHED_URL](state, { name }) {
        state[name].unfinished_session = false
        state[name].unfinished_url = null
        state[name].unfinished_type = null
    }
}
