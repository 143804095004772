<template>
    <div class="custom-table__row-owner">
        <tooltip-component :text="getOwner.firstname">
            <div
                class="custom-table__row-owner--avatar"
            >
                <!--            <img :src="'https://cms.customessaymeister.com/' + getOwner.file_on_disk" alt="" v-if="getOwner.file_on_disk">-->
                <!--&& !getOwner.file_on_disk-->
                <span
                    v-if="getOwner.avatar_url && !loadError"
                    :style="{ backgroundImage: `url(${getOwner.avatar_url})` }"
                    class="custom-table__row-owner--avatar_image"
                >
                    <img
                        v-show="false"
                        alt="avatar"
                        :src="row.avatar_url"
                        @error="loadError = true"
                    >
                </span>
                <span
                    v-else-if="getOwner.firstname"
                    class="custom-table__row-owner--avatar_name"
                >
                    {{ getOwner.firstname[0] || row.firstname[0] }}
                </span>
            </div>
        </tooltip-component>
    </div>
</template>

<script>
import TooltipComponent from '@/components/TooltipComponent'

export default {
    name: 'RowUpdatedBy',
    components: { TooltipComponent },
    props: {
        row: {
            type: Object
        },
        value: {
        }
    },
    data() {
        return {
            loadError: false
        }
    },
    computed: {
        getOwner() {
            const data = {
                avatar_url: '',
                firstname: ''
            }
            if (this.value && typeof this.value === 'object') {
                data.avatar_url = this.value.avatar_url
                data.firstname = this.value.firstname
            } else {
                data.avatar_url = this.row.avatar_url
                data.firstname = this.row.firstname
            }
            return data
        }
    }
}
</script>
<style scoped lang="scss">
.custom-table__row-owner {
    display: flex;
    align-items: center;
    justify-content: center;
    &--avatar {
        position: relative;
        width: 58px;
        height: 58px;
        background: $orange;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            border-radius: 50%;
            max-width: 58px;
            height: 58px;
            width: 100%;
        }
        &_name {
            font-size: 20px;
            color: #fff;
        }
        &_image{
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }

}
</style>
