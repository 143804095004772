export default [
    {
        name: 'guidelines',
        icon: 'playlist-check',
        text: 'Guidelines',
        route: 'guidelines'
    },
    {
        name: 'landing',
        permission: 'content/landing/manage',
        icon: 'desktop',
        text: 'Landing',
        route: 'posts-list-landing',
        routeAdd: 'create-post-landing',
        params: {
            page: 1,
            per_page: 10,
            sort_by: 'updated_at',
            sort_order: 'DESC'
        }
    },
    {
        permission: 'content/landing/manage',
        edit_permission: 'content/landing/change',
        icon: 'page',
        text: 'Landing Categories',
        route: 'content-landing-categories',
        routeAdd: 'content-landing-categories-create'
    },
    {
        name: 'blog',
        permission: 'content/blog/manage',
        edit_permission: 'content/blog/change',
        icon: 'pencil',
        text: 'Blog',
        route: 'posts-list-blog',
        routeAdd: 'create-post-blog',
        params: {
            page: 1,
            per_page: 10,
            sort_by: 'updated_at',
            sort_order: 'DESC'
        }
    },
    {
        permission: 'content/blog/manage',
        edit_permission: 'content/blog/change',
        icon: 'page',
        text: 'Blog Categories',
        route: 'content-blog-categories',
        routeAdd: 'content-blog-categories-create'
    },
    {
        name: 'essay',
        permission: 'content/essay/manage',
        edit_permission: 'content/essay/change',
        icon: 'page',
        text: 'Essay',
        route: 'posts-list-essay',
        routeAdd: 'create-post-essay',
        params: {
            page: 1,
            per_page: 10,
            sort_by: 'updated_at',
            sort_order: 'DESC'
        }
    },
    {
        permission: 'content/essay/manage',
        edit_permission: 'content/essay/change',
        icon: 'page',
        text: 'Essay Categories',
        route: 'content-essay-categories',
        routeAdd: 'content-essay-categories-create'
    },
    {
        name: 'Tasks',
        permission: 'content-tasks/manage',
        icon: 'page',
        text: 'Tasks',
        route: 'posts-list-tasks',
        routeAdd: 'create-post-task'
    }
]
