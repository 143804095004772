<template>
    <div>
        <div v-if="!hasSections">
            <div
                v-for="(item, index) in list"
                :key="index"
                class="checklist__item"
            >
                <form-checkbox
                    :label="item.name"
                    color="$sidebar-orange"
                    :value="checkedNames.includes(item.name)"
                    @input="handler(item)"
                />
            </div>
        </div>
        <div
            v-else
            class="section-wrap"
        >
            <div
                v-for="(section, index) in getSection"
                :key="index"
                class="section"
            >
                <h4>{{ section.name }}:</h4>
                <div
                    v-for="(item, itemIndex) in section.items"
                    :key="itemIndex"
                >
                    <form-checkbox
                        :label="item.name"
                        color="$sidebar-orange"
                        :value="checkedNames.includes(item.name)"
                        @input="handler(item)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { getSection } from '@/helpers/functions/helperSectionPermission'

export default {
    name: 'RowCheckList',
    props: {
        row: Object,
        defaultChecked: {
            type: Array,
            default: () => []
        },
        list: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            checked: []
        }
    },
    computed: {
        checkedNames() {
            return this.checked.map((item) => item.name)
        },
        hasSections() {
            return this.$route.name === 'posts-list-roles'
        },
        getSection() {
            return this.hasSections ? getSection(this.list, true) : []
        }
    },
    watch: {
        defaultChecked: {
            deep: true,
            handler() {
                this.resetChecked()
            }
        }
    },
    created() {
        this.resetChecked()
    },
    methods: {
        resetChecked() {
            this.checked = cloneDeep(this.defaultChecked)
        },
        handler(val) {
            if (this.checkedNames.includes(val.name)) {
                this.checked = this.checked.filter((item) => item.name !== val.name)
            } else {
                this.checked.push(val)
            }

            this.$emit('action', {
                checked: this.checked,
                row: this.row
            })
        }
    }
}
</script>

<style scoped lang="scss">
.checklist__item {
    margin: 10px 25px 10px 25px;
    width: 160px;
    word-break: break-all;
}
.section-wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-gap: 15px;
    & > .section {
        align-self: start
    }
    h4{
        font-size: 18px;
        text-transform: capitalize;
        text-align: left;
        padding-left: 10px;
    }
    .section > div {
        font-size: 16px;
        padding: 10px;
    }
}
</style>
