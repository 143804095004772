import Api from '@/helpers/api/index.js'

import {
    ACTION_CREATE_REVIEW,
    ACTION_UPDATE_REVIEW,
    ACTION_DELETE_REVIEW,
    ACTION_GET_REVIEW,
    ACTION_GET_REVIEWS
} from './action-types'

import {

} from './mutation-types'
import { UPDATE_REVISION_REVIEWS_COUNT } from '../content-revisions/mutation-types';
import { UPDATE_LIST_ITEM_REVIEWS } from '../content-list/mutation-types';

export const actions = {
    // eslint-disable-next-line no-empty-pattern
    async [ACTION_CREATE_REVIEW]({ commit, dispatch, rootState }, payload) {
        await Api.post('/api/content/reviews/create', payload)
        commit(`contentRevisions/${UPDATE_REVISION_REVIEWS_COUNT}`, {
            type: payload.revision_type,
            id: payload.revision_id
        }, { root: true })
        const types = {
            landing_revisions: 'landing',
            essay_revisions: 'essay',
            blog_revisions: 'blog'
        }
        if (!rootState.contentList[types[`${payload.revision_type}s`]]) {
            await dispatch(`contentList/ACTION_GET_${types[payload.revision_type].toUpperCase()}_LIST`, {}, { root: true })
        }
        commit(`contentList/${UPDATE_LIST_ITEM_REVIEWS}`, {
            type: payload.revision_type,
            id: payload.post_id,
            revision_id: payload.revision_id
        }, { root: true })
    },
    // eslint-disable-next-line no-empty-pattern
    async [ACTION_UPDATE_REVIEW]({}, payload) {
        await Api.put('/api/content/reviews/update', payload)
    },
    // eslint-disable-next-line no-empty-pattern
    async [ACTION_DELETE_REVIEW]({ commit, dispatch, rootState }, payload) {
        await Api.delete('/api/content/reviews/delete', payload)
        commit(`contentRevisions/${UPDATE_REVISION_REVIEWS_COUNT}`, {
            type: payload.revision_type,
            id: payload.revision_id,
            mode: 'delete'
        }, { root: true })
        const types = {
            landing_revisions: 'landing',
            essay_revisions: 'essay',
            blog_revisions: 'blog'
        }
        if (!rootState.contentList[types[`${payload.revision_type}s`]]) {
            await dispatch(`contentList/ACTION_GET_${types[payload.revision_type].toUpperCase()}_LIST`, {}, { root: true })
        }
        commit(`contentList/${UPDATE_LIST_ITEM_REVIEWS}`, {
            type: payload.revision_type,
            id: payload.post_id,
            revision_id: payload.revision_id,
            mode: 'delete'
        }, { root: true })
    },
    // eslint-disable-next-line no-empty-pattern
    async [ACTION_GET_REVIEW]({}, payload) {
        await Api.get('/api/content/reviews/fetch ', payload)
    },
    // eslint-disable-next-line no-empty-pattern,consistent-return
    async [ACTION_GET_REVIEWS]({}, { revision_id, type }) {
        try {
            const response = await Api.get('/api/content/reviews/list', {
                revision_id,
                revision_type: type
            })
            return response
        } catch (e) {
            console.log(e)
        }
    }
}
