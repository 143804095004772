import Api from '@/helpers/api/index.js'

import {
    ACTION_GET_CATEGORY_GROUP,
    ACTION_CREATE_CATEGORY_GROUP,
    ACTION_UPDATE_CATEGORY_GROUP,
    ACTION_DELETE_CATEGORY_GROUP,

    ACTION_GET_CATEGORY,
    ACTION_CREATE_CATEGORY,
    ACTION_UPDATE_CATEGORY,
    ACTION_DELETE_CATEGORY,

    ACTION_GET_CATEGORY_LIST_FOR_SELECT,

    ACTION_VALIDATE_LANDING_CATEGORY,
    ACTION_VALIDATE_BLOG_CATEGORY,
    ACTION_VALIDATE_ESSAY_CATEGORY
} from './action-types'

import {
    SET_CATEGORIES,
    UPDATE_CATEGORIES,
    DELETE_CATEGORY,
    SET_CATEGORIES_SELECT,
    SET_LOADING
} from './mutation-types'
// import {
//     // UPDATE_USER_CATEGORIES
// } from '../../auth/mutation-types';

export const actions = {
    // Refactoring for all endPoints

    async [ACTION_GET_CATEGORY_GROUP]({ commit }, payload) {
        commit(SET_LOADING, true)
        try {
            const response = await Api.get(`/api/content/categories/category-group/${payload.path}/list`, payload.data)
            commit(SET_CATEGORIES, { name: payload.path, data: response.data })
        } catch (e) {
            console.log(e)
        }
        commit(SET_LOADING, false)
    },

    async [ACTION_CREATE_CATEGORY_GROUP]({ commit }, payload) {
        try {
            const response = await Api.post(`/api/content/categories/category-group/${payload.path}/create`, payload.data)
            commit(UPDATE_CATEGORIES, { name: payload.path, data: response.data })
            console.log('response', response)
            return response
        } catch (e) {
            console.log(e)
            return e
        }
    },

    // eslint-disable-next-line consistent-return
    async [ACTION_UPDATE_CATEGORY_GROUP](ctx, payload) {
        try {
            const response = await Api.put(`/api/content/categories/category-group/${payload.path}/update`, payload.data)
            // commit(`auth/${UPDATE_USER_CATEGORIES}`, {
            //     categoryName: payload.path,
            //     data: {
            //         value: response.data.id,
            //         text: response.data.title
            //     }
            // }, { root: true })
            return response
        } catch (e) {
            console.log(e)
            return e
        }
    },

    async [ACTION_DELETE_CATEGORY_GROUP]({ commit }, { path, id }) {
        try {
            await Api.delete(`/api/content/categories/category-group/${path}/delete`, { id })
            commit(DELETE_CATEGORY, { name: path, id })
        } catch (e) {
            console.log(e)
        }
    },

    async [ACTION_GET_CATEGORY](ctx, { path, id }) {
        try {
            const response = await Api.get(`/api/content/categories/category/${path}/list`, { id })
            return response
        } catch (e) {
            console.error(e)
            return e
        }
    },

    async [ACTION_CREATE_CATEGORY](ctx, payload) {
        try {
            const response = await Api.post(`/api/content/categories/category/${payload.path}/create`, payload.data)
            response.data.posts_count = 0
            return response
        } catch (e) {
            console.error(e)
            return e
        }
    },

    async [ACTION_UPDATE_CATEGORY](ctx, payload) {
        const response = await Api.put(`/api/content/categories/category/${payload.path}/update`, payload.data)
        return response
    },

    async [ACTION_DELETE_CATEGORY](ctx, { path, data }) {
        await Api.delete(`/api/content/categories/category/${path}/delete`, {
            id: data.id
        })
        // commit(DELETE_SUBCATEGORY, { name: 'blog', id: catId, subId })
    },

    async [ACTION_GET_CATEGORY_LIST_FOR_SELECT]({ commit }, payload) {
        console.log('payload: ', payload)
        try {
            const response = await Api.get(`/api/content/categories/category-group/${payload.path}/list`, payload.data)
            const data = response.data.map((item) => {
                const categories = item.categories.map((cat) => ({
                    value: cat.id,
                    text: cat.title
                }))
                return {
                    value: item.id,
                    text: item.title,
                    categories
                }
            })
            commit(SET_CATEGORIES_SELECT, { name: payload.path, data })
        } catch (e) {
            console.log(e)
        }
    },
    async [ACTION_VALIDATE_LANDING_CATEGORY](ctx, { group_id, title, id }) {
        const response = await Api.post('/api/content/categories/category/landing/check-title', {
            group_id,
            title,
            id
        })
        return response
    },
    async [ACTION_VALIDATE_BLOG_CATEGORY](ctx, { group_id, title, id }) {
        const response = await Api.post('/api/content/categories/category/blog/check-title', {
            group_id,
            title,
            id
        })
        return response
    },
    async [ACTION_VALIDATE_ESSAY_CATEGORY](ctx, { group_id, title, id }) {
        const response = await Api.post('/api/content/categories/category/essay/check-title', {
            group_id,
            title,
            id
        })
        return response
    }
}
