<template>
    <div
        :class="isShowedSidebar ? 'opened' : 'closed'"
        class="sidebar sidebar-panel"
    >
        <router-link
            v-for="(link, indexLink) in showingMenuItems"
            :key="indexLink"
            class="sidebar-panel__subheading"
            :class="{'is-active' : isActive(link)}"
            exact
            :disabled="$route.name === link.route ? true : false"
            :event="$route.name === link.route ? '' : 'click'"
            :to="{
                name: link.route,
                params: link.params
            }"
        >
            <span class="sidebar-panel__subheading-icon">
                <component :is="link.icon" />
            </span>
            <span class="right-tooltip">
                {{ link.text }}
            </span>
            <router-link
                v-if="link.routeAdd && (!link.edit_permission || permissions.includes(link.edit_permission))"
                :to="{
                    name: link.routeAdd,
                    params: link.addParam
                }"
                event=""
                class="sidebar-panel__subheading-add"
                @click.native.prevent="createLinkPost(link)"
            >
                <span class="sidebar-panel__subheading-add__icon">
                    <cross />
                </span>
            </router-link>
            <router-link
                v-if="findUnfinishedSession(link.name) && (!link.edit_permission || permissions.includes(link.edit_permission))"
                class="sidebar-panel__subheading-add sidebar-panel__subheading-draft"
                :to="{ path: findUnfinishedSessionUrl(link.name) }"
                replace
            >
                <outline />
            </router-link>
        </router-link>
        <confirm-modal
            :title="`Create and clear draft?`"
            :content="'The draft will be cleared. Are you sure?'"
            :name="'clear-draft-modal-sidebar'"
            @onConfirmClick="clearDraft"
            @onCancelClick="cancelCreate"
        />
    </div>
</template>

<script>

/// / ICONS
import modalsMixins from '@/mixins/modals/modalsMixins'
import Lock from '@/icons/Lock'
import Arrow from '@/icons/Arrow'
import History from '@/icons/History'
import Pencil from '@/icons/Pencil'
import LinkIcon from '@/icons/Link'
import Cross from '@/icons/Cross'
import Users from '@/icons/Users'
import Page from '@/icons/Page'
import List from '@/icons/List'
import Desktop from '@/icons/Desktop'
import OutreachLinks from '@/icons/OutreachLinks'
import AccountCircle from '@/icons/AccountCircle'
import PlaylistCheck from 'mdi-vue/PlaylistCheck.vue'
import Outline from '@/icons/Outline';
import { helperPermissionFilter } from '@/helpers/functions/helperPermissionFilter'
import { mapGetters } from 'vuex'
import { items } from './menu-parts-new/SidebarMenuHelpers'

export default {
    name: 'Sidebar',
    components: {
        Lock,
        Arrow,
        History,
        Pencil,
        LinkIcon,
        Cross,
        Users,
        Page,
        List,
        Desktop,
        OutreachLinks,
        AccountCircle,
        Outline,
        PlaylistCheck
    },
    mixins: [modalsMixins],
    data() {
        return {
            navItems: items,
            isShowedSidebar: true,
            modalLink: null
        }
    },
    computed: {
        ...mapGetters('auth', {
            permissions: 'getterUserPermissions'
        }),
        resetMutationModuleName() {
            if (!this.modalLink) {
                return ''
            }
            const contentType = this.modalLink.name[0].toUpperCase() + this.modalLink.name.substring(1)
            return `content${contentType}Edit`
        },
        showingMenuItems() {
            return this.navItems.filter((item) => helperPermissionFilter(item, this.permissions));
        }
    },
    mounted() {
        this.$bus.$on('toggleSidebar', (val) => {
            this.isShowedSidebar = val
        })
    },
    updated() {
        const findEvent = this.$bus['_events']['toggleSidebar']
        if (findEvent && findEvent.length) return
        this.$bus.$on('toggleSidebar', (val) => {
            this.isShowedSidebar = val
        })
    },

    beforeDestroy() {
        this.$bus.$off('toggleSidebar')
    },
    methods: {
        isActive(link) {
            return this.$route.name === link.route || this.$route.name === link.routeAdd
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        openLinks(index) {
            const item = this.showingMenuItems[index]
            item.opened = !item.opened
            this.showingMenuItems.splice(index, 1, item)
        },
        findUnfinishedSession(name) {
            const storeContentModule = name && this.$store.state[`content${this.capitalizeFirstLetter(name)}Edit`]
            if (storeContentModule && storeContentModule[name]) {
                return storeContentModule[name].unfinished_session === true
            }
            return false
        },
        findUnfinishedSessionUrl(name) {
            const storeContentModule = name && this.$store.state[`content${this.capitalizeFirstLetter(name)}Edit`]
            if (storeContentModule && storeContentModule[name]) {
                return storeContentModule[name].unfinished_url
            }
            return ''
        },
        createLinkPost(link) {
            this.modalLink = null
            if (!this.findUnfinishedSessionUrl(link.name)) {
                this.$router.push({
                    name: link.routeAdd,
                    params: link.addParam
                })
                return
            }
            this.modalLink = link
            this.$bus.$emit('openModal', 'clear-draft-modal-sidebar')
        },
        clearDraft() {
            this.$store.commit(`${this.resetMutationModuleName}/RESET_EDIT_FORM_DATA`)
            this.$store.commit(`${this.resetMutationModuleName}/RESET_CREATED_FORM_DATA`)
            this.$router.push({
                name: this.modalLink.routeAdd,
                params: this.modalLink.addParam
            })
        },
        cancelCreate() {
            this.modalLink = null
        }
    }
}
</script>

<style lang="scss" scoped>
    .sidebar-panel {
        transition: width .5s ease;
        overflow-y: auto;
    }
    .sidebar-panel:hover {
        .sidebar-panel__subheading{
            width: 250px;
        }
        .right-tooltip {
            display: inline-block!important;
            opacity: 1!important;
            padding: 8.5px 14px;
            width: auto;
        }
    }
    .sidebar-panel__heading {
        cursor: pointer;
        outline: none;
    }
    .sidebar-panel__subheading-add {
        svg {
            position: relative;
            z-index: 111;
        }
    }
    .sidebar-panel__subheading {
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        justify-content: flex-start;
        padding-left: 10px;
        .right-tooltip {
            color: #fff;
            left: 110%;
            white-space: nowrap;
            opacity: 0;
            display: none;
        }
        &:hover {
            width: 300px;
        }
    }
    .sidebar-panel__subheading-add__icon {
        transform: rotate(-45deg);
    }
</style>
