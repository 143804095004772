<template>
    <modal-layout
        v-if="dialog"
        :size="'small'"
        :name="name"
        :hidden="hidden"
        :style="{ 'z-index': order }"
        @closeModal="cancelModal()"
        @hideModal="hideModal(name)"
    >
        <template #header>
            <h2>{{ title }}</h2>
        </template>
        <template #content>
            <slot name="content">
                <p>{{ content }}</p>
            </slot>
        </template>
        <template #buttons>
            <custom-button
                default
                class="orange-button"
                @on-btn-click="confirmModal(name)"
            >
                Confirm
            </custom-button>
            <custom-button
                default
                @on-btn-click="cancelModal(name)"
            >
                Cancel
            </custom-button>
        </template>
    </modal-layout>
</template>

<script>
import modalsEventBusMixins from '@/mixins/modals/modalsEventBusMixins'

export default {
    name: 'ConfirmModal',
    mixins: [
        modalsEventBusMixins
    ],
    props: {
        title: String,
        content: String,
        name: String
    }
}
</script>
