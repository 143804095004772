<template>
    <div class="tasks_status">
        <div
            class="tasks_status-control"
            :style="`background-color: ${getColorStatus}`"
        >
            {{ statuses[value] }}
        </div>
    </div>
</template>

<script>
import { mapTasksFields } from '@/store/modules/tasks'

export default {
    name: 'RowStatus',
    props: ['value'],
    computed: {
        ...mapTasksFields([
            'statuses'
        ]),
        getColorStatus() {
            switch (this.value) {
            case 1:
                return 'rgba(255, 84, 4, 0.2);'
            case 2:
                return 'rgba(39, 217, 31, 0.2);'
            case 3:
                return 'rgba(25, 118, 210, 0.2);'
            case 4:
                return 'rgba(25, 118, 210, 0.2);'
            case 5:
                return 'rgba(25, 118, 210, 0.5);'
            case 6:
                return '#EBDCF9'
            default:
                return '#FFFFFF'
            }
        }
    }
}
</script>

<style lang="scss">
    .tasks_status-control{
        border-radius: 4px;
        padding: 5px 10px;
        width: max-content;
        font-size: 14px;
        text-transform: capitalize;
    }
</style>
