import { updateField } from 'vuex-map-fields';
import { LandingPost } from '@/models/Content/LandingPost'

import { cloneDeep } from 'lodash'

import {
    RESET_CREATED_FORM_DATA,
    RESET_EDIT_FORM_DATA,
    SET_LANDING_POST,
    SET_LOADING,
    RESET_UNFINISHED_URL
} from './mutation-types'

export const mutations = {
    updateField,
    [SET_LOADING](state, payload) {
        state.loading = payload
    },
    [RESET_CREATED_FORM_DATA](state) {
        state.landing.form_data = new LandingPost()
        state.landing.default_form_data = new LandingPost()
    },
    [RESET_EDIT_FORM_DATA](state) {
        state.landing.form_data = cloneDeep(state.landing.default_form_data)
    },
    [SET_LANDING_POST](state, payload) {
        state.landing.form_data = cloneDeep(payload)
        state.landing.default_form_data = cloneDeep(payload)
    },
    [RESET_UNFINISHED_URL](state, { name }) {
        state[name].unfinished_session = false
        state[name].unfinished_url = null
        state[name].unfinished_type = null
    }
}
