<template>
    <div class="custom-table-url">
        <div class="custom-table-block__url">
            <template>
                <div class="custom-table-block__url-title">
                    <div
                        @click="routerChange"
                    >
                        {{ row.post_meta_title || row.title }}
                    </div>
                </div>

                <div class="custom-table-block__url-description body-1 mt-1">
                    <picture v-if="row.cover_image_url && !errorLoading">
                        <source
                            :srcset="`${ row.cover_image_url }?encode=jpeg&size=thumbnail-mini`"
                            @error="errorHandler"
                        >
                        <img
                            :src="`${ row.cover_image_url }?encode=jpeg&size=thumbnail-mini`"
                            @error="errorHandler"
                        >
                    </picture>
                    {{ getShowingText(description) }}
                    <span v-if="description !== getShowingText(description)">...</span>
                    <div class="clear" />
                </div>
                <div
                    class="custom-table__row-cell__slug"
                >
                    <span
                        ref="link"
                        class="link_path"
                        @click="goToLonk(row.url)"
                    >
                        {{ row.url.replace(/^.*\/\/[^\/]+/, '') }}
                    </span>
                    <span
                        style="cursor: pointer; margin-right: 20px"
                        @click="copy"
                    > <checkbox-multiple-blank-outline /> </span>
                </div>
                <div
                    v-if="row.category_id"
                    class="custom-table-block__url-category"
                >
                    <p
                        v-if="row.category_group_title"
                        class="custom-table-block__url-category-item"
                    >
                        {{ row.category_group_title }}
                    </p> <span v-if="row.category_group_title && row.category_title">/</span> <p
                        v-if="row.category_title"
                        class="custom-table-block__url-category-item"
                    >
                        {{ row.category_title }}
                    </p>
                </div>
                <div
                    v-if="row.redirect_to"
                    class="custom-table-block__url-link"
                    @click="goToLonk(row.redirect_to)"
                >
                    <tooltip-component :text="row.redirect_to">
                        <LinkIcon />
                    </tooltip-component>
                </div>
            </template>
            <div
                v-if="row.redirect===1"
                class="custom-table__row-cell__redirect"
            >
                <span style="color:red;">redirect:</span>
                <span style="color:red;">{{ row.redirect_to }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import LinkIcon from 'mdi-vue/Link.vue';
import CheckboxMultipleBlankOutline from 'mdi-vue/CheckboxMultipleBlankOutline.vue'
import TooltipComponent from '@/components/TooltipComponent';

export default {
    name: 'RowUrl',
    components: { LinkIcon, TooltipComponent, CheckboxMultipleBlankOutline },
    props: {
        row: {
            type: Object
        },
        hash: {},
        contentType: String
    },
    data() {
        return {
            errorLoading: false
        }
    },
    computed: {
        description() {
            return this.row.post_meta_description || this.row.meta_description
        }
    },
    methods: {
        errorHandler() {
            this.errorLoading = true
        },
        copy() {
            this.$clipboard(this.row.url)
            this.$bus.$emit('showSnackBar', 'Link copied to clipboard', 'success')
        },
        getShowingText(text) {
            return text?.substring(0, 87) || text
        },
        async routerChange() {
            await this.$router.replace({
                hash: this.hash,
                query: this.$route.query
            }).catch(() => {})
            await this.$router.push({
                name: `edit-post-${this.contentType}-preview`,
                params: {
                    id: this.row.id,
                    mode: 'edit'
                }
            })
        },
        goToLonk(url) {
            window.open(url, '_blank').focus();
        }
    }
}
</script>

<style lang="scss">
.custom-table-block__url {
    margin-bottom: 0 !important;
    width: 100%;
    &-title {
        color: #1976d2;
        font-weight: 500;
        line-height: 1.5rem;
        width: inherit;
        margin-bottom: 4px;
        word-wrap: break-word;
        cursor: pointer;
    }
    &-description {
        width: inherit;
        font-size: 1rem!important;
        letter-spacing: .03125em!important;
        line-height: 1.5rem;
        font-weight: 300;
        color: rgba(0,0,0,.87);
        // font-family: Roboto,sans-serif!important;
        margin-bottom: 8px;
        word-wrap: break-word;
        img{
            float: left;
            margin-right: 5px;
            margin-bottom: 5px;
            clear:left;
            width: 55px;
            height: 40px;
        }
        .clear{
            clear:both;
        }
    }
    &-link {
        font-size: .875rem!important;
        letter-spacing: .0178571429em!important;
        line-height: 1.25rem;
        font-weight: 400;
        // font-family: Roboto,sans-serif!important;
        width: inherit;
        margin-bottom: 4px;
        word-wrap: break-word;
        width: 30px;
        text-align: left;
        cursor: pointer!important;
        * {
            cursor: pointer!important;
        }
        a {
            color: #1976d2;
        }
    }
    &-category {
        font-size: .875rem!important;
        font-weight: 400;
        margin-bottom: 8px;
        color: rgba(0,0,0,.87);
        width: inherit;
        word-wrap: break-word;
        display: flex;
        align-items: center;
        span {
            margin: 0 10px;
        }
        &-item {
            border-radius: 62px;
            display: inline-block;
            padding: 8px 10px;
            background: $round-count;
            color: $orange;
            line-height: 1.5;
            max-width: 180px;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }
    }
}
.custom-table__row-cell__slug {
    font-weight: normal;
    margin: 10px;
    word-break: break-all;
    margin-left: 0;
    span {
        &.link_path{
            cursor: pointer;
            color: #1976d2;
        }
    }
}
</style>
