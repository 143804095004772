<template>
    <modal-layout
        v-if="dialog"
        :size="'small'"
        :name="name"
        :hidden="hidden"
        :style="{ 'z-index': order }"
        @closeModal="cancelModal()"
        @hideModal="hideModal(name)"
    >
        <template #header>
            <h2>{{ title }}</h2>
        </template>
        <template #content>
            <component
                :is="checkTypeSelect"
                v-model="$v.value.$model"
                name="value-list"
                :field.sync="$v.value.$model"
                :label="inputLabel ? inputLabel : name"
                tabindex="0"
                :items="listObject"
                :errors="validationMessage($v.value)"
                :is-valid="!$v.value.$anyError && !$v.value.$invalid"
                @blur="$v.value.$touch()"
            />
        </template>
        <template #buttons>
            <custom-button
                default
                class="orange-button"
                @on-btn-click="validate"
            >
                Confirm
            </custom-button>
            <custom-button
                default
                @on-btn-click="cancelModal(name)"
            >
                Cancel
            </custom-button>
        </template>
    </modal-layout>
</template>

<script>
import modalsEventBusMixins from '@/mixins/modals/modalsEventBusMixins'

import formValidationMixin from '@/mixins/formValidationMixin.js'
import { validationMessage } from 'vuelidate-messages'
import { validationMixin } from 'vuelidate';
import {
    required
} from 'vuelidate/lib/validators';

export default {
    name: 'PrioritieModal',
    mixins: [
        modalsEventBusMixins,
        validationMixin,
        formValidationMixin
    ],
    props: {
        title: String,
        name: String,
        contentType: String,
        listObject: {},
        inputLabel: {
            type: String
        },
        type: {
            type: String,
            default: 'default'
        },
        prependValue: {}
    },
    validations: {
        value: {
            required
        }
    },
    data() {
        return {
            value: null
        }
    },
    computed: {
        checkTypeSelect() {
            if (this.type === 'status') return 'FormSelectStatus'
            if (this.type === 'priority') return 'FormSelectPriority'
            return 'FormSelect'
        }
    },
    watch: {
        prependValue(val) {
            if (val || val === 0) {
                this.value = val
            }
            if (val === null) {
                this.value = null
            }
        }
    },
    created() {
        if (this.prependValue || this.prependValue === 0) {
            this.value = this.prependValue
        }
        if (this.prependValue === null) {
            this.value = null
        }
    },
    methods: {
        validationMessage: validationMessage(),
        submit() {
            this.$emit('changeData', this.value)
            this.confirmModal(this.name, this.value)
            this.value = null
        }
    }
}
</script>
