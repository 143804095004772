import Api from '@/helpers/api/index.js'

import {
    ACTION_LOGIN_ADMIN,
    ACTION_RESET_PASSWORD,
    ACTION_VALIDATE_RESET_TOKEN,
    ACTION_ADMIN_RESET_PASSWORD_SET_NEW,
    ACTION_CREATE_ADMIN,
    ACTION_ADMIN_CHECK_EMAIL,
    ACTION_ADMIN_CHECK_PHONE,
    ACTION_LOGOUT_ADMIN,
    ACTION_REFRESH_TOKEN,
    ACTION_GET_CURRENT_USER
} from './action-types'

import {
    LOGIN_ADMIN,
    LOGOUT_ADMIN,
    SET_ADMIN_TIMEZONE,
    SET_CURRENT_USER
} from './mutation-types'

export const actions = {

    // Get current user

    async [ACTION_GET_CURRENT_USER]({ commit }) {
        try {
            const response = await Api.get('/api/users/current-user')
            commit(SET_CURRENT_USER, response.data.current_user)
            return response
        } catch (e) {
            console.log(e)
            return e
        }
    },

    // Refresh token

    async [ACTION_REFRESH_TOKEN]({ commit }, payload) {
        Api.get('/api/auth/refresh-token', payload)
            .then(async (response) => {
                const data = {
                    ...response.data.data,
                    token: response.data.data.access_token
                }
                commit(LOGIN_ADMIN, data)
            })
    },

    // Login page

    async [ACTION_LOGIN_ADMIN]({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Api.post('/api/auth/login', payload)
                .then((response) => {
                    commit(LOGIN_ADMIN, response.data.user)
                    resolve(response.data.user)
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },

    // Logout

    [ACTION_LOGOUT_ADMIN]({ commit }) {
        return new Promise((resolve) => {
            commit(LOGOUT_ADMIN)
            resolve(true)
        })
    },

    // Reset page

    async [ACTION_RESET_PASSWORD](ctx, { email }) {
        try {
            await Api.post('/api/auth/forgotten/reset', { email })
        } catch (e) {
            console.log(e)
        }
    },

    // Forgot page

    async [ACTION_VALIDATE_RESET_TOKEN](ctx, { token }) {
        try {
            const response = await Api.get('/api/auth/forgotten/valid-reset-token', {
                token
            })
            return response
        } catch (e) {
            throw new Error(e)
        }
    },
    async [ACTION_ADMIN_RESET_PASSWORD_SET_NEW](ctx, payload) {
        try {
            await Api.put('/api/auth/forgotten/new-password', payload)
        } catch (e) {
            console.log(e)
            throw e
        }
    },

    // Register page

    async [ACTION_CREATE_ADMIN]({ commit, state, rootState }, {
        firstname, lastname, email, password, nickname
    }) {
        commit(SET_ADMIN_TIMEZONE)
        const {
            phone_number, country
        } = rootState.phone.form_data
        const form_data = {
            firstname,
            lastname,
            email,
            password,
            nickname,
            phone: phone_number,
            phone_code: `${country.phone_code}`,
            country: `${country.iso}`,
            timezone: state.timezone

        }
        const data = JSON.parse(JSON.stringify(form_data));
        try {
            await Api.post('/api/auth/registration', data)
            commit('phone/SET_DEFAULT', {}, { root: true })
        } catch (e) {
            console.log(e)
        }
    },

    // Check exists

    async [ACTION_ADMIN_CHECK_EMAIL](ctx, payload) {
        const options = {
            dialog: false
        }
        const response = await Api.get('/api/auth/validation/email', payload, options)
        return response
    },

    [ACTION_ADMIN_CHECK_PHONE](ctx, payload) {
        return new Promise((resolve, reject) => {
            Api.get('/api/auth/validation/phone', payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}
