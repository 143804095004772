import ConfirmModal from '@/components/modals/ConfirmModal'
import CategoryModal from '@/components/modals/CategoryModal'
import ModalSelect from '@/components/modals/ModalSelect'

export default {
    props: {
        contentType: String,
        data: Array
    },
    components: {
        ConfirmModal,
        CategoryModal,
        ModalSelect
    },
    data() {
        return {
            MODAL_NAME: ''
        }
    },
    computed: {
        getCheckedRows() {
            return this.data?.filter((item) => item.checked === true).length || []
        },
        getCheckedElementsId() {
            const idArr = []
            this.data.forEach((item) => {
                if (item.checked) {
                    idArr.push(parseInt(item.id, 10))
                }
            })
            return idArr
        }
    },
    mounted() {
        this.MODAL_NAME = this.name
        this.$bus.$on('confirm', (modalName, payload) => {
            if (this.MODAL_NAME === modalName && this.$route.name === `posts-list-${this.contentType}`) {
                this.submit(this.contentType, payload)
            }
        })
    },
    beforeDestroy() {
        this.$bus.$off('confirm')
    },
    methods: {
        openModal(modalName) {
            this.$bus.$emit('openModal', modalName)
        }
    }
}
