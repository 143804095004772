<template>
    <div class="tasks_priority">
        <TooltipComponent :text="priorities[value]">
            <div
                v-if="priorities"
                class="tasks_priority-control"
            >
                <img
                    v-if="priorities[value] === 'high'"
                    src="@/assets/img/svg/priority_high.svg"
                    alt=""
                >
                <img
                    v-if="priorities[value] === 'medium'"
                    src="@/assets/img/svg/priority_normal.svg"
                    alt=""
                >
                <img
                    v-if="priorities[value] === 'low'"
                    src="@/assets/img/svg/priority_low.svg"
                    alt=""
                >
            </div>
        </TooltipComponent>
    </div>
</template>

<script>
import TooltipComponent from '@/components/TooltipComponent';
import { mapTasksFields } from '@/store/modules/tasks'

export default {
    name: 'RowPriority',
    components: {
        TooltipComponent
    },
    props: ['value'],
    computed: {
        ...mapTasksFields([
            'priorities'
        ]),
        getColorStatus() {
            switch (this.value) {
            case 1:
                return 'rgba(255, 84, 4, 0.2);'
            case 2:
                return 'rgba(39, 217, 31, 0.2);'
            default:
                return 'rgba(79, 79, 79, 0.2);'
            }
        }
    }
}
</script>

<style lang="scss">
    .tasks_priority-control{
        border-radius: 4px;
        width: max-content;
        font-size: 14px;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        img{
            margin-right: 10px;
        }
    }
</style>
