const BlogRevisions = () => import(
    '@/views/Content/Blog/BlogRevisions'
)

export default [
    {
        path: '/content/revisions/blog/:id',
        component: BlogRevisions,
        name: 'revisions-list-blog',
        meta: {
            requiresAuth: true,
            permission: 'content/manage',
            contentType: 'blog',
            link: {
                name: 'blog',
                routeAdd: 'create-post-blog',
                edit_permission: 'content/blog/change'
            }
        },
        props: (route) => ({
            contentType: route.meta.contentType
        })
    }
]
