<template>
    <fieldset class="form-textarea__fieldset">
        <legend
            v-if="legend"
            class="form-textarea__legend"
            :class="{ 'error-font-color': validationError }"
        >
            {{ legend }}
        </legend>
        <component
            :is="prependIcon"
            class="form-textarea__prepend-icon"
        />
        <textarea
            class="form-textarea__input"
            :class="{ 'error-border': errors, 'success' : isValid }"
            :type="type"
            :name="name"
            :value="field"
            :disabled="disabled"
            :placeholder="placeholder"
            @input="onChange($event)"
        />
        <span @click="test">
            <component
                :is="appendIcon"
                class="form-textarea__append-icon"
            />
        </span>

        <div
            v-if="errors"
            class="form-textarea__error"
        >
            {{ errors }}
        </div>
        <div class="form-textarea__length">
            {{ field && field.length }}
        </div>
    </fieldset>
</template>

<script>
import EmailOutline from 'mdi-vue/EmailOutline.vue';
import Lock from 'mdi-vue/Lock.vue';
import Account from 'mdi-vue/Account.vue';
import Eye from 'mdi-vue/Eye.vue';
import EyeOff from 'mdi-vue/EyeOff.vue';

export default {
    name: 'FormTextarea',
    components: {
        EmailOutlineIcon: EmailOutline,
        LockIcon: Lock,
        AccountIcon: Account,
        EyeIcon: Eye,
        EyeOffIcon: EyeOff
    },
    props: {
        value: {
            type: String
        },
        prependIcon: {
            type: String
        },
        appendIcon: {
            type: String
        },
        legend: {
            type: String
        },
        type: {
            type: String
        },
        name: {
            type: String
        },
        field: {
            type: String
        },
        errors: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean
        },
        isValid: {
            type: Boolean
        }
    },
    data() {
        return {
            val: ''
        };
    },
    methods: {
        onChange(event) {
            this.$emit('update:field', event.target.value);
            this.$emit('onChange', event.target.value)
        },
        test() {
            this.$emit('clicked');
        }
    }
};
</script>

<style lang="scss">
.form-textarea {
    &__fieldset {
        border: none !important;
        border-radius: 4px;
        padding: 0;
        position: relative;
        width: 100%;
        textarea {
            font-size: 13px;
            border: 1px solid #d3d9e2;
            user-select: text !important;
            background: transparent;
            &:focus {
                border: 1px solid #666;
            }
            &.error-border {
                border: 1px solid red;
            }
            &.success {
                border: 1px solid #27d91f;
            }
        }
    }
    &__prepend-icon {
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translateY(-50%);
        color: rgba(0, 0, 0, 0.54);
    }
    &__append-icon {
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
        color: rgba(0, 0, 0, 0.54);
    }
    &__legend {
        display: table;
        color: #b3bbc6;
        caret-color: #b3bbc6;
        padding: 2px 5px 2px 5px;
        font-size: 80%;
        position: absolute;
        left: 6px;
        top: -8px;
        background-color: white;
        border: none;
    }
    &__input {
        box-sizing: border-box;
        display: inline-block;
        padding: 10px 40px 10px 10px;
        width: 100%;
        font-size: 14px;
        user-select: text;
        line-height: 2em;
        border: none;
        outline: none;
        border-radius: 4px;
        font-family: 'Mark Pro', sans-serif;
        resize: vertical;
        min-height: 100px;
    }
    &__error {
        display: inline;
        width: auto;
        text-align: left;
        color: #ff5252;
        caret-color: #ff5252;
        font-size: 12px;
        min-height: 14px;
        position: absolute;
        left: 13px;
        bottom: -3px;
        background: white;
        padding: 0 3px;
    }
    &__length {
        display: inline;
        width: auto;
        text-align: left;
        color: rgba(0, 0, 0, 0.6);
        caret-color: rgba(0, 0, 0, 0.6);
        font-size: 12px;
        min-height: 14px;
        position: absolute;
        right: 20px;
        bottom: -4px;
        background: white;
        padding: 0 3px;
    }
}
.error-border {
    border: 1px solid red !important;
}
.form-textarea__input.success {
    border: 1px solid #27d91f;
}
.form-textarea__input {
    &:disabled {
        background: rgb(248, 248, 248);
    }
}

</style>
