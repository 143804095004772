import { BasePost } from './BasePost';

export class LandingPost extends BasePost {
    constructor(payload = {}) {
        super(payload)
        this.redirect = payload.redirect || false
        this.redirect_to = payload.redirect_to || ''
        this.category_id = payload.category_id || ''
        this.post_title_primary = payload.post_title_primary || ''
        this.post_title_secondary = payload.post_title_secondary || ''
        this.post_content_primary = payload.post_content_primary || ''
        this.post_button_keyword = payload.post_button_keyword || ''
        this.post_content_secondary = payload.post_content_secondary || ''
        this.post_keyword_singular = payload.post_keyword_singular || ''
        this.post_keyword_plural = payload.post_keyword_plural || ''
        this.wordcount = payload.wordcount || null
        this.cover_image_url = payload.cover_image_url || null
        this.price = payload.price || ''
    }
}

export function createLanding(data) {
    return Object.freeze(new LandingPost(data));
}
