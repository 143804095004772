import api from '@/helpers/api'

const options = {
    dialog: false
}

export const changPassword = (password) => api.put('/api/users/change-password', { password })

export const changPhone = (phone, phone_code) => api.put('/api/users/change-phone', { phone, phone_code })

export const changeEmail = (email) => api.put('/api/users/change-email', { email })

export const changeUserName = (payload) => api.put('/api/users/change-name', { ...payload })

export const changeUserBio = (payload) => api.put('/api/users/change-biography', { ...payload })

export const checkEmail = (email) => api.get('/api/auth/validation/email', { email }, options)

export const checkPassword = (password) => api.get('/api/users/validate-password', { password }, options)

export const changeUserNickName = (paylaod) => api.put('/api/users/change-nickname', { ...paylaod })

export const getUserById = (id) => api.get('/api/users/info-user', { id })
