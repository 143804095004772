<template>
    <div
        class="custom-table__row-locale"
    >
        {{ row.locale }}
    </div>
</template>

<script>
export default {
    name: 'RowLocale',
    props: {
        row: {
            type: Object
        }
    }
}
</script>
<style lang="scss">
    .custom-table__row-locale {
        // font-family: Roboto,sans-serif!important;
        font-weight: 300;
    }
</style>
