<template>
    <div
        class="custom-table__row-link"
    >
        <tooltip-component
            v-if="value"
            :text="value"
        >
            <a
                ref="link"
                :href="value"
                target="_blank"
            > {{ stripSlug(value) }} </a>
            <span
                style="cursor: pointer; margin-right: 20px"
                @click="copy"
            > <checkbox-multiple-blank-outline /> </span>
        </tooltip-component>
    </div>
</template>

<script>
import CheckboxMultipleBlankOutline from 'mdi-vue/CheckboxMultipleBlankOutline.vue'
import TooltipComponent from '@/components/TooltipComponent'

export default {
    name: 'RowLinkOutreachDonor',
    components: { CheckboxMultipleBlankOutline, TooltipComponent },
    props: {
        value: {
            type: String
        }
    },
    methods: {
        copy() {
            this.$clipboard(this.value)
            this.$bus.$emit('showSnackBar', 'Link copied to clipboard', 'success')
        },
        stripSlug(link) {
            const url = new URL(link)
            return url.hostname
        }
    }
}
</script>

<style scoped>
a {
    display:inline-block;
    max-width: 180px;
    overflow: hidden !important;
    line-height: 24px;
    margin-right: 10px;
    color: #1976d2;
    font-weight: 500;
    word-break: break-all;
    text-align: left;
}
</style>
