export const LandingNames = {
    locale: 'Locale',
    post_meta_title: '<Meta Title> tag',
    post_meta_description: '<Meta Description> tag',
    post_slug: 'Slug',
    post_title_secondary: 'H1 Title',
    category_id: 'Category',
    post_title_primary: 'Primary Title',
    post_keyword_singular: 'Keywords singular',
    post_keyword_plural: 'Keywords plural',
    post_button_keyword: 'Button Keywords',
    post_content_primary: 'Description',
    post_content_secondary: 'Content'
}
