const MenuBuilder = () => import(
    '@/views/Settings/MenuBuilder'
)

const ActivityLog = () => import(
    '@/views/Settings/ActivityLog'
)

const UserProfile = () => import(
    '@/views/Settings/UserProfile'
)

const ChangeEmail = () => import(
    '@/views/Settings/UserProfile/ChangeEmail'
)

const ChangePassword = () => import(
    '@/views/Settings/UserProfile/ChangePassword'
)

const ChangePhone = () => import(
    '@/views/Settings/UserProfile/ChangePhone'
)

const ChangeBiography = () => import(
    '@/views/Settings/UserProfile/ChangeBiography'
)

const ChangeUserName = () => import(
    '@/views/Settings/UserProfile/ChangeUserName'
)

const ChangeUserNickName = () => import(
    '@/views/Settings/UserProfile/ChangeUserNickName'
)

export default [
    { // menu-editor
        path: '/admin/dashboard/view/settings/menu',
        component: MenuBuilder,
        name: 'menu-editor',
        meta: {
            requiresAuth: true,
            permission: 'content/manage',
            contentType: 'menu editor'
        }
    },
    { // activity-log
        path: '/admin/dashboard/view/settings/activity-log',
        component: ActivityLog,
        name: 'activity-log',
        meta: {
            requiresAuth: true,
            permission: 'content/manage',
            contentType: 'activity log'
        }
    },
    { // user-profile
        path: '/admin/dashboard/view/settings/profile',
        component: UserProfile,
        name: 'user-profile',
        meta: {
            requiresAuth: true,
            permission: 'content/manage',
            contentType: 'profile'
        }
    },
    { // change-email
        path: '/admin/dashboard/view/settings/change-email',
        component: ChangeEmail,
        name: 'change-email',
        meta: {
            requiresAuth: true,
            permission: 'content/manage',
            contentType: 'profile'
        }
    },
    { // change-password
        path: '/admin/dashboard/view/settings/change-password',
        component: ChangePassword,
        name: 'change-password',
        meta: {
            requiresAuth: true,
            permission: 'content/manage',
            contentType: 'profile'
        }
    },
    { // change-phone
        path: '/admin/dashboard/view/settings/change-phone',
        component: ChangePhone,
        name: 'change-phone',
        meta: {
            requiresAuth: true,
            permission: 'content/manage',
            contentType: 'profile'
        }
    },
    { // change-user-name
        path: '/admin/dashboard/view/settings/change-user-name',
        component: ChangeUserName,
        name: 'change-user-name',
        meta: {
            requiresAuth: true,
            permission: 'content/manage',
            contentType: 'profile'
        }
    },
    { // change-user-nick-name
        path: '/admin/dashboard/view/settings/change-user-nick-name',
        component: ChangeUserNickName,
        name: 'change-user-nick-name',
        meta: {
            requiresAuth: true,
            permission: 'content/manage',
            contentType: 'profile'
        }
    },
    { // change-phone
        path: '/admin/dashboard/view/settings/change-biography',
        component: ChangeBiography,
        name: 'change-biography',
        meta: {
            requiresAuth: true,
            permission: 'content/manage',
            contentType: 'profile'
        }
    }
]
