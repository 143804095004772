import { updateField } from 'vuex-map-fields';

import {
    SET_LANDING_LIST,
    UPDATE_LIST_ITEMS,
    UPDATE_LIST_ITEM,
    UPDATE_LIST_ITEM_REVISIONS,
    UPDATE_LIST_ITEM_REVIEWS,
    SET_BLOG_LIST,
    SET_ESSAY_LIST,
    SET_LOADING
} from './mutation-types'

export const mutations = {
    updateField,

    [SET_LOADING](state, payload) {
        state.loading = payload
    },

    // Landing

    [SET_LANDING_LIST](state, payload) {
        state.landings = payload
    },

    // Blog

    [SET_BLOG_LIST](state, payload) {
        state.blogs = payload
    },

    // Essay

    [SET_ESSAY_LIST](state, payload) {
        state.essays = payload
    },

    // FOR ALL

    [UPDATE_LIST_ITEMS](state, { name, data }) {
        data.forEach((item) => {
            const updatedItem = item
            const index = state[name].data.findIndex((i) => i.id === item.id)
            updatedItem.checked = false
            updatedItem.is_updated = true
            if (index !== -1) state[name].data.splice(index, 1, updatedItem)
        })
    },
    [UPDATE_LIST_ITEM](state, { name, data, save_position = false }) {
        const index = state[name].data.findIndex((item) => item.id === data.id)
        if (save_position) {
            state[name].data.splice(index, 1, { ...data, is_updated: true, checked: false })
            return
        }
        if (index !== -1) {
            state[name].data.splice(index, 1)
        }
        state[name].data.unshift({ ...data, is_updated: true, checked: false })
    },
    [UPDATE_LIST_ITEM_REVISIONS](state, { name, id }) {
        const index = state[name].data.findIndex((item) => item.id === id)
        if (index !== -1) {
            const item = state[name].data.find((item) => item.id === id)
            item.revisions -= 1
            state[name].data.splice(index, 1, item)
        }
    },
    [UPDATE_LIST_ITEM_REVIEWS](state, {
        type, id, revision_id, mode
    }) {
        const types = {
            landing_revisions: 'landings',
            essay_revisions: 'essays',
            blog_revisions: 'blogs'
        }
        const index = state[types[type]].data.findIndex((item) => item.id === id)
        if (index !== -1) {
            const item = state[types[type]].data.find((item) => item.id === id)
            if (item.revision_id !== revision_id) return
            if (mode === 'delete') item.active_revision_reviews_count -= 1
            else item.active_revision_reviews_count += 1
            state[types[type]].data.splice(index, 1, item)
        }
    }
}
