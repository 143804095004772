<template>
    <div class="custom-table__row-actions">
        <div
            class="row-action__block"
            @click="onEditClick"
        >
            <pencil />
        </div>
        <div
            class="row-action"
        >
            <span
                v-if="!row.categories.length"
                class="row-action__block"
                @click="onDeleteClick"
            >
                <garbage />
            </span>
            <span v-else />
        </div>
    </div>
</template>

<script>
import Pencil from '@/icons/Pencil';
import Garbage from '@/icons/Garbage';

export default {
    name: 'RowActions',
    components: {
        Pencil,
        Garbage
    },
    props: {
        row: Object
    },
    methods: {
        onEditClick() {
            this.$bus.$emit('openModal', 'edit-category-modal', this.row)
            this.$bus.$emit('actionEdit', this.row)
        },
        onDeleteClick() {
            this.$bus.$emit('openModal', 'delete-category-modal', this.row)
        }
    }
}
</script>

<style scoped lang="scss">
.custom-table__row-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 68px;
    .row-action {
        margin-right: 10px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .row-action__block {
        cursor: pointer;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
            background: rgba(79, 79, 79, 0.1);
            border-radius: 2px;
            color: $orange;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}
</style>
