export class SchemaAdmin {
    constructor({
        timezone = '',
        fullname = '',
        firstname = '',
        lastname = '',
        nickname = '',
        email = '',
        password = '',
        address = '',
        city = '',
        zip = '',
        country = '',
        region = '',
        paymethod = 'cc'
    } = {}) {
        this.timezone = timezone;
        this.fullname = fullname;
        this.firstname = firstname;
        this.lastname = lastname;
        this.nickname = nickname;
        this.email = email;
        this.password = password;
        this.address = address;
        this.city = city;
        this.zip = zip;
        this.country = country;
        this.region = region;
        this.paymethod = paymethod;
    }
}
