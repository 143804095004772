<template>
    <modal-layout
        v-if="dialog"
        :size="'small'"
        :name="name"
        :hidden="hidden"
        :style="{ 'z-index': order }"
        @closeModal="cancelModal()"
        @hideModal="hideModal(name)"
    >
        <template #header>
            <h2>{{ title }}</h2>
        </template>
        <template #content>
            <form-select-category
                v-model="$v.category.$model"
                name="category-list"
                :field.sync="$v.category.$model"
                label="Code"
                tabindex="0"
                :items="categoriesList"
                :errors="validationMessage($v.category)"
                :is-valid="!$v.category.$anyError && !$v.category.$invalid"
                @blur="$v.category.$touch()"
            />
        </template>
        <template #buttons>
            <custom-button
                default
                class="orange-button"
                @on-btn-click="validate"
            >
                Confirm
            </custom-button>
            <custom-button
                default
                @on-btn-click="cancelModal(name)"
            >
                Cancel
            </custom-button>
        </template>
    </modal-layout>
</template>

<script>
import modalsEventBusMixins from '@/mixins/modals/modalsEventBusMixins'
import formValidationMixin from '@/mixins/formValidationMixin.js'
import { validationMixin } from 'vuelidate';
import { validationMessage } from 'vuelidate-messages'
import { validationCategoryRules } from '@/validation/CategoryModal/Rules'
import { formMessages } from '@/validation/CategoryModal/Messages'

import { mapContentCategoryFields } from '@/store/modules/content/content-category'

export default {
    name: 'CategoryModal',
    mixins: [
        modalsEventBusMixins,
        validationMixin,
        formValidationMixin
    ],
    props: {
        title: String,
        name: String,
        contentType: String
    },
    validations: {
        ...validationCategoryRules
    },
    data() {
        return {
            categoriesList: [],
            category: null
        }
    },
    computed: {
        ...mapContentCategoryFields([
            'selectCategories'
        ])
    },
    mounted() {
        this.getCategories()
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        submit() {
            this.confirmModal(this.name, this.category)
            this.category = null
        },
        // here must be action to get categories
        getCategories() {
            this.categoriesList = [...this.selectCategories[this.contentType]]
        }
    }
}
</script>
