import { updateField } from 'vuex-map-fields';

import {
    SET_REVISIONS,
    UPDATE_REVISIONS,
    DELETE_REVISION,
    UPDATE_CURRENT_ACTIVE_REVISION,
    SET_LOADING,
    UPDATE_REVISION_REVIEWS_COUNT
} from './mutation-types'

export const mutations = {
    updateField,
    [SET_LOADING](state, payload) {
        state.loading = payload
    },
    [SET_REVISIONS](state, { name, data }) {
        state[name] = data
    },
    [UPDATE_REVISIONS](state, { name, data }) {
        const index = state[name].revisions.findIndex((item) => item.id === data.id)
        if (index !== -1) state[name].revisions.splice(index, 1, data)
    },
    [DELETE_REVISION](state, { name, id }) {
        const index = state[name].revisions.findIndex((item) => item.id === id)
        if (index !== -1) state[name].revisions.splice(index, 1)
    },
    [UPDATE_CURRENT_ACTIVE_REVISION](state, { name, id }) {
        state[name].post.revision_id = id
    },
    [UPDATE_REVISION_REVIEWS_COUNT](state, { type, id, mode }) {
        const types = {
            landing_revisions: 'landing',
            essay_revisions: 'essay',
            blog_revisions: 'blog'
        }
        const index = state[types[type]].revisions.findIndex((item) => item.id === id)
        if (index !== -1) {
            const item = state[types[type]].revisions.find((item) => item.id === id)
            if (mode === 'delete') item.reviews_count -= 1
            else item.reviews_count += 1
            state[types[type]].revisions.splice(index, 1, item)
        }
    }
}
