import { Task } from '@/models/Task'

export const initialState = {
    tasks: null,
    priorities: null,
    statuses: null,
    loading: false,
    form_data: new Task(),
    default_form_data: new Task(),
    types: {
        0: 'blog',
        1: 'essay',
        2: 'landing'
    }
}

export const state = { ...initialState }
