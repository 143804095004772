<template>
    <div
        v-if="startFrom"
        class="custom-table__row-index"
    >
        {{ id + startFrom }}
    </div>
    <div
        v-else
        class="custom-table__row-index"
    >
        {{ id + 1 }}
    </div>
</template>

<script>
export default {
    name: 'RowIndex',
    props: {
        row: {
            type: Object
        },
        id: {
            type: Number
        },
        startFrom: Number
    }
}
</script>
