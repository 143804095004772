export const ACTIONS_GET_LANDING_REVISIONS = 'ACTIONS_GET_LANDING_REVISIONS'
export const ACTION_ACTIVATE_LANDING_REVISION = 'ACTION_ACTIVATE_LANDING_REVISION'
export const ACTION_DELETE_LANDING_REVISION = 'ACTION_DELETE_LANDING_REVISION'

export const ACTIONS_GET_BLOG_REVISIONS = 'ACTIONS_GET_BLOG_REVISIONS'
export const ACTION_ACTIVATE_BLOG_REVISION = 'ACTION_ACTIVATE_BLOG_REVISION'
export const ACTION_DELETE_BLOG_REVISION = 'ACTION_DELETE_BLOG_REVISION'

export const ACTIONS_GET_ESSAY_REVISIONS = 'ACTIONS_GET_ESSAY_REVISIONS'
export const ACTION_ACTIVATE_ESSAY_REVISION = 'ACTION_ACTIVATE_ESSAY_REVISION'
export const ACTION_DELETE_ESSAY_REVISION = 'ACTION_DELETE_ESSAY_REVISION'
