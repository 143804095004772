<template>
    <div
        class="custom-table__row-wordcount"
    >
        {{ row.wordcount || row.words | number }}
    </div>
</template>

<script>
import eventsMixin from '@/mixins/content/eventsMixin'
import filtersMixin from '@/mixins/filtersMixin'

export default {
    name: 'RowWordcount',
    mixins: [
        eventsMixin,
        filtersMixin
    ],
    props: {
        row: {
            type: Object
        }
    }
}
</script>

<style lang="scss">
    .custom-table__row-wordcount {
        // font-family: Roboto,sans-serif!important;
        font-weight: 300;
    }
</style>
