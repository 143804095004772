<template>
    <div>
        <!--navbar-->
        <navbar />
        <!--navbar-->

        <!--sidebar-->
        <sidebar />
        <!--sidebar-->

        <!--content-->
        <keep-alive :max="5">
            <router-view />
        </keep-alive>
        <!--content-->
    </div>
</template>

<script>
import Sidebar from '@/components/sidebar-menu/SideBar';
import Navbar from '@/components/NavBar';

import {
    ACTION_GET_CURRENT_USER
} from '@/store/modules/auth/action-types';
import { createNamespacedHelpers } from 'vuex';

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('auth');

export default {
    name: 'Dashboard',
    components: {
        Sidebar,
        Navbar
    },
    mounted() {
        this[ACTION_GET_CURRENT_USER]()
    },
    methods: {
        ...mapAdminActions([
            ACTION_GET_CURRENT_USER
        ])
    }
}
</script>
