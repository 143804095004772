import Api from '@/helpers/api/index.js'

import {
    ACTIONS_GET_LANDING_REVISIONS,
    ACTION_ACTIVATE_LANDING_REVISION,
    ACTION_DELETE_LANDING_REVISION,

    ACTIONS_GET_BLOG_REVISIONS,
    ACTION_ACTIVATE_BLOG_REVISION,
    ACTION_DELETE_BLOG_REVISION,

    ACTIONS_GET_ESSAY_REVISIONS,
    ACTION_ACTIVATE_ESSAY_REVISION,
    ACTION_DELETE_ESSAY_REVISION
} from './action-types'

import {
    DELETE_REVISION,
    SET_REVISIONS,
    UPDATE_CURRENT_ACTIVE_REVISION,
    SET_LOADING
} from './mutation-types'

import {
    UPDATE_LIST_ITEM,
    UPDATE_LIST_ITEM_REVISIONS
} from '../content-list/mutation-types'

export const actions = {
    // Landing
    async [ACTIONS_GET_LANDING_REVISIONS]({ commit }, payload) {
        commit(SET_LOADING, true)
        const response = await Api.get('/api/content/posts/landing/fetch-post-with-revisions', payload)
        response.data.revisions.forEach((item) => { item.checked = false })
        commit(SET_REVISIONS, { name: 'landing', data: response.data })
        commit(SET_LOADING, false)
        return response.data
    },
    async [ACTION_ACTIVATE_LANDING_REVISION]({ rootState, commit }, { post_id, revision_id }) {
        const response = await Api.put('/api/content/posts/landing/set-revision', {
            post_id,
            revision_id
        })
        commit(UPDATE_CURRENT_ACTIVE_REVISION, { name: 'landing', id: revision_id })
        if (rootState.contentList.landings) {
            commit(`contentList/${UPDATE_LIST_ITEM}`, { name: 'landings', data: response.data.post }, { root: true })
        }
        return response.data
    },
    async [ACTION_DELETE_LANDING_REVISION]({ rootState, commit }, { post_id, revision_id }) {
        await Api.delete('/api/content/posts/landing/delete-revision', {
            id: revision_id
        })
        commit(DELETE_REVISION, { name: 'landing', id: revision_id })
        if (rootState.contentList.landings) {
            commit(`contentList/${UPDATE_LIST_ITEM_REVISIONS}`, { name: 'landings', id: post_id }, { root: true })
        }
    },

    // Blog
    async [ACTIONS_GET_BLOG_REVISIONS]({ commit }, payload) {
        commit(SET_LOADING, true)
        const response = await Api.get('/api/content/posts/blog/fetch-post-with-revisions', payload)
        response.data.revisions.forEach((item) => { item.checked = false })
        commit(SET_REVISIONS, { name: 'blog', data: response.data })
        commit(SET_LOADING, false)
        return response.data
    },
    async [ACTION_ACTIVATE_BLOG_REVISION]({ rootState, commit }, { post_id, revision_id }) {
        const response = await Api.put('/api/content/posts/blog/set-revision', {
            post_id,
            revision_id
        })
        commit(UPDATE_CURRENT_ACTIVE_REVISION, { name: 'blog', id: revision_id })
        if (rootState.contentList.blogs) {
            commit(`contentList/${UPDATE_LIST_ITEM}`, { name: 'blogs', data: response.data.post }, { root: true })
        }
        return response.data
    },

    async [ACTION_DELETE_BLOG_REVISION]({ rootState, commit }, { post_id, revision_id }) {
        await Api.delete('/api/content/posts/blog/delete-revision', {
            id: revision_id
        })
        commit(DELETE_REVISION, { name: 'blog', id: revision_id })
        if (rootState.contentList.blogs) {
            commit(`contentList/${UPDATE_LIST_ITEM_REVISIONS}`, { name: 'blogs', id: post_id }, { root: true })
        }
    },

    // Essay

    async [ACTIONS_GET_ESSAY_REVISIONS]({ commit }, payload) {
        commit(SET_LOADING, true)
        const response = await Api.get('/api/content/posts/essay/fetch-post-with-revisions', payload)
        response.data.revisions.forEach((item) => { item.checked = false })
        commit(SET_REVISIONS, { name: 'essay', data: response.data })
        commit(SET_LOADING, false)
        return response.data
    },
    async [ACTION_ACTIVATE_ESSAY_REVISION]({ rootState, commit }, { post_id, revision_id }) {
        const response = await Api.put('/api/content/posts/essay/set-revision', {
            post_id,
            revision_id
        })
        commit(UPDATE_CURRENT_ACTIVE_REVISION, { name: 'essay', id: revision_id })
        if (rootState.contentList.essays) {
            commit(`contentList/${UPDATE_LIST_ITEM}`, { name: 'essays', data: response.data.post }, { root: true })
        }
        return response.data
    },

    async [ACTION_DELETE_ESSAY_REVISION]({ rootState, commit }, { post_id, revision_id }) {
        await Api.delete('/api/content/posts/essay/delete-revision', {
            id: revision_id
        })
        commit(DELETE_REVISION, { name: 'essay', id: revision_id })
        if (rootState.contentList.essays) {
            commit(`contentList/${UPDATE_LIST_ITEM_REVISIONS}`, { name: 'essays', id: post_id }, { root: true })
        }
    }
}
