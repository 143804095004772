<template>
    <div class="custom-table__row-name">
        <div class="custom-table__row-owner">
            <span
                v-if="row.avatar_url && !loadError"
                :style="{ backgroundImage: `url(${row.avatar_url})` }"
                class="custom-table__row-owner--avatar_image"
            >
                <img
                    v-show="false"
                    alt="avatar"
                    :src="row.avatar_url"
                    @error="loadError = true"
                >
            </span>
            <span
                v-else-if="row.firstname"
                class="custom-table__row-owner--avatar_name"
            >
                {{ row.firstname[0] }}
            </span>
        </div>

        {{ row.lastname }}
        <span
            class="details-info-wrap"
            @click="getUser"
        >
            <Information />
        </span>
        <user-modal
            :name="`user-modal${row.id}`"
            :user="user"
        />
    </div>
</template>

<script>
import Information from 'mdi-vue/Information'
import UserModal from '@/components/modals/UserModal';
import { getUserById } from '@/services/profileService'

export default {
    name: 'RowUserInfo',
    components: {
        Information,
        UserModal
    },
    props: {
        row: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loadError: false,
            user: {}
        }
    },
    methods: {
        getUser() {
            getUserById(this.row.id).then((response) => {
                this.user = response.data.info_user
                this.$bus.$emit('openModal', `user-modal${this.row.id}`)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.custom-table__row-name{
    display: flex;
    align-items: center;
    .details-info-wrap {
        color: #1976D2;
        margin-left: 10px;
        cursor: pointer;
    }
}
.custom-table__row-owner {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 58px;
    width: 58px;
    min-width: 58px;
    margin-right: 10px;
    background: $orange;
    border-radius: 50%;
    &--avatar {
        position: relative;
        width: 58px;
        height: 58px;
        background: $orange;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            border-radius: 50%;
            max-width: 58px;
            height: 58px;
            width: 100%;
        }
        &_name {
            font-size: 20px;
            color: #fff;
        }
        &_image{
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }

}
</style>
