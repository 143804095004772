<template>
    <div>
        <div class="chip-list">
            <div
                v-for="(item, index) in showingItems"
                :key="index"
            >
                {{ item }}
            </div>
            <div
                v-if="list.length > 3"
                class="dots"
                @click="openLogModal"
            >
                ...
            </div>
            <permissios-modal
                :name="`log-modal${row.id}`"
                :title="'Permissios'"
                :permissions="list"
            />
        </div>
    </div>
</template>

<script>
import permissiosModal from '../../../modals/permissiosModal';

export default {
    name: 'RowChips',
    components: { permissiosModal },
    props: {
        row: {
            type: Object,
            required: true
        }
    },
    computed: {
        list() {
            return this.row.user_permissions
        },
        showingItems() {
            return this.list ? this.list.slice(0, 3) : []
        },
        tooltipText() {
            return this.list ? this.list.join('<br/> <br/>') : ''
        }
    },
    methods: {
        openLogModal() {
            this.$bus.$emit('openModal', `log-modal${this.row.id}`, {
            })
        }
    }
}
</script>

<style scoped lang="scss">
    .chip-list {
        min-width: 185px;
        position: relative;
    }
    .chip-list > div:not(.v-modal) {
        border-color: rgba(0,0,0,.12);
        color: rgba(0,0,0,.87);
        background: #d8d8d8;
        border-radius: 16px;
        line-height: 32px;
        padding: 0 12px;
        margin: 5px auto;
        user-select: none;
        &.dots {
            cursor: pointer;
        }
    }
</style>
