<template>
    <div id="app">
        <dashboard v-if="auth" />
        <router-view v-else />
        <snack-bar />
        <confirm-auth-popup />
    </div>
</template>

<script>
import { mapState } from 'vuex';

import authMixin from '@/mixins/global/authMixin.js'

import SnackBar from './components/SnackBar';
import Dashboard from './layouts/Dashboard'
import ConfirmAuthPopup from './components/popups/ConfirmAuthPopup'

export default {
    components: {
        SnackBar,
        Dashboard,
        ConfirmAuthPopup
    },
    mixins: [
        authMixin
    ],
    computed: {
        ...mapState('auth', {
            auth: 'auth'
        })
    }
}
</script>
