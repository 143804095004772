/* eslint-disable class-methods-use-this */
import moment from 'moment'

class Auth {
    constructor() {
        this.auth = {}
    }

    logout() {
        return new Promise((resolve) => {
            window.localStorage.setItem('vuex', JSON.stringify({}))
            resolve(true)
        })
    }

    isLogged() {
        if (!this.getAuth()) {
            this.logout()
            throw new Error('Login session has expired')
        }
        if (!this.getToken() || this.getToken() === null) {
            this.logout()
            throw new Error('No token')
        }
        if (!this.isTokenValid()) {
            this.logout()
            throw new Error('Login session has expired')
        }
        return true
    }

    userAuth() {
        if (!this.getToken() || this.getToken() === null) {
            return false
        }
        if (!this.isTokenValid()) {
            return false
        }
        return true
    }

    getLocalStorage() {
        const persistentState = window.localStorage.getItem('vuex')
        if (!persistentState || persistentState === '{}') return false
        return JSON.parse(persistentState)
    }

    getAuth() {
        const { auth } = this.getLocalStorage()
        return auth
    }

    getToken() {
        if (this.getAuth()) {
            const { admin_data } = this.getAuth()
            if (admin_data) {
                return admin_data.token
            }
            return false
        }
        return false
    }

    getRefreshToken() {
        const { admin_data } = this.getAuth()
        return admin_data.refresh_token
    }

    getTokenExpiry() {
        // returns a date string in UTC
        const { admin_data } = this.getAuth()
        return admin_data.expires_date
    }

    isTokenValid() {
        // !!!!  we go by UTC timezone !!!!!!
        // this means that the backend must return a date in ISO and UTC
        const token = moment.parseZone(this.getTokenExpiry()).valueOf()
        const now = moment().valueOf()
        return now < token
    }

    shouldDisplayPopup() {
        const delayInSeconds = Number(process.env.VUE_APP_AUTH_POPUP_TIME_TO_SHOW) * 60
        const momentToken = moment(this.getTokenExpiry())
        const momentNow = moment().toISOString()
        const countdown = momentToken.diff(momentNow, 'seconds')
        if (countdown <= 0) throw new Error('Token is expired')
        return (countdown <= delayInSeconds)
    }

    getTimeToExpiryInSeconds() {
        const momentToken = moment(this.getTokenExpiry())
        const momentNow = moment().toISOString()
        return momentToken.diff(momentNow, 'seconds')
    }
}
export default new Auth()
