import { updateField } from 'vuex-map-fields';
import { BlogPost } from '@/models/Content/BlogPost';

import { cloneDeep } from 'lodash'

import {
    RESET_CREATED_FORM_DATA,
    RESET_EDIT_FORM_DATA,
    SET_BLOG_POST,
    SET_LOADING,
    RESET_UNFINISHED_URL,
    UPDATE_DEFAULT_FORM_DATA
} from './mutation-types'

export const mutations = {
    updateField,
    [SET_LOADING](state, payload) {
        state.loading = payload
    },
    [UPDATE_DEFAULT_FORM_DATA](state, { name, response }) {
        state[name].default_form_data = response
    },
    [RESET_CREATED_FORM_DATA](state) {
        state.blog.form_data = new BlogPost()
        state.blog.default_form_data = new BlogPost()
    },
    [RESET_EDIT_FORM_DATA](state) {
        state.blog.form_data = cloneDeep(state.blog.default_form_data)
    },
    [SET_BLOG_POST](state, payload) {
        state.blog.form_data = cloneDeep(payload)
        state.blog.default_form_data = cloneDeep(payload)
    },
    [RESET_UNFINISHED_URL](state, { name }) {
        state[name].unfinished_session = false
        state[name].unfinished_url = null
        state[name].unfinished_type = null
    }
}
