import Vue from 'vue'
import VueRouter from 'vue-router'

// import { scroller } from 'vue-scrollto/src/scrollTo'
import { eventBus } from '@/helpers/event-bus/'
import store from '@/store/'

import Auth from '@/helpers/auth/'
import { routes } from './routes-dashboard'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err)
}

function shouldRedirectToAccount(toRoute) {
    const blockedRoutes = ['login', 'register', 'forgot-password']
    const index = blockedRoutes.findIndex((route) => route === toRoute)
    return index !== -1 && Auth.userAuth()
}

const router = new VueRouter({
    base: process.env.BASE_URL,
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (to.name === from.name) return false
        if (to.hash) {
            // return new Promise((resolve) => {
            //     const element = document.querySelector(`${to.hash}`)
            //     resolve(
            //         element.scrollIntoView({ block: 'center', behavior: 'smooth' })
            //     );
            // });
            return setTimeout(() => {
                const element = document.querySelector(`${to.hash}`)
                // { block: 'center', behavior: 'smooth' }
                if (element) {
                    element.scrollIntoView({ block: 'center' })
                }
            }, 1)
        }
        return savedPosition || { x: 0, y: 0 }
    },
    routes
});

router.beforeEach((to, from, next) => {
    // set true for loading property so that we can display loader animation until a route is fully loaded
    router.app.loading = true

    if (to.path === '/') next({ name: 'login' })

    // redirect logged in user to account page, in case an irrelevant route is accessed
    if (shouldRedirectToAccount(to.name)) {
        next({ name: 'posts-list-landing' })
    }
    // all routes processing logic
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        try {
            Auth.isLogged()
            if (!to.meta.permission || store.getters['auth/getterUserPermissions'].includes(to.meta.permission)) {
                next()
                return
            }
            eventBus.$emit('showSnackBar', 'not authorised to view route', 'error')
        } catch (error) {
            window.localStorage.setItem('previous_url', window.location.pathname);
            Auth.logout()
            eventBus.$emit('showSnackBar', error, 'error')
            next({ name: 'login' })
            setTimeout(() => { window.location.reload() }, 500)
        }
    } else {
        next();
    }
})

router.afterEach(() => {
    // remove loading animation, once our route is fully loaded
    router.app.loading = false
});

export default router
