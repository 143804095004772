export const initialState = {
    landing_categories: [],
    blog_categories: [],
    essay_categories: [],
    selectCategories: {
        landing: [],
        blog: [],
        essay: []
    },
    loading: false
}

export const state = { ...initialState }
