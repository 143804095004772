<template>
    <div class="row_duedate">
        {{ getDate }}
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: 'RowDueDate',
    props: ['value'],
    computed: {
        getDate() {
            const date = moment(this.value)
            return date.format('DD-MM-YY');
        }
    }
}
</script>

<style>

</style>
