<template>
    <modal-layout
        v-if="dialog"
        :size="'small'"
        :name="name"
        :hidden="hidden"
        :style="{ 'z-index': order }"
        @closeModal="cancelModal()"
        @hideModal="hideModal(name)"
    >
        <template #header>
            <h2>{{ title }}</h2>
        </template>
        <template #content>
            <form-textarea
                v-model="$v.text.$model"
                :field.sync="$v.text.$model"
                :legend="label"
                :name="title"
                :placeholder="label"
                :errors="validationMessage($v.text)"
                :is-valid="$v.text.$dirty && !$v.text.$anyError"
                @input.native="$v.text.$touch()"
                @blur.native="$v.text.$touch()"
            />
        </template>
        <template #buttons>
            <custom-button
                default
                class="orange-button"
                @on-btn-click="validate"
            >
                Confirm
            </custom-button>
            <custom-button
                default
                @on-btn-click="cancelModal(name)"
            >
                Cancel
            </custom-button>
        </template>
    </modal-layout>
</template>

<script>
import modalsEventBusMixins from '@/mixins/modals/modalsEventBusMixins'

import formValidationMixin from '@/mixins/formValidationMixin.js'
import { validationMixin } from 'vuelidate';
import { validationMessage } from 'vuelidate-messages'
import { validationRules } from '@/validation/TextareaModal/Rules'
import { formMessages } from '@/validation/TextareaModal/Messages'

export default {
    name: 'TextAreaModal',
    mixins: [
        modalsEventBusMixins,
        validationMixin,
        formValidationMixin
    ],
    props: {
        name: String,
        label: String,
        title: String,
        prepend_value: String
    },
    validations: {
        ...validationRules
    },
    data() {
        return {
            text: ''
        }
    },
    watch: {
        dialog(val) {
            if (!val) {
                this.text = ''
                this.$v.$reset()
            }
            if (this.prepend_value) {
                this.text = this.prepend_value
            }
        }
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        submit() {
            this.confirmModal(this.name, { comment: this.text })
            this.text = ''
            this.$v.$reset()
        }
    }
}
</script>

<style scoped>

</style>
