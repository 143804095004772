<template>
    <div class="phone__row">
        <phone-number-select
            v-model="$v.country.$model"
            label="Code"
            tabindex="0"
            :errors="validationMessage($v.country)"
            :is-valid="!$v.country.$anyError && !$v.country.$invalid"
            @change="$v.country.$touch()"
            @blur="$v.country.$touch()"
        />
        <phone-number-input
            v-model="phoneNumberValue"
            :field.sync="phoneNumberValue"
            :errors="validationMessage($v.phone_number) || phone_validation_message"
            :is-valid="$v.phone_number.$dirty && !$v.phone_number.$anyError"
            @input.native="$v.phone_number.$touch()"
            @blur.native="$v.phone_number.$touch()"
            @phoneValidated="$emit('phoneValidated', $event)"
        />
        <div class="phone-verification__btn">
            <button
                :class="{'btn-base_disabled': !isPhoneNumberComplete}"
                class="btn-base btn-base_colored btn-base_normal"
                large
                :loading="phone_validation_loading"
                @click.prevent="verificationStart"
            >
                <template v-if="phone_validation_loading">
                    <button-loader />
                </template>
                <span v-else>Verify</span>
            </button>
        </div>
    </div>
</template>

<script>

import Messages from '@/helpers/InterfaceMessages_en'
import { eventBus } from '@/helpers/event-bus/'

import ButtonLoader from '@/components/common/ButtonLoader.vue';

import Api from '@/helpers/api/index.js'

// validation
import { validationMixin } from 'vuelidate';
import { validationMessage } from 'vuelidate-messages'
import { validationRules } from '@/validation/phone/Rules.js'
import { formMessages } from '@/validation/phone/Messages.js'
import formValidationMixin from '@/mixins/formValidationMixin.js'

import { debounce } from 'lodash'
// VUEX
import { mapState } from 'vuex';
import { mapPhoneFields } from '@/store/modules/phone'
import PhoneNumberSelect from './PhoneNumberSelect';
import PhoneNumberInput from './PhoneNumberInput';

export default {
    name: 'PhoneNumberField',
    components: {
        PhoneNumberInput,
        PhoneNumberSelect,
        ButtonLoader
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    validations: {
        ...validationRules
    },
    data() {
        return {
            phoneNumberValue: '',
            phoneValidationStatus: false,
            phone_validation_loading: false
        }
    },
    computed: {
        ...mapState('phone', {
            started: 'started',
            verified: 'verified'
        }),
        ...mapPhoneFields([
            'form_data.country',
            'form_data.phone_number'
        ]),
        isPhoneNumberComplete() {
            return (this.country.phone_code && this.phone_number !== '')
                    && !(this.$v.country.$error || this.$v.phone_number.$error) && !this.phone_validation_loading
        },
        isFormValid() {
            // return !this.$v.$invalid && this.verified
            return !this.$v.$invalid
        }
    },
    watch: {
        phoneNumberValue: debounce(function (newPhoneNumber) {
            this.phone_number = newPhoneNumber
        }, 300)
    },
    activated() {
        this.phone_validation_loading = false
    },
    mounted() {
        if (this.$route.name === 'profile_phone') {
            this.country = {}
            this.phone_number = ''
            eventBus.$on('saveSettings', () => {
                if (this.$v.$invalid) {
                    this.validate()
                    return
                }
                eventBus.$emit('validate', true)
            })
            return
        }
        this.phoneNumberValue = this.phone_number
    },
    beforeDestroy() {
        eventBus.$off('saveSettings')
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        async verificationStart() {
            this.phone_validation_loading = true
            Api.post('/api/phone/verification-start', {
                phone: this.phone_number,
                phone_code: this.country.phone_code
            }).then(() => {
                this.$store.commit('phone/START')
                this.phone_validation_error = false
                eventBus.$emit('showSnackBar', Messages.modal_sent_phone_verification_pin.body, 'success');
            }).catch((error) => {
                this.phone_validation_error = true
                this.phone_validation_message = error.response.data.message
                setTimeout(() => {
                    this.phone_validation_error = false
                    this.phone_validation_message = ''
                }, 2000)
            }).finally(() => {
                this.phone_validation_loading = false
            })
        }
    }
}
</script>
