<template>
    <div
        v-if="row.properties"
        class="row-properties"
    >
        <span
            @click="openLogModal"
        >
            <eye />
        </span>
    </div>
</template>

<script>
import Eye from 'mdi-vue/Eye.vue'

export default {
    name: 'RowProperties',
    components: {
        Eye
    },
    props: {
        row: Object
    },
    methods: {
        openLogModal() {
            this.$bus.$emit('openModal', 'log-modal', {
                row: this.row
            })
        }
    }
}
</script>

<style scoped lang="scss">
    .row-properties {
        span {
            cursor: pointer;
            svg {
                transition: .3s;
                &:hover {
                    color: #f57c00;
                }
            }
        }
    }
</style>
