export class Permission {
    constructor({
        name = ''
    } = {}) {
        this.name = name
    }
}

export function createPermission(data) {
    return Object.freeze(new Permission(data));
}
