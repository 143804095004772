<template>
    <div
        v-click-outside="clickOutside"
        class="form-select-categories"
    >
        <fieldset :class="openListItem ? 'form-select__fieldset active' : 'form-select__fieldset'">
            <legend
                class="form-select-categories__legend"
                :class="{ 'error': validationError }"
            >
                {{ label }}
            </legend>
            <component
                :is="prependIcon"
                class="form-input-categories__prepend-icon"
            />
            <input
                :class="{ 'error-border': validationError, 'success-border' : setValue, 'grey-border' : openListItem }"
                class="form-select-categories__input"
                :style="{ paddingLeft: prependIcon ? '40px' : '10px' }"
                :value="setValue"
                type="text"
                :name="name"
                autocomplete="off"
                :placeholder="'Select ' + name"
                :disabled="disabled"
                @click.self="openListItem = !openListItem"
                @focus="$event.target.select()"
                @input="onInputChanged($event)"
            >
            <ul
                v-if="openListItem"
                class="form-select-categories__list"
            >
                <li
                    v-for="(item, index) in filteredList"
                    :key="index"
                    class="form-select-categories__list-item"
                >
                    <p class="form-select-categories__list-item__text">
                        {{ item.text }}
                    </p>
                    <ul class="form-select-categories__list-item__subcat">
                        <li
                            v-for="(subItem,id) in item.categories"
                            :key="id"
                            class="form-select-categories__list-item__subcat-item"
                            @click="selectItem(subItem.value)"
                        >
                            {{ subItem.text }}
                        </li>
                    </ul>
                </li>
            </ul>
            <div
                v-if="!disabled"
                class="select-arrow"
                @click="openListItem = !openListItem"
            >
                <arrow />
            </div>
            <div
                v-if="validationError"
                class="form-select-categories__error"
            >
                {{ validationError }}
            </div>
        </fieldset>
    </div>
</template>

<script>
import BookOpenVariant from 'mdi-vue/BookOpenVariant.vue';
import FileDocument from 'mdi-vue/FileDocument.vue';
import ClickOutside from 'vue-click-outside'
import Arrow from '@/icons/Arrow';

export default {
    name: 'FormSelectCategory',
    components: {
        BookOpenVariantIcon: BookOpenVariant,
        FileDocumentIcon: FileDocument,
        Arrow
    },
    directives: {
        ClickOutside
    },
    props: {

        value: {},
        prependIcon: { type: String },
        items: { type: Array },
        label: { type: String },
        errors: { type: String },
        escape: { type: Boolean },
        name: {
            required: true,
            type: String
        },
        disabled: Boolean
    },
    data() {
        return {
            openListItem: false,
            filteredItems: [],
            search: ''
        };
    },
    computed: {
        setValue() {
            const isExist = this.items.some((el) => el.categories.find((item) => item.value === this.search));
            if (isExist) {
                return this.items.find((element) => element.categories.find((item) => item.value === this.search)).categories.find((el) => el.value === this.search).text
            }
            if (this.escape) {
                return ''
            }
            return this.search;
        },
        filteredList() {
            if (this.search === this.value) {
                return this.items.filter((item) => item.categories.length)
            }
            const items = this.items.map((item) => {
                const categories = item.categories.filter((el) => el.text.toLowerCase().includes(this.search.toLowerCase()))
                return {
                    text: item.text,
                    value: item.value,
                    categories
                }
            })
            return items.filter((item) => item.categories.length)
        },
        validationError() {
            return this.errors ? this.errors : '';
        }
    },
    watch: {
        value(value) {
            if (!value) {
                this.search = ''
            } else {
                this.search = value
            }
        }
    },
    created() {
        this.setInitValue();
    },
    methods: {
        selectItem(value) {
            this.$emit('input', value);
            this.search = value
            this.openListItem = false;
        },
        onInputChanged(event) {
            this.openListItem = true;
            this.search = event.target.value;
            if (this.search === '') {
                this.$emit('input', '');
            }
        },
        setInitValue() {
            this.search = this.value;
        },
        clickOutside() {
            this.openListItem = false
        }
    }
};
</script>

<style lang="scss">
    .form-select-categories {
        outline: none;
        // border: 1px solid #27d91f ;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px !important;
        max-height: 48px!important;
        min-height: 48px!important;
        width: 100%;
        position: relative;
        .select-arrow {
            position: absolute;
            right: 10px;
            top: 50%;
            margin-top: -11px;
            cursor: pointer;
            svg {
                transition: transform .2s ease;
                transform: rotate(-90deg);
                width: 8px;
            }
        }

        &.success-border {
            border: 1px solid #27d91f;
        }
        &.grey-border {
            border: 1px solid #D3D9E2;
        }
        &__icon {
            position: absolute;
            top: 10px;
            left: 12px;
        }

        &__fieldset {
            position: relative;
            border: none ;
            outline: none;

        }

        &__legend {
            display: table;
            color: #b3bbc6;
            caret-color: #b3bbc6;
            padding: 2px 5px 2px 5px;
            font-size: 80%;
            position: absolute;
            left: 6px;
            top: -8px;
            background-color: white;
            border: none;
        }

        &__input {
            box-sizing: border-box;
            display: inline-block;
            width: 100%;
            cursor: pointer;
            border: none ;
            outline: none;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            border-radius: 4px ;
            font-family: 'Mark Pro', sans-serif ;
            font-size: 14px;
            max-height: 48px;
            height: 48px;
        }

        &__list {
            overflow-y: scroll;
            max-height: 270px;
            background-color: white;
            position: absolute;
            z-index: 10;
            top: 55px;
            text-align: left;
            width: 100%;
            margin: 0;
            padding: 0;
            -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
            border-radius: 4px;
        }

        &__list-item {
            &__text {
                padding: 15px;
                background-color: #fff !important;
                margin-bottom: 1px !important;
                font-weight: bold !important;
                font-size: 18px;
            }
            &__subcat {
                &-item {
                    padding: 15px 15px 15px 30px;
                    background-color: #fff;
                    margin-bottom: 1px;
                    cursor: pointer;
                    &:hover {
                        background-color: lightgrey;
                        //color: #FFF;
                    }
                }
            }
        }
        &__error {
            display: inline;
            width: auto;
            text-align: left;
            color: #ff5252;
            caret-color: #ff5252;
            font-size: 12px;
            min-height: 14px;
            position: absolute;
            left: 13px;
            bottom: -7px;
            background: white;
            padding: 0 3px;
        }
    }

.form-select-categories__input.success-border {
    border: 1px solid #27d91f;
}
.form-select-categories__list-item__text {
    color: #1f2939;
}
.form-select-categories__input {
    border: 1px solid #D3D9E2;
}
</style>
