export default {
    modal_logout: {
        title: 'Signed out',
        body: 'You`ve successfully signed out of your account. All your personal data has been removed from the browser. If you are on a public computer, it is recommended that you delete browsing history in your browser.'
    },
    modal_register_success: {
        title: 'Successfully Registered!',
        body: 'Your are now logged in to the website. You can place your first order by visiting the order form page. Should you have any questions, our Customer Service agents are always on-line to help!'
    },
    modal_forgot_response: {
        title: 'Done!',
        body: 'Our system will check your e-mail and, if it exists, will send a message with the instructions on how to reset your password.'
    },
    modal_clear_order_form: {
        title: 'Clear Form',
        body: 'Are you sure you want to delete all data from the order form?'
    },
    modal_sent_phone_verification_pin: {
        title: 'Get Code',
        body: 'We have sent an SMS text message to your mobile phone. Please get the 4 digit code and enter it into the verification field to verify your phone number.'
    },
    form_support_ticket_created: {
        title: 'Success',
        body: 'A support ticket has been created. You can view all tickets in your account (Support Tickets).'
    },
    form_order_reset: {
        title: 'Success',
        body: 'The order form is reset'
    }
}
