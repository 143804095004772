const TablePermission = () => import(
    '@/views/Permissions/PermissionsPages'
)

const CreatePermission = () => import(
    '@/views/Permissions/CreatePermissionPage'
)

const TableRoles = () => import(
    '@/views/Permissions/RolePages'
)

const CreateRole = () => import(
    '@/views/Permissions/CreateRolePage'
)

const TableUsers = () => import(
    '@/views/Permissions/UserPages'
)

export default [
    { // list-permissions
        path: '/admin/dashboard/view/permissions',
        component: TablePermission,
        name: 'posts-list-permission',
        meta: {
            requiresAuth: true,
            permission: 'content/manage',
            contentType: 'permission',
            link: {
                routeAdd: 'create-post-permission'
            }
        },
        props: (route) => ({
            contentType: route.meta.contentType
        })
    },
    { // create-post-permission
        path: '/admin/dashboard/create/post/permission',
        component: CreatePermission,
        name: 'create-post-permission',
        props: (route) => ({
            contentType: route.meta.contentType
        }),
        meta: {
            requiresAuth: true,
            permission: 'content/manage',
            mode: 'create',
            contentType: 'permission',
            link: {
                routeAdd: 'create-post-permission'
            }
        }
    },
    { // list-roles
        path: '/admin/dashboard/view/roles',
        component: TableRoles,
        name: 'posts-list-roles',
        meta: {
            requiresAuth: true,
            permission: 'content/manage',
            contentType: 'roles',
            link: {
                routeAdd: 'create-post-role'
            }
        },
        props: (route) => ({
            contentType: route.meta.contentType
        })
    },
    { // create-post-role
        path: '/admin/dashboard/create/post/role',
        component: CreateRole,
        name: 'create-post-role',
        props: (route) => ({
            mode_type: route.meta.mode_type,
            contentType: route.meta.contentType
        }),
        meta: {
            requiresAuth: true,
            permission: 'content/manage',
            mode: 'create',
            contentType: 'role',
            link: {
                routeAdd: 'create-post-role'
            }
        }
    },
    { // list-users
        path: '/admin/dashboard/view/users',
        component: TableUsers,
        name: 'posts-list-users',
        meta: {
            requiresAuth: true,
            permission: 'content/manage',
            contentType: 'users'
        },
        props: (route) => ({
            contentType: route.meta.contentType
        })
    }
]
