export const ACTION_CREATE_POST = 'ACTION_CREATE_POST'
export const ACTION_CREATE_ESSAY_POST = 'ACTION_CREATE_ESSAY_POST'
export const ACTION_GET_POST = 'ACTION_GET_POST'
export const ACTION_GET_ESSAY_POST = 'ACTION_GET_ESSAY_POST'
export const ACTION_UPDATE_POST = 'ACTION_UPDATE_POST'
export const ACTION_UPDATE_ESSAY_POST = 'ACTION_UPDATE_ESSAY_POST'
export const ACTION_VALIDATE_SLUG = 'ACTION_VALIDATE_SLUG'
export const ACTION_VALIDATE_ESSAY_SLUG = 'ACTION_VALIDATE_ESSAY_SLUG'
export const ACTION_VALIDATE_REDIRECT = 'ACTION_VALIDATE_REDIRECT'

export const ACTION_CREATE_REVISION = 'ACTION_CREATE_REVISION'
