<template>
    <div class="comments_popup">
        <div class="comments_popup_header">
            <p class="comments_popup_header_title">
                Comments
            </p>
            <div
                class="comments_popup_header_close"
                @click="hidePopup()"
            >
                <cross />
            </div>
        </div>
        <div class="comments_popup_body">
            <template v-for="comment in comments">
                <div
                    v-if="comment.owner"
                    :key="comment.id"
                    class="comments_popup_item"
                >
                    <div class="comments_popup_item_header">
                        <div class="comments_popup_item_header_avatar">
                            <img
                                v-if="comment.owner && comment.owner.avatar_url"
                                :src="comment.owner.avatar_url"
                                alt=""
                            >
                            <div
                                v-else
                                class="comments_popup_item_header_avatar--later"
                            >
                                {{ getAvatarLetter(comment.owner) }}
                            </div>
                        </div>
                        <div
                            class="comments_popup_item_header_text"
                        >
                            <p
                                class="comments_popup_item_header_text_name"
                            >
                                {{ comment.owner ? `${comment.owner.firstname} ${comment.owner.lastname}` : 'null' }}
                            </p>
                            <p class="comments_popup_item_header_text_date">
                                {{ comment.created_at | moment_from }}
                            </p>
                        </div>
                        <div
                            v-if="showButtons"
                            class="comments_popup_item_header_actions"
                        >
                            <span @click="deleteComment(comment.id)"> <DeleteOutline /> </span>
                            <span @click="openModal(name, comment.comment, comment.id)"> <PencilOutline /> </span>
                        </div>
                    </div>
                    <div class="comments_popup_item_message">
                        <p>
                            {{ comment.comment }}
                        </p>
                    </div>
                </div>
            </template>
        </div>
        <div class="comments_popup_footer">
            <form-textarea
                v-model="commentMessage"
                :field.sync="commentMessage"
                placeholder="Leave a comment"
            />
            <button
                class="comments_popup_footer_btn"
                @click="submitComment()"
            >
                SEND
            </button>
        </div>
        <textarea-modal
            :name="name"
            :prepend_value="commentMessageEdit"
            label="Comment"
            title="Comment"
            @onConfirmClick="(data, payload) => editCommit(contentType, payload)"
        />
    </div>
</template>

<script>
import filtersMixin from '@/mixins/filtersMixin'
import Cross from '@/icons/Cross';
import DeleteOutline from 'mdi-vue/DeleteOutline.vue'
import PencilOutline from 'mdi-vue/PencilOutline.vue'
import TextareaModal from '@/components/modals/TextareaModal';

export default {
    components: {
        Cross,
        DeleteOutline,
        PencilOutline,
        TextareaModal
    },
    mixins: [
        filtersMixin
    ],
    props: {
        comments: {
            type: Array,
            required: true
        },
        showButtons: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            commentMessage: '',
            commentMessageEdit: '',
            name: 'edit-comment',
            editId: null
        }
    },
    computed: {
        getUserId() {
            return this.$store.getters['auth/getterUserId']
        }
    },
    methods: {
        getAvatarLetter(owner) {
            if (owner && owner.firstname && owner.lastname) {
                return owner.firstname[0] + owner.lastname[0]
            }
            return ''
        },
        hidePopup() {
            this.$emit('hideComments')
        },
        submitComment() {
            this.$emit('submitComment', this.commentMessage)
            this.commentMessage = ''
        },
        deleteComment(id) {
            this.$emit('deleteComment', id)
        },
        editCommit(name, payload) {
            this.$emit('editCommit', payload, this.editId)
            this.editId = null
        },
        openModal(modalName, comment, id) {
            this.editId = id
            this.commentMessageEdit = comment
            this.$bus.$emit('openModal', modalName)
        }
    }
}
</script>

<style lang="scss" scoped>
.comments_popup{
    position: fixed;
    z-index: 9999;
    right: 30px;
    bottom: 50px;
    width: 350px;
    background: #fff;
    height: 100%;
    max-height: 70%;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    display: flex;
    flex-direction: column;
    padding: 15px 0;
    &_header{
        padding: 0 15px;
        position: relative;
        width: 100%;
        &_title{
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 10px;
        }
        &_close{
            position: absolute;
            top: 0px;
            right: 15px;
            z-index: 99;
            cursor: pointer;
        }
    }
    &_body{
        height: 100%;
        margin-bottom: auto;
        overflow-y: scroll;
        &::-webkit-scrollbar { width: 0; }
    }
    &_footer{
        padding: 0 15px;
        &_btn{
            font-weight: bold;
            padding: 10px 0;
            text-align: center;
            width: 100%;
            background: #1976D2;
            border: none;
            margin-top: 10px;
            color: #fff;
            border-radius: 4px;
        }
    }
    &_item{
        padding: 15px;
        transition: .3s all;
        &:hover{
            background: rgba(0,0,0, 0.05);
            .comments_popup_item_header_actions{
                opacity: 1;
            }
        }
        &_header{
            display: flex;
            margin-bottom: 10px;
            &_avatar{
                width: 40px;
                height: 40px;
                border-radius: 50%;
                margin-right: 10px;
                img {
                    max-width: 100%;
                    border-radius: 50%;
                }
                &--later{
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    color: #fff;
                    font-weight: bold;
                    font-size: 16px;
                    background: #f44336;
                }
            }
            &_text{
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                &_name{
                    font-weight: bold;
                }
                &_date{
                    color: rgba(0, 0, 0, 0.38);
                }
            }
            &_actions{
                margin-left: auto;
                opacity: 0;
                transition: .3s all;
                &--none {
                    display: none !important;
                }
                span {
                    &:first-child{
                        margin-right: 5px;
                    }
                }
                svg {
                    fill: rgba(0, 0, 0, 0.38);
                    transition: .3s fill;
                    cursor: pointer;
                    &:hover{
                        fill: #000;
                    }
                }
            }
        }
        &_message{
            p {
                white-space: pre-line;
                line-height: 1.3;
            }
        }
    }
}
</style>
