<template>
    <div
        class="checkox-block"
        :class="validationError && 'checkox-block-error'"
        @click="onClick"
    >
        <div
            class="checkox-container"
            :class="{ 'disabled-checkbox': disabled }"
        >
            <input
                :id="check"
                v-model="value"
                type="checkbox"
                class="form-checkbox"
                :checked="value"
                :disabled="disabled"
                @click="onClick"
            >
            <label
                :class="{ checked }"
                :for="check"
            />
        </div>
        <div
            v-if="label"
            class="checkox-block_label"
        >
            {{ label }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'FormCheckbox',
    props: {

        value: {},
        check: {
            type: String
        },
        label: {
            type: String
        },
        errors: {
            type: String
        },
        disabled: Boolean
    },
    data() {
        return {
            checked: this.value
        }
    },
    computed: {
        validationError() {
            return this.errors ? this.errors : '';
        }
    },
    watch: {
        value(val) {
            this.checked = val
        }
    },
    methods: {
        onClick() {
            if (!this.disabled) {
                this.checked = !this.checked
                this.$emit('input', this.checked)
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.checkox-block {
    display: flex;
    align-items: center;
    cursor: pointer;
    &_label {
        color: #1F2939;
        font-weight: normal;
        margin-left: 16px;
        line-height: 20px;
        text-align: left;
    }
}
.checkox-container {
    &.disabled-checkbox label{
        background: #f8f8f8 !important;
    }
    height: 18px;
    width: 18px;
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    // .checkbox-icon {
    //     span {
    //         display: inline-block;
    //         width: 100%;
    //         height: 100%;
    //     }
    // }
    // svg {
    //     color: rgba(0, 0, 0, 0.6);
    // }
    input {
        display: none;
        &:checked + label {
            background: $orange;
            border: 1px solid $orange;
        }
        &:checked + label::before {
            opacity: 1;
        }
    }

    label {
        display: inline-block;
        position: relative;
        min-width: 18px;
        height: 18px;
        background: #FFFFFF;
        border: 2px solid #4F4F4F;
        box-sizing: border-box;
        border-radius: 4px;
        transition: 0.5s;
        cursor: pointer;

        &::before {
            content: '';
            position: absolute;
            background: url('~@/assets/img/svg/check.svg') no-repeat;
            width: 12px;
            height: 10px;
            left: 50%;
            margin-left: -6px;
            top: 50%;
            margin-top: -5px;
            opacity: 0;
            transition: 0.5s;
        }

    }
}
// .form-checkbox {
//     position: absolute;
//     cursor: pointer;
//     opacity: 0;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     user-select: none;
//     &:checked {
//     }
// }
</style>
