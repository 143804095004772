import {
    numeric,
    requiredIf
} from 'vuelidate/lib/validators';

export const validationRules = {
    form_data: {
        pin: {
            required: requiredIf(function () {
                return this.started
            }),
            numeric
        }
    }
}
