export const initialState = {
    permissions: {},
    roles: {},
    users: {},
    loading: false,
    fullPermissions: [],
    fullRoles: []
}

export const state = { ...initialState }
