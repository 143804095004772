<template>
    <div
        class="custom-table__row-reviews"
    >
        <p
            v-if="row.comments.length"
            @click="openCommentsPopup"
        >
            <span>
                {{ row.comments.length }}
            </span>
        </p>
        <div
            v-if="show === row.id"
            v-click-outside="hidePopup"
            class="comment-popup"
        >
            <h2 class="comment-popup__header">
                Comments
                <div @click="hidePopup">
                    <cross />
                </div>
            </h2>
            <div
                v-for="(comment, id) in row.comments"
                :key="id"
                class="comment-popup__comment"
            >
                <div
                    v-if="comment.owner"
                    class="comment-popup__comment-user"
                >
                    <img
                        :src="comment.owner.avatar_url"
                        class="comment-popup__comment-user_avatar"
                    >
                    <div class="comment-popup__comment-user_info">
                        <p class="comment-popup__comment-user_info-name">
                            {{ comment.owner.firstname }} {{ comment.owner.lastname }}
                        </p>
                        <p class="comment-popup__comment-user_info-time">
                            {{ comment.created_at | moment_from }}
                        </p>
                    </div>
                </div>
                <div class="comment-popup__comment-text">
                    <p>{{ comment.comment }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import filtersMixin from '@/mixins/filtersMixin'
import Cross from '@/icons/Cross';

export default {
    name: 'RowFileComments',
    components: {
        Cross
    },
    mixins: [
        filtersMixin
    ],
    props: {
        row: Object
    },
    data() {
        return {
            show: null
        }
    },
    methods: {
        openCommentsPopup() {
            this.show = this.row.id
        },
        hidePopup() {
            this.show = null
        }
    }
}
</script>

<style lang="scss">
.custom-table__row-reviews {
    position: relative;
}
.comment-popup {
    position: absolute;
    width: 272px;
    top: 64px;
    left: -222px;
    padding: 22px 20px;
    display: block;
    z-index: 4;
    background: #fff;
    max-height: 455px;
    overflow-y: hidden;
    box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    &__header {
        font-size: 16px;
        line-height: 20px;
        color: #1F2939;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__comment {
        margin-top: 22px;
        &-user {
            display: flex;
            align-items: center;
            &_avatar {
                width: 36px;
                height: 36px;
                margin: 0px 8px 0 0;
                border-radius: 50%;
            }
            &_info {
                font-style: normal;
                font-size: 14px;
                line-height: 18px;

                &-name {
                    color: #1F2939;
                    font-weight: 500;
                }
                &-time {
                    color: #818181;
                }
            }
        }
        &-text {
            margin-top: 5px;
            font-size: 14px;
            line-height: 150%;
            color: #1F2939;
        }
    }
}
</style>
