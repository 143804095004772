<template>
    <div class="row_post-count">
        <div class="row_post-count__block">
            {{ post_count }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'RowPostCount',
    props: {
        row: Object
    },
    computed: {
        post_count() {
            const val = this.row.posts_count || this.row.count_post
            return val || 0
        }
    }
}
</script>

<style lang="scss">
.row_post-count {
    display: flex;
    align-items: center;
    justify-content: center;
    &__block {
        background-color: $round-count;
        color: $orange;
        border-radius: 50%;
        height: 30px;
        min-width: 30px;
        width: 30px;
        text-align: center;
        font-family: Mark Pro;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;
    }
}
</style>
