import Api from '@/helpers/api/index.js'

import {
    ACTION_GET_ADDITIONS_FILES,
    ACTION_ADDITIONS_TRASH,
    ACTION_CHANGE_ADDITIONS_PLACEMENT,
    ACTION_CREATE_ADDITIONS_COMMENT,
    ACTION_DELETE_COMMENT,
    ACTION_EDIT_COMMENT,
    ACTION_CREATE_ADDITIONS_PAGE_ESSAY
} from './action-types'

import {
    SET_FILES,
    SET_LOADING,
    UPDATE_LIST_ITEM,
    SET_COMMENT,
    DELETE_COMMENT,
    EDIT_COMMENT,
    SET_FILE_ID
} from './mutation-types'

export const actions = {
    async [ACTION_GET_ADDITIONS_FILES]({ commit }, payload) {
        commit(SET_LOADING, true)
        try {
            const response = await Api.get('/api/file/additional/list', payload)
            response.data.data.forEach((item) => { item.checked = false })
            commit(SET_FILES, response.data)
        } catch (e) {
            console.log(e)
        } finally {
            commit(SET_LOADING, false)
        }
    },
    async [ACTION_ADDITIONS_TRASH]({ commit, dispatch, rootState }, { files, query }) {
        const response = await Api.post('/api/file/additional/trash', { checked_items: [...files] })
        if (!rootState.files.files) {
            await dispatch(`filesAdditional'/${ACTION_GET_ADDITIONS_FILES}`, { ...query })
        }
        if (!response.data.length) {
            commit(`filesAdditional/${UPDATE_LIST_ITEM}`, {
                data: response.data
            }, { root: true })
        } else {
            response.data.forEach((item) => {
                commit(`filesAdditional/${UPDATE_LIST_ITEM}`, {
                    data: item
                }, { root: true })
            })
        }
        return response
    },
    async [ACTION_CHANGE_ADDITIONS_PLACEMENT]({ commit, dispatch, rootState }, { checked_items, url, query }) {
        const response = await Api.post('/api/file/additional/placement', {
            checked_items,
            url
        })
        if (!rootState.files.files) {
            await dispatch(`filesAdditional/${ACTION_GET_ADDITIONS_FILES}`, { ...query })
        }
        if (!response.data.length) {
            commit(`filesAdditional/${UPDATE_LIST_ITEM}`, {
                data: response.data
            }, { root: true })
        } else {
            response.data.forEach((item) => {
                commit(`filesAdditional/${UPDATE_LIST_ITEM}`, {
                    data: item
                }, { root: true })
            })
        }
        return response
    },
    async [ACTION_CREATE_ADDITIONS_COMMENT]({ commit, rootState }, payload) {
        const response = await Api.post('/api/file/additional/comment', { ...payload })
        if (response) {
            commit(SET_COMMENT, { ...payload, user: { ...rootState.auth.user } })
        }
        return response
    },

    async [ACTION_DELETE_COMMENT]({ commit }, payload) {
        const response = await Api.delete('/api/content/tasks/comments/delete', { ...payload })
        if (response) {
            commit(DELETE_COMMENT, payload)
        }
        return response
    },

    async [ACTION_EDIT_COMMENT]({ commit }, payload) {
        const response = await Api.put('/api/content/tasks/comments/update', { ...payload })
        if (response) {
            commit(EDIT_COMMENT, payload)
        }
        return response
    },

    async [ACTION_CREATE_ADDITIONS_PAGE_ESSAY]({ commit }, payload) {
        const response = await Api.post('/api/file/additional/create-page-essay', { ...payload })
        if (response) {
            commit(SET_FILE_ID, payload)
        }
        return response
    }
}
