<template>
    <div class="tasks_url">
        <router-link
            :to="{
                name:'edit-post-task',
                params:{
                    id: row.id
                }
            }"
        >
            {{ value }}
        </router-link>
        <a
            v-if="row.post_links"
            :href="row.post_links"
            target="_blank"
        >
            <OutreachLinks />
        </a>
    </div>
</template>

<script>
import OutreachLinks from '@/icons/OutreachLinks'

export default {
    name: 'RowTaskUrl',
    components: {
        OutreachLinks
    },
    props: ['value', 'row']
}
</script>

<style scoped lang="scss">
    .tasks_url{
        display: flex;
        align-items: center;
        a{
            width: max-content !important;
            color: #1976d2;
            font-weight: 500;
            line-height: 1.5rem;
            width: inherit;
            margin-bottom: 4px;
            word-wrap: break-word;
            cursor: pointer;
            &:visited{
                color: #1976d2;
            }
            &:first-child{
                margin-right: 10px;
            }
            &:last-child{
                margin-top: 5px;
            }
        }
    }
</style>
