import ModalLayout from '@/layouts/ModalLayout'

export default {
    props: {
        contentType: String,
        preventHide: {
            type: Boolean,
            default: false
        }
    },
    components: {
        ModalLayout
    },
    data() {
        return {
            dialog: false,
            MODAL_NAME: '',
            order: null, // current z-index for opened modal
            order_counter: 0, // counter for check how many modals were opened
            hidden: false,
            data: null
        }
    },
    mounted() {
        this.busHandler()
    },
    activated() {
        this.busHandler()
    },
    watch: {
        name() {
            this.MODAL_NAME = this.name
        }
    },
    // destroyed() {
    //     this.$bus.$off('openModal')
    //     this.$bus.$off('unfoldingModal')
    // },
    methods: {
        busHandler() {
            this.MODAL_NAME = this.name
            this.$bus.$on('openModal', (modalName, data) => {
                if (!this.dialog) {
                    this.order_counter += 100
                    if (this.checker(modalName)) {
                        this.dialog = true
                        this.hidden = false
                        this.order = this.order_counter
                        this.data = data
                    }
                }
            })
            this.$bus.$on('unfoldingModal', (modal) => {
                this.order_counter += 100
                if (this.checker(modal.name)) {
                    this.hidden = false
                    this.dialog = true
                    this.order = this.order_counter
                }
            })
            this.$bus.$on('closeAllModals', () => {
                this.cancelModal(this.MODAL_NAME)
            })
            this.$bus.$on('closeModal', (modalName) => {
                if (this.MODAL_NAME !== modalName) { return }
                this.cancelModal(this.MODAL_NAME)
            })
        },
        checker(modalName) {
            return this.MODAL_NAME === modalName
        },
        confirmModal(modalName, payload = {}) {
            console.log(modalName, payload)
            this.$bus.$emit('confirm', modalName, payload)
            this.$emit('onConfirmClick', this.data, payload)
            if (!this.preventHide) {
                this.dialog = false
                this.unsetOrdering()
            }
        },
        cancelModal() {
            this.dialog = false
            this.unsetOrdering()
            this.$emit('onCancelClick')
        },
        unsetOrdering() {
            this.order = null
            this.order_counter = 0
        },
        hideModal(modalName) {
            this.hidden = true
            this.dialog = false
            this.unsetOrdering()
            this.$bus.$emit('hideModal', modalName)
        }
    }
}
