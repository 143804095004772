<template>
    <div
        v-show="!hidden"
        class="modal-wrap"
        @mousedown.self="$emit('closeModal')"
    >
        <div
            :class="size"
            :name="name"
            :style="{ width, height }"
            class="v-modal"
            @click.stop
        >
            <div class="v-modal__header">
                <slot name="header" />
                <div
                    v-if="hideMode"
                    class="v-modal__header-hide"
                    @click="$emit('hideModal')"
                >
                    <window-minimize />
                </div>
                <div
                    class="v-modal__header-cross"
                    @click="$emit('closeModal')"
                >
                    <cross />
                </div>
            </div>
            <div class="v-modal__content">
                <slot name="content" />
            </div>
            <div class="v-modal__buttons">
                <slot name="buttons" />
            </div>
        </div>
    </div>
</template>

<script>
import Cross from '@/icons/Cross'
import WindowMinimize from 'mdi-vue/WindowMinimize'

export default {
    name: 'ModalLayout',
    components: {
        Cross,
        WindowMinimize
    },
    props: {
        hideMode: Boolean,
        dialog: Boolean,
        name: String,
        width: String,
        height: String,
        hidden: Boolean,
        size: String
    }
}
</script>

<style lang="scss">
    .modal-wrap {
        top: 0;
        left: 0;
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: rgba(9, 30, 66, 0.54);
    }
</style>
