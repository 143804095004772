import { updateField } from 'vuex-map-fields';

import {
    SET_LOADING
} from './mutation-types'

export const mutations = {
    updateField,
    [SET_LOADING](state, payload) {
        state.loading = payload
    }
}
