import { postNames } from './formFieldNames/index'

export default {
    // posts
    ...postNames,
    // step 1
    linked_to_order: 'Reference Order ID',
    type: 'Project Type',
    date: 'Due Date',
    time: 'Due Time',
    pages: 'Pages',
    slides: 'Slides',
    charts: 'Charts',
    problems: 'Problems',
    datetime: 'Due Date',
    academiclevel: 'Academic Level',
    discount_code: 'Discount Code',
    // step 2
    subject: 'Subject',
    title: 'Title',
    details: 'Details',
    sources: 'Number of Sources',
    style: 'Citation Style',
    preferred_writers: 'Preferred Writers',
    blocked_writers: 'Blocked Writers',
    // step 3

    // checkout

    // step1
    firstname: 'Firstname',
    lastname: 'Lastname',
    email: 'Email',
    password: 'Password',
    password2: 'Confirm Password',

    // step 2
    address: 'Address',
    city: 'City',
    zip: 'Zip Code',
    country: 'Country',
    region: 'Region / State',
    phone: 'Phone Number',
    phone_code: 'Phone Code',
    verified: 'Phone Verification',
    pin: 'PIN number',

    // step 3
    cc_name: 'Cardholder Name',
    cc_number: 'Card Number',
    cc_expiry: 'Card Expiry Date',
    cc_cvv: 'CVV Code',
    cc_address: 'Billing Address',
    cc_zip: 'Billing Zip Code',
    chk_agreement: 'Accept Terms of Service',

    // support
    message: 'Message',

    // feedback form
    delivery: 'Delivery',
    delivery_details: 'Delivery Problem',
    content: 'Content',
    content_details: 'Content Problem',
    communication: 'Communication',
    communication_details: 'Communication Problem',
    website: 'Website',
    website_details: 'Website Problem',
    support: 'Support',
    support_details: 'Support Problem',

    // forgot password
    email_forgot: 'Email'
}
