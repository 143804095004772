import { render, staticRenderFns } from "./RowBold.vue?vue&type=template&id=18f82e2c&scoped=true&"
import script from "./RowBold.vue?vue&type=script&lang=js&"
export * from "./RowBold.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18f82e2c",
  null
  
)

export default component.exports