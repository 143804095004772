import NotFound from '@/views/NotFound'
import routesAuth from './routes/auth.js'
import routesContent from './routes/content/index.js'
import routesPermissions from './routes/permissions.js'
import routesSettings from './routes/settings.js'
import routesSeo from './routes/seo.js'
import routesResources from './routes/resources'
import routesResourcesAdditions from './routes/resource-additions'

export const routes = [
    ...routesAuth,
    ...routesContent,
    ...routesPermissions,
    ...routesSettings,
    ...routesSeo,
    ...routesResources,
    ...routesResourcesAdditions,
    {
        path: '*',
        name: 'Not Found',
        component: NotFound
    }
];
