<template>
    <div>
        {{ row.filesize | getFileSize }}
    </div>
</template>

<script>
export default {
    name: 'RowFileSize',
    filters: {
        getFileSize(val) {
            return `${(val / 1024).toFixed(2)}KB`
        }
    },
    props: {
        row: Object
    }
}
</script>

<style scoped>

</style>
