import { BasePost } from './BasePost';

export class EssayPost extends BasePost {
    constructor(payload = {}) {
        super(payload)
        this.category = payload.category || ''
        this.categoryNew = payload.categoryNew || ''
        this.title = payload.title || ''
        this.details = payload.details || ''
        this.redirectToPage = payload.redirectToPage || ''
        this.redirectToPageList = payload.redirectToPageList || []
        this.post_excerpt = payload.post_excerpt || ''
        this.post_content = payload.post_content || ''
        this.post_title = payload.post_title || ''
        this.category_id = payload.category_id || null
    }
}

export function createEssay(data) {
    return Object.freeze(new EssayPost(data));
}
