/* eslint-disable class-methods-use-this */
import axios from 'axios'
import { loadProgressBar } from 'axios-progress-bar'
import Auth from '../auth/index.js'

export class Http {
    // TO DO - remove the hardcoded baseUrl and make it through .env
    create() {
        console.log(process.env)
        const headers = {
            baseURL: process.env.VUE_APP_API_URL,
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            },
            timeout: 0
        }

        if (Auth.getToken()) {
            headers.Authorization = `Bearer ${Auth.getToken()}`
        }

        const instance = axios.create(headers)
        loadProgressBar({}, instance);

        // request interceptor
        instance.interceptors.request.use(async (config) => {
            const token = await Auth.getToken()
            // eslint-disable-next-line no-param-reassign
            if (token) {
                config.headers.Authorization = `Bearer ${token}`
            }
            return config
        }, (error) => Promise.reject(error))

        return instance
    }
}

export default new Http()
