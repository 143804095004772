import { updateField } from 'vuex-map-fields';
import { Task } from '@/models/Task'

import { cloneDeep } from 'lodash'

import {
    RESET_CREATED_FORM_DATA,
    RESET_EDIT_FORM_DATA,
    SET_TASK_POST,
    SET_LOADING,
    RESET_UNFINISHED_URL,
    SET_PRIORITIES_LIST,
    SET_STATUSES_LIST,
    SET_COMMENT_SINGLE,
    SET_DELETE_SINGLE,
    SET_EDIT_SINGLE
} from './mutation-types'

export const mutations = {
    updateField,
    [SET_LOADING](state, payload) {
        state.loading = payload
    },
    [RESET_CREATED_FORM_DATA](state) {
        state.task.form_data = new Task()
        state.task.default_form_data = new Task()
    },
    [RESET_EDIT_FORM_DATA](state) {
        state.task.form_data = cloneDeep(state.task.default_form_data)
    },
    [SET_TASK_POST](state, payload) {
        state.task.form_data = cloneDeep(payload)
        state.task.default_form_data = cloneDeep(payload)
    },
    [RESET_UNFINISHED_URL](state, { name }) {
        state[name].unfinished_session = false
        state[name].unfinished_url = null
        state[name].unfinished_type = null
    },
    [SET_STATUSES_LIST](state, payload) {
        state.statuses = payload
    },

    [SET_PRIORITIES_LIST](state, payload) {
        state.priorities = payload
    },

    [SET_COMMENT_SINGLE](state, payload) {
        const owner = {
            avatar_url: payload.user?.avatar_url,
            firstname: payload.user.firstname,
            lastname: payload.user.lastname
        }
        state.task.form_data.comments.push({ ...payload, owner })
    },

    [SET_DELETE_SINGLE](state, payload) {
        state.task.form_data.comments = state.task.form_data.comments.filter((item) => item.id !== payload.data.id)
    },

    [SET_EDIT_SINGLE](state, payload) {
        const comment = state.task.form_data.comments.find((item) => item.id === payload.data.id)
        comment.comment = payload.data.comment
    }
}
