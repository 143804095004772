<template>
    <div class="phone__row no-wrap">
        <form-input
            v-model="$v.form_data.pin.$model"
            legend="PIN"
            class="form-input__pin"
            type="text"
            name="pin"
            :field.sync="pin"
            :errors="validationMessage($v.form_data.pin) || errorText"
            :is-valid="$v.form_data.pin.$dirty && !$v.form_data.pin.$anyError"
            @input.native="inputHandler"
            @blur.native="inputHandler"
        />

        <button
            class="phone__row-submit-btn btn-base btn-base_colored btn-base_normal"
            type="button"
            @click="prevalidate"
        >
            <template v-if="phone_validation_loading">
                <button-loader />
            </template>
            <span v-else>Submit</span>
        </button>
        <button
            class="phone__row-reset-btn btn btn-base btn-base_normal"
            type="button"
            @click="reset"
        >
            Reset
        </button>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { eventBus } from '@/helpers/event-bus/'

import Api from '@/helpers/api/index.js'

import ButtonLoader from '@/components/common/ButtonLoader.vue';

// validation
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import formValidationMixin from '@/mixins/formValidationMixin.js'
import { validationRules } from '@/validation/phone/pin/RulesPin.js'
import { formMessages } from '@/validation/phone/pin/MessagesPin.js'

import { mapPhoneFields } from '@/store/modules/phone'

export default {
    name: 'PhoneNumberPin',
    components: {
        ButtonLoader
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    validations: {
        ...validationRules
    },
    data() {
        return {
            phone_validation_loading: false,
            errorText: ''
        }
    },
    computed: {
        ...mapState('phone', {
            started: 'started',
            verified: 'verified',
            form_data: 'form_data'
        }),
        ...mapPhoneFields([
            'form_data.pin',
            'form_data.country',
            'form_data.phone_number'
        ])
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        prevalidate() {
            this.validate()
        },
        inputHandler() {
            this.errorText = ''
            this.$v.form_data.pin.$touch()
        },
        submit() {
            this.phone_validation_loading = true
            Api
                .post('/api/phone/verification-check', {
                    phone: this.phone_number,
                    phone_code: this.country.phone_code.toString(),
                    pin: this.pin
                })
                .then(() => {
                    this.$store.commit('phone/SUCCESS')
                    eventBus.$emit('showSnackBar', 'Phone number has been validated successfully!', 'success')
                    this.$emit('validated')
                })
                .catch(async () => {
                    this.errorText = 'pin is invalid'
                    this.$v.form_data.pin.$touch()
                })
                .finally(() => {
                    this.phone_validation_loading = false
                })
        },
        reset() {
            this.pin = ''
            this.$store.commit('phone/RESET')
        }
    }
}
</script>
<style scoped lang="scss">
    .phone__row.no-wrap {
        flex-wrap: nowrap !important;
    }
</style>
