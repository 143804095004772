import Api from '@/helpers/api/index.js'
import { Task } from '@/models/Task'

import {
    ACTION_GET_TASKS_LIST,
    ACTION_GET_TASKS_STATUSES,
    ACTION_GET_TASKS_PRIORITIES,
    ACTION_DELETE_TASK,
    ACTION_CHANGE_STATUS_TASK,
    ACTION_CHANGE_CATEGORY_TASK,
    ACTION_CHANGE_PRIORITY_TASK,
    ACTION_GET_TASK,
    ACTION_CLEAR_FORM_DATA,
    ACTION_UPDATE_TASK,
    ACTION_CREATE_TASK,
    ACTION_CREATE_COMMENT,
    ACTION_DELETE_COMMENT,
    ACTION_TAKE_TASK,
    ACTION_EDIT_COMMENT,
    ACTION_FINALIZE_TASK,
    ACTION_COMPLETE_TASK
} from './action-types'

import {
    SET_LOADING,
    SET_TASKS_LIST,
    SET_STATUSES_LIST,
    SET_PRIORITIES_LIST,
    SET_TASK,
    CLEAR_FORM_DATA,
    DELETE_TASKS,
    UPDATE_TASKS_ITEMS,
    UPDATE_FORM_DATA,
    UPDATE_TASK,
    SET_COMMENT,
    EDIT_COMMENT,
    DELETE_COMMENT,
    TAKE_TASK,
    FINALIZE_TASK
} from './mutation-types'

export const actions = {
    // Tasks
    async [ACTION_GET_TASKS_LIST]({ commit }, payload) {
        commit(SET_LOADING, true)
        try {
            const response = await Api.get('/api/content/tasks/list', payload)
            response.data.data.forEach((item) => {
                item.checked = false
                item.is_updated = false
            })
            commit(SET_TASKS_LIST, response.data)
        } catch (e) {
            throw new Error(e)
        }
        commit(SET_LOADING, false)
    },
    async [ACTION_GET_TASKS_STATUSES]({ commit }, payload) {
        try {
            const response = await Api.get('/api/content/tasks/list-statuses', payload)
            commit(SET_STATUSES_LIST, response.data)
        } catch (e) {
            throw new Error(e)
        }
    },
    async [ACTION_GET_TASKS_PRIORITIES]({ commit }, payload) {
        try {
            const response = await Api.get('/api/content/tasks/list-priorities', payload)
            commit(SET_PRIORITIES_LIST, response.data)
        } catch (e) {
            throw new Error(e)
        }
    },
    async [ACTION_DELETE_TASK]({ commit }, { tasks }) {
        try {
            await Api.delete('/api/content/tasks/delete-many', { tasks })
            commit(DELETE_TASKS, { tasks })
        } catch (e) {
            throw new Error(e)
        }
    },

    async [ACTION_CHANGE_STATUS_TASK]({ commit }, payload) {
        try {
            await Api.put('/api/content/tasks/set-status', payload)
            commit(UPDATE_TASKS_ITEMS, { name: 'status', ...payload })
        } catch (e) {
            throw new Error(e)
        }
    },
    async [ACTION_CHANGE_CATEGORY_TASK]({ commit }, payload) {
        try {
            await Api.put('/api/content/tasks/set-post-type', payload)
            commit(UPDATE_TASKS_ITEMS, { name: 'post_type', ...payload })
        } catch (e) {
            throw new Error(e)
        }
    },
    async [ACTION_CHANGE_PRIORITY_TASK]({ commit }, payload) {
        try {
            await Api.put('/api/content/tasks/set-priority', payload)
            commit(UPDATE_TASKS_ITEMS, { name: 'priority', ...payload })
        } catch (e) {
            throw new Error(e)
        }
    },

    async [ACTION_GET_TASK]({ commit }, payload) {
        const getDate = (dateStr) => {
            const arr = dateStr.substring(0, 10).split('-')
            console.log(arr)
            return new Date(...arr).toISOString()
        }
        try {
            const response = await Api.get('/api/content/tasks/fetch-task', { id: payload })
            commit(SET_TASK, { ...response.data[0], deadline_at: getDate(response.data[0].deadline_at) })
        } catch (e) {
            throw new Error(e)
        }
    },

    [ACTION_CLEAR_FORM_DATA]({ commit }) {
        try {
            commit(CLEAR_FORM_DATA)
        } catch (e) {
            throw new Error(e)
        }
    },

    async [ACTION_CREATE_TASK]({ commit, state }, payload) {
        const response = await Api.post('/api/content/tasks/create', { ...payload, post_type: state.types[payload.post_type] })
        if (response.data) {
            commit(CLEAR_FORM_DATA)
        }
        return response
    },

    async [ACTION_UPDATE_TASK]({ commit, state }, payload) {
        const update_form = new Task(payload.form)
        let { post_type } = payload.form
        if (post_type === '0' || post_type === '1' || post_type === '2') {
            post_type = state.types[post_type]
        }
        const response = await Api.put('/api/content/tasks/update', { ...update_form, id: payload.id, post_type })
        if (response) {
            commit(UPDATE_TASK, { ...payload.form, id: payload.id })
            commit(UPDATE_FORM_DATA, payload.form)
        }
        return response
    },

    async [ACTION_CREATE_COMMENT]({ commit, rootState }, payload) {
        const response = await Api.post('/api/content/tasks/comments/create', { ...payload, user: { ...rootState.auth.user } })
        if (response) {
            commit(SET_COMMENT, { ...response.data, user: { ...rootState.auth.user } })
            if (payload.single) {
                commit('contentTaskEdit/SET_COMMENT_SINGLE', { ...response.data, user: { ...rootState.auth.user } }, { root: true })
            }
        }
        return response
    },

    async [ACTION_DELETE_COMMENT]({ commit }, payload) {
        const response = await Api.delete('/api/content/tasks/comments/delete', { ...payload.data })
        if (response) {
            commit(DELETE_COMMENT, payload)
            if (payload.single) {
                commit('contentTaskEdit/SET_DELETE_SINGLE', payload, { root: true })
            }
        }
        return response
    },

    async [ACTION_EDIT_COMMENT]({ commit }, payload) {
        const response = await Api.put('/api/content/tasks/comments/update', { ...payload.data })
        if (response) {
            commit(EDIT_COMMENT, payload)
            if (payload.single) {
                commit('contentTaskEdit/SET_EDIT_SINGLE', payload, { root: true })
            }
        }
        return response
    },

    async [ACTION_TAKE_TASK]({ commit }, payload) {
        try {
            await Api.put('/api/content/tasks/writer-take-task', payload)
            commit(TAKE_TASK, payload)
        } catch (error) {
            console.log(error);
            throw error
        }
    },

    async [ACTION_FINALIZE_TASK]({ commit }, payload) {
        try {
            await Api.put('/api/content/tasks/writer-complete-task', payload)
            commit(FINALIZE_TASK, payload)
        } catch (error) {
            console.log(error);
            throw error
        }
    },

    async [ACTION_COMPLETE_TASK]({ commit }, payload) {
        try {
            await Api.put('/api/content/tasks/set-status', { ...payload, status: 6 })
            commit(UPDATE_TASKS_ITEMS, { name: 'status', ...payload, status: 6 })
        } catch (e) {
            throw new Error(e)
        }
    }
}
