<template>
    <tooltip-component :text="value">
        <div
            class="custom-table__row-bold"
            v-html="value"
        />
    </tooltip-component>
</template>

<script>
import TooltipComponent from '@/components/TooltipComponent'

export default {
    name: 'RowBold',
    components: { TooltipComponent },
    props: {
        value: {
            type: String
        }
    }
}
</script>

<style scoped>

</style>
