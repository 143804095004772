export const ACTION_GET_LANDING_LIST = 'ACTION_GET_LANDING_LIST'
export const ACTION_LANDING_LOCK = 'ACTION_LANDING_LOCK'
export const ACTION_LANDING_UNLOCK = 'ACTION_LANDING_UNLOCK'
export const ACTION_LANDING_PUBLISH = 'ACTION_LANDING_PUBLISH'
export const ACTION_LANDING_UNPUBLISH = 'ACTION_LANDING_UNPUBLISH'
export const ACTION_LANDING_CHANGE_CATEGORY = 'ACTION_LANDING_CHANGE_CATEGORY'
export const ACTION_LANDING_DELETE_POST = 'ACTION_LANDING_DELETE_POST'
export const ACTION_GET_BLOG_LIST = 'ACTION_GET_BLOG_LIST'
export const ACTION_BLOG_LOCK = 'ACTION_BLOG_LOCK'
export const ACTION_BLOG_UNLOCK = 'ACTION_BLOG_UNLOCK'
export const ACTION_BLOG_PUBLISH = 'ACTION_BLOG_PUBLISH'
export const ACTION_BLOG_UNPUBLISH = 'ACTION_BLOG_UNPUBLISH'
export const ACTION_BLOG_CHANGE_CATEGORY = 'ACTION_BLOG_CHANGE_CATEGORY'
export const ACTION_BLOG_DELETE_POST = 'ACTION_BLOG_DELETE_POST'
export const ACTION_GET_ESSAY_LIST = 'ACTION_GET_ESSAY_LIST'
export const ACTION_ESSAY_LOCK = 'ACTION_ESSAY_LOCK'
export const ACTION_ESSAY_UNLOCK = 'ACTION_ESSAY_UNLOCK'
export const ACTION_ESSAY_PUBLISH = 'ACTION_ESSAY_PUBLISH'
export const ACTION_ESSAY_UNPUBLISH = 'ACTION_ESSAY_UNPUBLISH'
export const ACTION_ESSAY_CHANGE_CATEGORY = 'ACTION_ESSAY_CHANGE_CATEGORY'
export const ACTION_ESSAY_DELETE_POST = 'ACTION_ESSAY_DELETE_POST'
