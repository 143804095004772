import Api from '@/helpers/api/index.js'

import {
    ACTION_CREATE_POST,
    ACTION_GET_POST,
    ACTION_UPDATE_POST,
    ACTION_VALIDATE_SLUG,
    ACTION_CREATE_REVISION,
    ACTION_VALIDATE_REDIRECT
} from '../action-types'

import {
    RESET_CREATED_FORM_DATA,
    RESET_UNFINISHED_URL,
    SET_ESSAY_POST,
    SET_LOADING
} from './mutation-types'

import {
    UPDATE_LIST_ITEM
} from '../../content-list/mutation-types'

import {
    ACTION_GET_ESSAY_LIST
} from '../../content-list/action-types'

import { RESET_EDIT_FORM_DATA, UPDATE_DEFAULT_FORM_DATA } from '../mutation-types';

export const actions = {
    async [ACTION_GET_POST]({ commit }, payload) {
        commit(SET_LOADING, true)
        try {
            const response = await Api.get('/api/content/posts/essay/fetch-post', payload)
            commit(SET_ESSAY_POST, response.data)
            commit(SET_LOADING, false)
            return response
        } catch (e) {
            console.error(e)
            commit(SET_LOADING, false)
            return e
        }
    },
    async [ACTION_UPDATE_POST]({ commit, dispatch, rootState }, payload) {
        try {
            const response = await Api.put('/api/content/posts/essay/update', {
                post_id: payload.id,
                category_id: payload.category_id,
                locale: payload.locale,
                post_slug: payload.post_slug,
                cover_image_url: payload.cover_image_url,
                redirect_to: payload.redirect_to
            })
            if (!rootState.contentList.essays) {
                await dispatch(`contentList/${ACTION_GET_ESSAY_LIST}`)
            }
            commit(`contentList/${UPDATE_LIST_ITEM}`, { name: 'essays', data: response.data }, { root: true })
            dispatch(ACTION_GET_POST, {
                id: payload.id
            })
            commit(RESET_UNFINISHED_URL, { name: 'essay' })
            commit(RESET_EDIT_FORM_DATA, { name: 'essay' })
            commit(RESET_CREATED_FORM_DATA, { name: 'essay' })
            return response
        } catch (e) {
            console.error(e)
            return e
        }
    },
    async [ACTION_VALIDATE_SLUG](ctx, payload) {
        const response = await Api.get('/api/content/posts/essay/is-unique-slug', payload, { dialog: false })
        return response
    },
    async [ACTION_VALIDATE_REDIRECT](ctx, payload) {
        const response = await Api.get('/api/content/posts/essay/is-valid-redirect-to', payload, { dialog: false })
        return response
    },

    async [ACTION_CREATE_REVISION]({ commit }, payload) {
        try {
            const response = await Api.put('/api/content/posts/essay/create-revision', {
                post_id: payload.id,
                post_content: payload.post_content,
                post_excerpt: payload.post_excerpt,
                post_meta_title: payload.post_meta_title,
                post_meta_description: payload.post_meta_description,
                post_title: payload.post_title
            })
            response.data.post.checked = false
            commit(`contentList/${UPDATE_LIST_ITEM}`, { name: 'essays', data: response.data.post }, { root: true })
            commit(UPDATE_DEFAULT_FORM_DATA, { name: 'essay', response: response.data.post })
            commit(RESET_UNFINISHED_URL, { name: 'essay' })
            commit(RESET_EDIT_FORM_DATA, { name: 'essay' })
            commit(RESET_CREATED_FORM_DATA, { name: 'essay' })
            return response
        } catch (e) {
            console.error(e)
            return e
        }
    },
    async [ACTION_CREATE_POST]({ commit, dispatch, rootState }, payload) {
        try {
            const response = await Api.post('/api/content/posts/essay/create', { ...payload })
            console.log(JSON.parse(JSON.stringify(rootState)))
            if (!rootState.contentList.essays) {
                await dispatch(`contentList/${ACTION_GET_ESSAY_LIST}`, {}, { root: true })
            }
            commit(`contentList/${UPDATE_LIST_ITEM}`, { name: 'essays', data: response.data.post }, { root: true })
            if (response.data) {
                await commit(RESET_CREATED_FORM_DATA, { name: 'essay' })
            }
            await commit(RESET_UNFINISHED_URL, { name: 'essay' })
            return response
        } catch (e) {
            console.error(e)
            return e
        }
    }
}
