<template>
    <fieldset class="form-input__fieldset">
        <legend
            v-if="legend"
            class="form-input__legend"
            :class="{ 'error-font-color': validationError }"
            :style="{
                'background-color': bg_color
            }"
        >
            {{ legend }}
        </legend>
        <component
            :is="prependIcon"
            class="form-input__prepend-icon"
        />
        <input
            ref="input"
            v-model="val"
            class="form-input__input"
            :style="{'background-color': bg_color}"
            :class="{ 'error-border': validationError, 'success': !validationError && field, ...classes }"
            :type="type"
            :tabindex="tabindex"
            :autofocus="autofocus"
            :name="name"
            :placeholder="placeholder"
            :disabled="disabled"
            @input="onChange($event)"
            @blur="blur($event)"
        >
        <span @click="emitEvent">
            <component
                :is="appendIcon"
                class="form-input__append-icon"
            />
        </span>
        <span v-if="name === 'email'">
            <component
                :is="emailStatusIcon"
                class="form-input__append-icon"
            />
        </span>
        <!-- clearable -->
        <span
            v-if="clearable && value && value.length"
            @click="clearInput"
        >
            <CloseIcon
                class="form-input__append-icon"
            />
        </span>
        <!-- clearable -->

        <div
            v-if="validationError"
            class="form-input__error"
            :style="{
                'background-color': bg_color
            }"
        >
            {{ validationError }}
        </div>
    </fieldset>
</template>

<script>
import EmailOutline from 'mdi-vue/EmailOutline.vue'
import Lock from 'mdi-vue/Lock.vue'
import Account from 'mdi-vue/Account.vue'
import AccountOutline from 'mdi-vue/AccountOutline.vue'
import Eye from 'mdi-vue/Eye.vue'
import EyeOff from 'mdi-vue/EyeOff.vue'
import Magnify from 'mdi-vue/Magnify.vue'

import Close from 'mdi-vue/Close.vue'

export default {
    name: 'FormInput',
    components: {
        EmailOutlineIcon: EmailOutline,
        LockIcon: Lock,
        AccountIcon: Account,
        EyeIcon: Eye,
        EyeOffIcon: EyeOff,
        CloseIcon: Close,
        AccountOutline,
        MagnifyIcon: Magnify
    },
    props: {
        classes: {
            type: Object,
            default: () => ({})
        },
        tabindex: {},
        value: {},
        autofocus: {
            type: Boolean
        },
        disabled: {
            type: Boolean,
            default: false
        },
        prependIcon: {
            type: String
        },
        appendIcon: {
            type: String
        },
        legend: {
            type: String
        },
        type: {
            type: String
        },
        name: {
            type: String
        },

        field: {},
        placeholder: {
            type: String
        },
        errors: {
            type: String
        },
        is_valid: {
            type: Boolean
        },
        bg_color: {
            type: String
        },
        clearable: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean
        }
    },
    computed: {
        val: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        },
        validationError() {
            return this.errors ? this.errors : '';
        },
        emailStatusIcon() {
            // eslint-disable-next-line no-nested-ternary
            return this.loading && this.field ? 'LoadingIcon' : !this.validationError && this.field ? 'SuccessIcon' : this.validationError ? 'ErrorIcon' : ''
        }
    },
    mounted() {
        // console.log(this.isValid);
    },
    methods: {
        onChange(event) {
            this.$emit('update:field', event.target.value);
            this.$emit('onChange', event.target.value)
        },
        emitEvent() {
            this.$emit('clicked');
        },
        blur(event) {
            this.$emit('blur', event.target.value)
        },
        focus() {
            this.$refs.input.focus()
        },
        clearInput() {
            this.$emit('update:field', '');
            this.$emit('onChange', '')
        }
    }
};
</script>

<style lang="scss" >
.pr-0 {
    padding-right: 0!important;
    padding-left: 5px!important;
}

.form-input {
    font-family: 'Mark Pro', sans-serif !important;
    &__prepend-icon {
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translateY(-50%);
        color: rgba(0, 0, 0, 0.54);
        & + .form-input__input {
            padding-left: 40px;
        }
    }
    &__append-icon {
        position: absolute;
        cursor: pointer;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
        color: rgba(0, 0, 0, 0.54);
    }

    &__fieldset {
        border: 1px solid #27d91f;
        border-radius: 4px;
        max-height: 48px;
        height: 48px;
        padding: 0;
        position: relative;
        width: 100%;
        &.error-border {

            .form-input__error {
                padding: 0 3px;
            }
        }
    }

    &__legend {
        font-family: 'Mark Pro', sans-serif;
        display: table;
        color: #b3bbc6;
        caret-color: #b3bbc6;
        padding: 2px 5px 2px 5px;
        font-size: 80%;
        position: absolute;
        left: 6px;
        top: -8px;
        border: none;
        z-index: 1;
        background: #fff;
        line-height: 16px;
    }

    &__input {
        box-sizing: border-box;
        display: inline-block;
        padding: 10px 40px 10px 10px;
        width: 100%;
        user-select: text;
        line-height: 46px;
        outline: none;
        border-radius: 4px;
        font-family: 'Mark Pro', sans-serif;
        max-height: 48px;

        &:focus {
            border: 1px solid #27d91f !important;
            // border-left-color: transparent !important;
        }
        &[name=post_keyword_singular], &[name=post_keyword_plural]{
            text-transform: capitalize;
        }
    }
    &__error {
        font-family: 'Mark Pro', sans-serif;
        display: inline;
        width: auto;
        text-align: left;
        color: #ff5252;
        caret-color: #ff5252;
        font-size: 12px;
        min-height: 14px;
        position: relative;
        position: absolute;
        left: 13px;
        bottom: -7px;
        background: white;
    }
}
fieldset {
    padding: 0 !important;
}
</style>
