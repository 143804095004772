export const ACTION_GET_TASKS_LIST = 'ACTION_GET_TASKS_LIST'
export const ACTION_GET_TASKS_STATUSES = 'ACTION_GET_TASKS_STATUSES'
export const ACTION_GET_TASKS_PRIORITIES = 'ACTION_GET_TASKS_PRIORITIES'
export const ACTION_DELETE_TASK = 'ACTION_DELETE_TASK'

export const ACTION_CHANGE_STATUS_TASK = 'ACTION_CHANGE_STATUS_TASK'
export const ACTION_CHANGE_CATEGORY_TASK = 'ACTION_CHANGE_CATEGORY_TASK'
export const ACTION_CHANGE_PRIORITY_TASK = 'ACTION_CHANGE_PRIORITY_TASK'

export const ACTION_GET_TASK = 'ACTION_GET_TASK'
export const ACTION_CLEAR_FORM_DATA = 'ACTION_CLEAR_FORM_DATA'

export const ACTION_CREATE_TASK = 'ACTION_CREATE_TASK'
export const ACTION_UPDATE_TASK = 'ACTION_UPDATE_TASK'

export const ACTION_CREATE_COMMENT = 'ACTION_CREATE_COMMENT'
export const ACTION_DELETE_COMMENT = 'ACTION_DELETE_COMMENT'
export const ACTION_EDIT_COMMENT = 'ACTION_EDIT_COMMENT'

export const ACTION_TAKE_TASK = 'ACTION_TAKE_TASK'
export const ACTION_FINALIZE_TASK = 'ACTION_FINALIZE_TASK'
export const ACTION_COMPLETE_TASK = 'ACTION_COMPLETE_TASK'
