<template>
    <div
        class="link_type"
        :class="{ empty }"
        style="min-width: 40px"
        @click="handleClick"
    >
        <tooltip-component
            v-if="!empty"
            :text="row.info_payment"
        >
            <ReceiptIcon v-if="value === 'paypal_invoice'" />
            <AtIcon v-else-if="value === 'paypal_email'" />
        </tooltip-component>
    </div>
</template>

<script>
import TooltipComponent from '@/components/TooltipComponent'
import ReceiptIcon from 'mdi-vue/Receipt.vue';
import AtIcon from 'mdi-vue/At.vue';

export default {
    name: 'RowSeoLinkType',
    components: {
        ReceiptIcon,
        AtIcon,
        TooltipComponent
    },
    props: {
        value: {
            required: true
        },
        row: {
            type: Object,
            required: true
        }
    },
    computed: {
        empty() {
            return !this.value
        }
    },
    methods: {
        handleClick() {
            if (this.empty) { return }
            if (this.value === 'paypal_invoice') {
                window.open(this.row.info_payment, '_blank').focus();
            }
            if (this.value === 'paypal_email') {
                const range = document.createRange()
                const div = document.createElement('div')
                div.textContent = this.row.info_payment
                div.style.display = 'none'
                document.body.append(div)
                range.selectNode(div)
                window.getSelection().removeAllRanges()
                window.getSelection().addRange(range)
                document.execCommand('copy')
                window.getSelection().removeAllRanges()
                div.remove()
                this.$bus.$emit('showSnackBar', 'Email copied to clipboard', 'success')
            }
        }
    }
}
</script>

<style scoped>
    .link_type:not(.empty) {
        cursor: pointer;
    }
</style>
