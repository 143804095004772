import Api from '@/helpers/api/index.js'

import {
    ACTION_GET_LANDING_LIST,

    ACTION_LANDING_LOCK,
    ACTION_LANDING_UNLOCK,
    ACTION_LANDING_PUBLISH,
    ACTION_LANDING_UNPUBLISH,
    ACTION_LANDING_CHANGE_CATEGORY,
    ACTION_LANDING_DELETE_POST,

    ACTION_GET_BLOG_LIST,
    ACTION_BLOG_LOCK,
    ACTION_BLOG_UNLOCK,
    ACTION_BLOG_PUBLISH,
    ACTION_BLOG_UNPUBLISH,
    ACTION_BLOG_CHANGE_CATEGORY,
    ACTION_BLOG_DELETE_POST,

    ACTION_GET_ESSAY_LIST,
    ACTION_ESSAY_LOCK,
    ACTION_ESSAY_UNLOCK,
    ACTION_ESSAY_PUBLISH,
    ACTION_ESSAY_UNPUBLISH,
    ACTION_ESSAY_CHANGE_CATEGORY,
    ACTION_ESSAY_DELETE_POST

} from './action-types'

import {
    SET_LANDING_LIST,
    UPDATE_LIST_ITEMS,
    SET_BLOG_LIST,
    SET_ESSAY_LIST,
    SET_LOADING
} from './mutation-types'

export const actions = {
    // Landing
    async [ACTION_GET_LANDING_LIST]({ commit }, payload) {
        commit(SET_LOADING, true)
        try {
            const response = await Api.get('/api/content/posts/landing/list-posts', payload)
            response.data.data.forEach((item) => { item.checked = false })
            commit(SET_LANDING_LIST, response.data)
        } catch (e) {
            throw new Error(e)
        } finally {
            commit(SET_LOADING, false)
        }
    },
    async [ACTION_LANDING_LOCK]({ commit }, payload) {
        try {
            const response = await Api.put('/api/content/posts/landing/lock', payload)
            commit(UPDATE_LIST_ITEMS, { name: 'landings', data: response.data })
            return response
        } catch (e) {
            throw new Error(e)
        }
    },
    async [ACTION_LANDING_UNLOCK]({ commit }, payload) {
        try {
            const response = await Api.put('/api/content/posts/landing/unlock', payload)
            commit(UPDATE_LIST_ITEMS, { name: 'landings', data: response.data })
            return response
        } catch (e) {
            throw new Error(e)
        }
    },
    async [ACTION_LANDING_PUBLISH]({ commit }, payload) {
        try {
            const response = await Api.put('/api/content/posts/landing/publish', payload)
            commit(UPDATE_LIST_ITEMS, { name: 'landings', data: response.data })
            return response
        } catch (e) {
            throw new Error(e)
        }
    },
    async [ACTION_LANDING_UNPUBLISH]({ commit }, payload) {
        try {
            const response = await Api.put('/api/content/posts/landing/unpublish', payload)
            commit(UPDATE_LIST_ITEMS, { name: 'landings', data: response.data })
            return response
        } catch (e) {
            throw new Error(e)
        }
    },
    async [ACTION_LANDING_CHANGE_CATEGORY]({ commit }, payload) {
        try {
            const response = await Api.put('/api/content/posts/landing/set-category', payload)
            commit(UPDATE_LIST_ITEMS, { name: 'landings', data: response.data })
            return response
        } catch (e) {
            throw new Error(e)
        }
    },
    async [ACTION_LANDING_DELETE_POST]({ dispatch }, { posts, query }) {
        try {
            await Api.delete('/api/content/posts/landing/delete-many', { posts })
            dispatch(ACTION_GET_LANDING_LIST, query)
        } catch (e) {
            throw new Error(e)
        }
    },

    // Blog
    async [ACTION_GET_BLOG_LIST]({ commit }, payload) {
        commit(SET_LOADING, true)
        try {
            const response = await Api.get('/api/content/posts/blog/list-posts', payload)
            response.data.data.forEach((item) => { item.checked = false })
            commit(SET_BLOG_LIST, response.data)
        } catch (e) {
            throw new Error(e)
        } finally {
            commit(SET_LOADING, false)
        }
    },
    async [ACTION_BLOG_LOCK]({ commit }, payload) {
        try {
            const response = await Api.put('/api/content/posts/blog/lock', payload)
            commit(UPDATE_LIST_ITEMS, { name: 'blogs', data: response.data })
            return response
        } catch (e) {
            throw new Error(e)
        }
    },
    async [ACTION_BLOG_UNLOCK]({ commit }, payload) {
        try {
            const response = await Api.put('/api/content/posts/blog/unlock', payload)
            commit(UPDATE_LIST_ITEMS, { name: 'blogs', data: response.data })
            return response
        } catch (e) {
            throw new Error(e)
        }
    },

    async [ACTION_BLOG_PUBLISH]({ commit }, payload) {
        try {
            const response = await Api.put('/api/content/posts/blog/publish', payload)
            commit(UPDATE_LIST_ITEMS, { name: 'blogs', data: response.data })
            return response
        } catch (e) {
            throw new Error(e)
        }
    },

    async [ACTION_BLOG_UNPUBLISH]({ commit }, payload) {
        try {
            const response = await Api.put('/api/content/posts/blog/unpublish', payload)
            commit(UPDATE_LIST_ITEMS, { name: 'blogs', data: response.data })
            return response
        } catch (e) {
            throw new Error(e)
        }
    },

    async [ACTION_BLOG_CHANGE_CATEGORY]({ commit }, payload) {
        try {
            const response = await Api.put('/api/content/posts/blog/set-category', payload)
            commit(UPDATE_LIST_ITEMS, { name: 'blogs', data: response.data })
            return response
        } catch (e) {
            throw new Error(e)
        }
    },
    async [ACTION_BLOG_DELETE_POST]({ dispatch }, { posts, query }) {
        try {
            await Api.delete('/api/content/posts/blog/delete-many', { posts })
            dispatch(ACTION_GET_BLOG_LIST, query)
        } catch (e) {
            throw new Error(e)
        }
    },
    // Essay

    async [ACTION_GET_ESSAY_LIST]({ commit }, payload) {
        commit(SET_LOADING, true)
        if (!payload.category_id) { delete payload.category_id }
        try {
            const response = await Api.get('/api/content/posts/essay/list-posts', payload)
            response.data.data.forEach((item) => { item.checked = false })
            commit(SET_ESSAY_LIST, response.data)
        } catch (e) {
            throw new Error(e)
        }
        commit(SET_LOADING, false)
    },
    async [ACTION_ESSAY_LOCK]({ commit }, payload) {
        try {
            const response = await Api.put('/api/content/posts/essay/lock', payload)
            commit(UPDATE_LIST_ITEMS, { name: 'essays', data: response.data })
            return response
        } catch (e) {
            throw new Error(e)
        }
    },
    async [ACTION_ESSAY_UNLOCK]({ commit }, payload) {
        try {
            const response = await Api.put('/api/content/posts/essay/unlock', payload)
            commit(UPDATE_LIST_ITEMS, { name: 'essays', data: response.data })
            return response
        } catch (e) {
            throw new Error(e)
        }
    },

    async [ACTION_ESSAY_PUBLISH]({ commit }, payload) {
        try {
            const response = await Api.put('/api/content/posts/essay/publish', payload)
            commit(UPDATE_LIST_ITEMS, { name: 'essays', data: response.data })
            return response
        } catch (e) {
            throw new Error(e)
        }
    },

    async [ACTION_ESSAY_UNPUBLISH]({ commit }, payload) {
        try {
            const response = await Api.put('/api/content/posts/essay/unpublish', payload)
            commit(UPDATE_LIST_ITEMS, { name: 'essays', data: response.data })
            return response
        } catch (e) {
            throw new Error(e)
        }
    },

    async [ACTION_ESSAY_CHANGE_CATEGORY]({ commit }, payload) {
        try {
            const response = await Api.put('/api/content/posts/essay/set-category', payload)
            commit(UPDATE_LIST_ITEMS, { name: 'essays', data: response.data })
            return response
        } catch (e) {
            throw new Error(e)
        }
    },
    async [ACTION_ESSAY_DELETE_POST]({ dispatch }, { posts, query }) {
        try {
            await Api.delete('/api/content/posts/essay/delete-many', { posts })
            dispatch(ACTION_GET_ESSAY_LIST, query)
        } catch (e) {
            throw new Error(e)
        }
    }
}
