import { updateField } from 'vuex-map-fields';
import { BlogPost } from '@/models/Content/BlogPost'
import { LandingPost } from '@/models/Content/LandingPost'
import { EssayPost } from '@/models/Content/EssayPost'

import { cloneDeep } from 'lodash'

import {
    RESET_CREATED_FORM_DATA,
    RESET_EDIT_FORM_DATA,
    SET_LANDING_POST,
    SET_BLOG_POST,
    SET_ESSAY_POST,
    SET_LOADING,
    RESET_UNFINISHED_URL,
    UPDATE_DEFAULT_FORM_DATA
} from './mutation-types'

export const mutations = {
    updateField,
    [SET_LOADING](state, payload) {
        state.loading = payload
    },
    [UPDATE_DEFAULT_FORM_DATA](state, { name, response }) {
        console.log(state[name].default_form_data, response)
        state[name].default_form_data = { ...response }
    },
    [RESET_CREATED_FORM_DATA](state, { name }) {
        switch (name) {
        case 'landing':
            state.landing.form_data = new LandingPost()
            state.landing.default_form_data = new LandingPost()
            break;
        case 'blog':
            state.blog.form_data = new BlogPost()
            state.blog.default_form_data = new BlogPost()
            break;
        case 'essay':
            state.essay.form_data = new EssayPost()
            state.essay.default_form_data = new EssayPost()
            break;
        default:
            break;
        }
    },
    [RESET_EDIT_FORM_DATA](state, { name }) {
        switch (name) {
        case 'landing':
            state.landing.form_data = cloneDeep(state.landing.default_form_data)
            break;
        case 'blog':
            state.blog.form_data = cloneDeep(state.blog.default_form_data)
            break;
        case 'essay':
            state.essay.form_data = cloneDeep(state.essay.default_form_data)
            break;
        default:
            break;
        }
    },
    [SET_LANDING_POST](state, payload) {
        state.landing.form_data = cloneDeep(payload)
        state.landing.default_form_data = cloneDeep(payload)
    },
    [SET_BLOG_POST](state, payload) {
        state.blog.form_data = cloneDeep(payload)
        state.blog.default_form_data = cloneDeep(payload)
    },
    [SET_ESSAY_POST](state, payload) {
        state.essay.form_data = cloneDeep(payload)
        state.essay.default_form_data = cloneDeep(payload)
    },
    [RESET_UNFINISHED_URL](state, { name }) {
        state[name].unfinished_session = false
        state[name].unfinished_url = null
    }
}
