export default [
    {
        permission: 'files-legacy/manage',
        icon: 'page',
        text: 'Files',
        route: 'files-legacy-list',
        params: {
            page: 1,
            per_page: 10
        }
    },
    {
        permission: 'files-legacy/manage',
        icon: 'page',
        text: 'Files Addition',
        route: 'files-legacy-list-additions',
        params: {
            page: 1,
            per_page: 10
        }
    }
]
