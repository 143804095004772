export const permissionMock = [
    'content/landing/manage',
    'content/blog/manage',
    'content/essay/manage',
    'content-tasks/manage',
    'content/landing-category/manage',
    'content/blog-category/manage',
    'content/essay-category/manage',
    'content/landing/change',
    'content/blog/change',
    'content/essay/change',
    'content/blog/change-category',
    'content/landing/change-category',
    'content/delete',
    'content/essay/change-category',
    'content/guidelines/view',
    'content/lock',
    'content/manage',
    'content/publish',
    'content/suspend',
    'content/unlock',
    'content/update',
    'files-legacy/manage',
    'menu/manage',
    'permissions/manage',
    'seo/manage',
    'seo/outreach/delete',
    'seo/outreach/manage',
    'users/manage'
]
