<template>
    <div class="custom-table__row-checkbox">
        <form-checkbox
            color="$sidebar-orange"
            :value="row.checked"
            @input="handler"
        />
    </div>
</template>

<script>
import { eventBus } from '@/helpers/event-bus'

export default {
    name: 'RowCheckbox',
    props: {
        row: {
            type: Object
        }
    },
    methods: {
        handler() {
            eventBus.$emit('changeRowChecked', { rowId: this.row.id })
        }
    }
}
</script>

<style lang="scss">
    //.custom-table__row-checkbox .checkox-container label{
    //    width: 24px;
    //    min-width: 24px;
    //    height: 24px;
    //    &.checked {
    //        background: $orange;
    //        &:before{
    //            stroke: #fff;
    //        }
    //    }
    //}
</style>
