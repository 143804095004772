/* eslint-disable global-require */
export const includeFroalaConfig = () => {
    // docs are at https://froala.com/wysiwyg-editor/docs/plugins/

    // Require Froala Editor js file.
    require('froala-editor/js/froala_editor.pkgd.min.js')

    // Require Froala Editor css files.
    require('froala-editor/css/froala_editor.pkgd.min.css')
    require('froala-editor/css/froala_style.min.css')

    // SYSTEM PLUGINS

    // plugin quick insert
    require('froala-editor/css/plugins/quick_insert.min.css')
    require('froala-editor/js/plugins/quick_insert.min.js')

    // plugin char counter
    require('froala-editor/css/plugins/char_counter.min.css')
    require('froala-editor/js/plugins/char_counter.min.js')

    // plugin word paste
    require('froala-editor/js/plugins/word_paste.min.js')

    // plugin url
    require('froala-editor/js/plugins/url.min.js')

    // plugin font awesome
    // require('froala-editor/js/plugins/font_awesome.min.js')

    // plugin fullscreen
    require('froala-editor/css/plugins/fullscreen.min.css')
    require('froala-editor/js/plugins/fullscreen.min.js')

    // // plugin code mirror
    // require('dashboard/from_cdn/codemirror-5.3.0.min.css'),
    // require('dashboard/from_cdn/codemirror-5.3.0.min.js'),
    // require('dashboard/from_cdn/codemirror-xml.min.js'),

    // plugin code_view
    require('froala-editor/css/plugins/code_view.min.css')
    require('froala-editor/js/plugins/code_view.min.js')
    require('froala-editor/js/plugins/code_beautifier.min.js')

    // plugin print
    require('froala-editor/js/plugins/print.min.js')

    // plugin getPDF
    require('froala-editor/js/plugins/print.min.js')

    // plugin image
    require('froala-editor/css/plugins/image.min.css')
    require('froala-editor/js/plugins/image.min.js')

    // plugin imageTui
    // require('froala-editor/css/third_party/image_tui.min.css')
    // require('froala-editor/js/third_party/image_tui.min.js')

    // plugin video
    require('froala-editor/js/plugins/video.min.js')

    // plugin table
    require('froala-editor/js/plugins/table.min.js')

    // plugin files
    require('froala-editor/css/plugins/file.min.css')
    require('froala-editor/js/plugins/file.min.js')

    // plugin embedly
    require('froala-editor/css/third_party/embedly.min.css')
    require('froala-editor/js/third_party/embedly.min.js')

    // plugin colors
    require('froala-editor/css/plugins/colors.min.css')
    require('froala-editor/js/plugins/colors.min.js')

    // plugin emoticons
    require('froala-editor/js/plugins/emoticons.min.js')

    // plugin lists
    require('froala-editor/js/plugins/lists.min.js')

    // plugin align
    require('froala-editor/js/plugins/align.min.js')

    // plugin font family
    require('froala-editor/js/plugins/font_family.min.js')
    // plugin font size
    require('froala-editor/js/plugins/font_size.min.js')

    // plugin special characters
    require('froala-editor/css/plugins/special_characters.min.css')
    require('froala-editor/js/plugins/special_characters.min.js')

    // plugin spell checker
    require('froala-editor/css/third_party/spell_checker.min.css')
    require('froala-editor/js/third_party/spell_checker.min.js')

    // plugin link
    require('froala-editor/js/plugins/link.min.js')

    // plugin url
    require('froala-editor/js/plugins/url.min.js')

    // plugin quote
    require('froala-editor/js/plugins/quote.min.js')

    // plugin paragraph style
    require('froala-editor/js/plugins/paragraph_style.min.js')

    // plugin inline Style
    require('froala-editor/js/plugins/inline_style.min.js')

    // plugin paragraph format
    require('froala-editor/js/plugins/paragraph_format.min.js')

    require('froala-editor/js/plugins/inline_class.min.js')
}
