<template>
    <Comments
        v-click-outside="hidePopup"
        :comments="commentsList"
        @hideComments="hidePopup"
        @submitComment="submitComment"
        @deleteComment="deleteComment"
        @editCommit="editCommit"
    />
</template>

<script>
import Comments from '@/components/comments/CommentsPopup.vue';
import { cloneDeep } from 'lodash'

import {
    ACTION_CREATE_COMMENT,
    ACTION_DELETE_COMMENT,
    ACTION_EDIT_COMMENT
} from '@/store/modules/tasks/action-types'

import { createNamespacedHelpers } from 'vuex';

const {
    mapActions: mapTasksActions
} = createNamespacedHelpers('tasks');

export default {
    components: {
        Comments
    },
    props: {
        comments: {
            type: Array,
            required: true
        },
        id: {
            type: Number,
            required: true
        }
    },
    computed: {
        commentsList() {
            const list = cloneDeep(this.comments)
            return list.reverse()
        }
    },
    methods: {
        ...mapTasksActions([
            ACTION_CREATE_COMMENT,
            ACTION_DELETE_COMMENT,
            ACTION_EDIT_COMMENT
        ]),
        async submitComment(message) {
            try {
                await this.ACTION_CREATE_COMMENT({
                    task_id: this.id,
                    comment: message,
                    single: this.$route.name === 'edit-post-task'
                })
                this.$bus.$emit('showSnackBar', 'Comment was created', 'success')
            } catch (error) {
                this.$bus.$emit('showSnackBar', error, 'error')
            }
        },
        async deleteComment(id) {
            try {
                await this.ACTION_DELETE_COMMENT({
                    data: {
                        id,
                        task_id: this.id
                    },
                    single: this.$route.name === 'edit-post-task'
                })
                this.$bus.$emit('showSnackBar', 'Comment was delete', 'success')
            } catch (error) {
                this.$bus.$emit('showSnackBar', error, 'error')
            }
        },
        async editCommit(payload, id) {
            try {
                await this.ACTION_EDIT_COMMENT({
                    data: {
                        id,
                        comment: payload.comment,
                        task_id: this.id
                    },
                    single: this.$route.name === 'edit-post-task'
                })
                this.$bus.$emit('showSnackBar', 'Comment was update', 'success')
            } catch (error) {
                this.$bus.$emit('showSnackBar', error, 'error')
            }
        },
        hidePopup() {
            this.$emit('hideComments')
        }
    }
}
</script>

<style>

</style>
