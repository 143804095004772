const EssayCategory = () => import(
    '@/views/Content/Essay/EssayCategory'
)

const CreateEssayCategory = () => import(
    '@/views/Content/Essay/CreateEssayCategory'
)

const EditEssayCategory = () => import(
    '@/views/Content/Essay/EditEssayCategory'
)

export default [
    {
        path: '/content/category/list/essay',
        component: EssayCategory,
        name: 'content-essay-categories',
        meta: {
            requiresAuth: true,
            permission: 'content/manage',
            contentType: 'essay-categories',
            link: {
                routeAdd: 'content-essay-categories-create',
                edit_permission: 'content/essay/change'
            }
        },
        props: (route) => ({
            contentType: route.meta.contentType
        })
    },
    {
        path: '/admin/dashboard/view/posts/essay-categories/create',
        component: CreateEssayCategory,
        name: 'content-essay-categories-create',
        meta: {
            requiresAuth: true,
            permission: 'content/manage',
            contentType: 'essay-categories',
            link: {
                routeAdd: 'content-essay-categories-create',
                edit_permission: 'content/essay/change'
            }
        },
        props: (route) => ({
            contentType: route.meta.contentType
        })
    },
    {
        path: '/admin/dashboard/view/posts/essay-categories/edit/:id',
        component: EditEssayCategory,
        name: 'content-essay-categories-edit',
        meta: {
            requiresAuth: true,
            permission: 'content/manage',
            contentType: 'essay-categories',
            link: {
                routeAdd: 'content-essay-categories-create',
                edit_permission: 'content/essay/change'
            }
        },
        props: (route) => ({
            contentType: route.meta.contentType
        })
    }
]
