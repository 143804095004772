<template>
    <div class="subcategory_block">
        <div class="subcategory_count">
            {{ subcat.length }}
        </div>
        <div class="subcategory_list">
            <div
                v-for="(cat, id) in subcat"
                :key="id"
                class="row_category-subcategories"
            >
                <div class="row_category-subcategory">
                    {{ cat.title }}
                    <span class="subcategory-post_count">
                        {{ cat.posts_count }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RowSubCategory',
    props: {
        row: Object
    },
    computed: {
        subcat() {
            if (this.row.categories.length) {
                return this.row.categories
            }
            return []
        }
    }
}
</script>

<style scoped lang="scss">
    .subcategory_count {
        background-color: $round-count;
        color: $orange;
        border-radius: 50%;
        height: 30px;
        min-width: 30px;
        width: 30px;
        text-align: center;
        font-family: Mark Pro;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;
        margin: 8px 8px 8px 0px;
    }
    .subcategory_block {
        display: flex;
        align-items: flex-start;
    }
    .subcategory_list {
        display: flex;
        flex-wrap: wrap;
    }
    .row_category {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        &-subcategory {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #e0e0e0;
            border-radius: 16px;
            font-size: 14px;
            height: 32px;
            border-color: rgba(0,0,0,.12);
            color: rgba(0,0,0,.87);
            margin: 8px!important;
            padding: 0 6px 0 12px;
            line-height: 20px;
            max-width: 100%;
            outline: none;
            transition-timing-function: cubic-bezier(.4,0,.2,1);
            .subcategory-post_count {
                font-family: Roboto,sans-serif;
                font-weight: 300;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 8px;
                font-size: 14px;
                color: #fff;
                background-color: rgb(174, 174, 174);
                border-color: rgb(174, 174, 174);
                height: 24px!important;
                min-width: 24px!important;
                width: 24px!important;
                border-radius: 50%;
            }
        }
    }
</style>
