<template>
    <div
        v-click-outside="clickOutside"
        class="form-select-writers"
    >
        <fieldset
            :class="[openListItem || selectedWriters ? 'form-select-writers__fieldset active' : 'form-select-writers__fieldset']"
            @click.self="toggleList"
        >
            <legend class="form-select-writers__legend">
                {{ label }}
            </legend>
            <div class="form-select-writers__selected-block">
                <div
                    v-if="selectedWriters"
                    class="selected_user"
                    @click="toggleList"
                >
                    <div
                        v-if="selectedWriters.firstname"
                        class="form-select-writers__avatar form-select-writers__avatar--small"
                        @click="toggleList"
                    >
                        <img
                            v-if="selectedWriters.avatar_url"
                            :src="selectedWriters.avatar_url"
                            alt=""
                            class="select_avatar"
                            @click="toggleList"
                        >
                        <span
                            v-else
                            class="select_avatar select_avatar--default"
                            @click="toggleList"
                        >
                            {{ `${selectedWriters.firstname.charAt(0).toUpperCase() + selectedWriters.lastname.charAt(0).toUpperCase()}` }}
                        </span>
                    </div>
                    <span @click="toggleList">
                        <span
                            @click="toggleList"
                        >{{ selectedWriters.lastname }}</span>
                    </span>
                </div>
                <div
                    v-if="selectedWriters"
                    class="select_clear_icon"
                    @click="deleteWriter"
                />
                <span
                    v-else
                    class="form-select-writers__placeholder"
                    @click.self="toggleList"
                >
                    Select {{ label.toLowerCase() }}
                </span>
                <transition name="fade">
                    <ul
                        v-if="openListItem"
                        class="form-select-writers__list"
                    >
                        <li>
                            <div class="form-select-writers__search">
                                <Magnify class="form-select-writers__icon form-select-writers__icon--prepend" />
                                <input
                                    ref="searchInput"
                                    v-model="search"
                                    autocomplete="off"
                                    class="form-select-writers__input"
                                    placeholder="Enter a writer's nick or ID"
                                    type="text"
                                >
                                <span
                                    v-if="search.length > 0"
                                    @click.stop="clearSearchField()"
                                >
                                    <Close class="form-select-writers__icon form-select-writers__icon--append" />
                                </span>
                            </div>
                        </li>
                        <li
                            v-for="writer in filteredWriters"
                            :key="writer.sw_id"
                            @click="selectWriter(writer)"
                        >
                            <div
                                class="form-select-writers__list-item"
                            >
                                <img
                                    v-if="writer.avatar_url"
                                    :src="writer.avatar_url"
                                    alt=""
                                    class="select_avatar"
                                >
                                <span
                                    v-else
                                    class="select_avatar select_avatar--default"
                                >
                                    {{ `${writer.firstname.charAt(0).toUpperCase() + writer.lastname.charAt(0).toUpperCase()}` }}
                                </span>
                                <div class="form-select-writers__info">
                                    <span class="form-select-writers__info-title">{{ writer.lastname }}</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div
                                v-if="filteredWriters.length === 0"
                                class="form-select-writers__list-item"
                            >
                                No writer was found
                            </div>
                        </li>
                    </ul>
                </transition>
            </div>
        </fieldset>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import Close from 'mdi-vue/Close.vue';
import Magnify from 'mdi-vue/Magnify.vue';

import { createNamespacedHelpers } from 'vuex'

import {
    ACTION_GET_USERS_LIST
} from '@/store/modules/permissions/permissions-list/action-types'
import { mapContentListFields } from '@/store/modules/permissions/permissions-list'

const {
    mapActions: mapContentListActions
} = createNamespacedHelpers('permissionsList');

export default {
    name: 'FormSelectUser',
    components: {
        Close,
        Magnify
    },
    directives: {
        ClickOutside
    },
    props: {
        label: { type: String },
        initValue: {},
        disabled_items: { Array },
        name: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            openListItem: false,
            selectedWriters: null,
            search: ''
        };
    },
    computed: {
        ...mapContentListFields([
            'users'
        ]),
        filteredWriters() {
            const usersList = this.users.data
            return usersList ? usersList.filter((user) => !user.is_active && (user.firstname?.toLowerCase().includes(this.search.toLowerCase())
                || user.lastname?.toLowerCase().includes(this.search.toLowerCase())
                || `${user.id}`.toLowerCase().includes(this.search.toLowerCase()))) : []
        }
    },
    watch: {
        initValue: {
            handler() {
                this.initWriters()
            },
            deep: true
        }
    },
    activated() {
        this.ACTION_GET_USERS_LIST()
        this.initWriters()
    },
    methods: {
        ...mapContentListActions([
            ACTION_GET_USERS_LIST
        ]),
        selectWriter(writer) {
            this.selectedWriters = { ...writer }
            this.openListItem = false
            this.$emit('input', this.selectedWriters.id);
        },
        deleteWriter() {
            this.selectedWriters = null
            this.$emit('input', '');
        },
        initWriters() {
            if (this.initValue) {
                this.selectedWriters = this.users.data.find((item) => item.id === this.initValue)
                this.$emit('input', this.selectedWriters ? this.selectedWriters.id : '');
            } else {
                this.selectedWriters = null
                this.$emit('input', '');
            }
        },
        clickOutside() {
            this.openListItem = false
        },
        async toggleList() {
            if (!this.users.data) {
                await this.ACTION_GET_USERS_LIST()
            }
            this.openListItem = !this.openListItem;
            if (this.openListItem) {
                this.focusOnSearchInput()
            }
        },
        focusOnSearchInput() {
            this.$nextTick(() => {
                this.$refs['searchInput'].focus();
            });
        },
        clearSearchField() {
            this.search = '';
        }
    }
}
</script>

<style lang="scss">
.form-select-writers {
    outline: none;
    border-radius: 4px;
    min-height: 50px;

    .select_avatar{
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 15px;
        &--default{
            display: inline-block;
            background: $orange;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 12px;
            color: #fff;
        }
    }
    .select_clear_icon {
        width: 20px;
        height: 20px;
        position: relative;
        cursor: pointer;
        right: -15px;
        &::after {
            position: absolute;
            bottom: 50%;
            content: '';
            width: 100%;
            height: 2px;
            background-color: #000;
            transform: rotate(45deg);
        }
        &::before {
            position: absolute;
            bottom: 50%;
            content: '';
            width: 100%;
            height: 2px;
            background-color: #000;
            transform: rotate(-45deg);
        }
    }

    .selected_user{
        display: flex;
        align-items: center;
        .select_avatar{
            margin-right: 15px;
        }
    }

    &__icon {
        position: absolute;
        color: rgba(0,0,0,.54);

        &--prepend{
            top: 12px;
            left: 24px;
        }

        &--append {
            top: 10px;
            right: 12px;
            z-index: 10;
        }
    }

    &__fieldset {
        position: relative;
        outline: none;
        border-radius: 4px;
        border: 1px solid #D3D9E2;
        min-height: 48px;

        &.active {
            border: 1px solid #27d91f;
        }
    }

    &__legend {
        display: table;
        //color: #b3bbc6;
        padding: 2px 5px 2px 5px;
        //font-size: 80%;
        font-size: 14px;
        position: absolute;
        left: 6px;
        top: -12px;
        background-color: #fff;
        border: none;
    }

    &__input {
        box-sizing: border-box;
        display: inline-block;
        //padding-left: 75px;
        min-width: 100%;
        cursor: pointer;
        border: none;
        border-radius: 0;
        margin-left: 0;
        outline: none;
        font-family: 'Mark Pro', sans-serif;
        min-height: 46px;
        caret-color: #b3bbc6;
        border-bottom: 1px solid #D3D9E2;
        user-select: text !important;
        font-size: 14px;
        padding-left: 70px!important;
    }

    &__placeholder {
        position: absolute;
        left: 0px;
        right: 0;
        top: 0;
        bottom: 0;
        padding-left: 20px;
        color: rgba(0, 0, 0, .38);
        display: flex;
        align-items: center;
    }

    &__list {
        overflow-y: scroll;
        max-height: 410px;
        background-color: #fff;
        position: absolute;
        z-index: 10;
        top: 100%;
        left: 0;
        text-align: left;
        width: 100%;
        margin: 0;
        padding: 0;
        -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
        border-radius: 4px;
    }

    &__list-item {
        padding: 15px;
        background-color: #fff;
        margin-bottom: 1px;
        cursor: pointer;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #D3D9E2;

        &:last-child {
            border-bottom: none;
            color: rgba(0,0,0,.54);
        }

        .avatar-img {
            width: 40px;
            height: 40px;
            margin-right: 16px;
        }

        .form-select-writers__info {

            &-title {
                line-height: 1.2;
                margin-bottom: 2px;
            }

            &-subtitle {
                font-size: 14px;
                color: rgba(0,0,0,.6);
            }
        }

        &:hover {
            background-color: rgba(230, 230, 230, 0.3);
            //color: #FFF;
        }

        &--selected {
            background-color: rgba(230, 230, 230, 1);

            &:hover {
                background-color: rgba(230, 230, 230, 1);
                //color: #FFF;
            }

        }

        &--search {
            padding: 0;
        }
    }

    &__selected-block {
        display: flex;
        align-items: center;
        padding-left: 15px;
        min-height: 46px;
        margin-right: 25px;
        justify-content: space-between;
        cursor: pointer;
    }

    &__selected-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        height: 100%;
    }

    &__selected-item {
        position: relative;
        display: flex;
        margin-right: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
        align-items: center;
        //padding-top: 3px;

        .avatar-wrapper {
            width: 100%;
            height: 100%;

            .avatar-img {
                width: inherit;
                height: inherit;
            }

            .default-avatar  {
                width: 12px;
                height: 12px;
            }
        }
    }

    &__avatar {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        margin-right: 20px;

        &--small {
            width: 24px;
            height: 24px;
            margin-right: 10px;
        }

    }

    &__info {
        display: flex;
        flex-direction: column;
    }

    &__delete-btn {
        margin-left: 10px;
        cursor: pointer;
        color: #4caf50;
    }

    &__btn-icon {
        height: 18px;
        width: 18px;
    }
}

.form-select-writers__delete-btn.error {
    color: red;
}

.form-select-writers__fieldset.success {
    border: 1px solid #27d91f;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

@media screen and (max-width : 768px){

    .form-select-writers {

        &__input {
            padding-left: 0;
        }
    }
}
</style>
