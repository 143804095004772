export class SeoLink {
    constructor({
        url = '',
        url_in_bulk = '',
        price = '',
        type_payment = '',
        info_payment = ''
    } = {}) {
        this.url = url
        this.url_in_bulk = url_in_bulk
        this.price = price
        this.type_payment = type_payment
        this.info_payment = info_payment
    }
}

export function createSeoLink(data) {
    return Object.freeze(new SeoLink(data))
}
