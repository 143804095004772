<template>
    <div
        v-click-outside="clickOutside"
        class="form-select"
    >
        <fieldset :class="openListItem ? 'form-select__fieldset active' : 'form-select__fieldset'">
            <legend
                class="form-select__legend"
                :class="{ 'error': validationError }"
            >
                {{ label }}
            </legend>
            <input
                :class="{ 'error-border': validationError, 'success-border' : setValue, 'grey-border' : openListItem }"
                class="form-select__input"
                :value="setValue"
                type="text"
                :name="name"
                autocomplete="off"
                :placeholder="'Select ' + name"
                :disabled="disabled"
                @click.self="openListItem = !openListItem"
                @focus="$event.target.select()"
                @input="onInputChanged($event)"
            >
            <span
                class="status_value"
                :style="`background-color: ${getColorStatus(currentIndex)}`"
            >
                {{ setValue }}
            </span>
            <ul
                v-if="openListItem"
                class="form-select__list"
            >
                <li
                    v-for="(item, index) in items"
                    :key="index"
                    class="form-select__list-item"
                    @click="selectItem(item.value, index)"
                >
                    <span
                        class="status-control"
                        :style="`background-color: ${getColorStatus(index)}`"
                    >
                        {{ item ? item.text: '' }}
                    </span>
                </li>
            </ul>
            <div
                v-if="!disabled"
                class="select-arrow"
                @click.self="openListItem = !openListItem"
            >
                <arrow />
            </div>
            <div
                v-if="validationError"
                class="form-select__error"
            >
                {{ validationError }}
            </div>
        </fieldset>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import Arrow from '@/icons/Arrow';

export default {
    name: 'FormSelectStatus',
    components: {
        Arrow
    },
    directives: {
        ClickOutside
    },
    props: {
        value: {},
        items: { type: Array, default: () => [] },
        label: { type: String },
        errors: { type: String },
        escape: { type: Boolean },
        name: {
            required: true,
            type: String
        },
        disabled: Boolean
    },
    data() {
        return {
            openListItem: false,
            filteredItems: [],
            search: '',
            currentIndex: null
        };
    },
    computed: {
        setValue() {
            // eslint-disable-next-line eqeqeq
            const isExist = this.items.some((el) => el.value == this.search);
            if (isExist) {
                // eslint-disable-next-line eqeqeq
                return this.items.find((element) => element.value == this.search).text;
            }
            if (this.escape) {
                return ''
            }
            return this.search;
        },
        filteredList() {
            if (this.search === this.value) {
                return this.items
            }
            return this.items.filter((item) => item.text.toLowerCase().includes(this.search.toLowerCase()));
        },
        validationError() {
            return this.errors ? this.errors : '';
        }
    },
    watch: {
        value(value) {
            if (!value) {
                this.search = ''
                this.currentIndex = null
            } else {
                // eslint-disable-next-line eqeqeq
                this.search = this.items.find((item) => item.value == value).text
                // eslint-disable-next-line eqeqeq
                this.currentIndex = this.items.findIndex((item) => item.value == this.value)
            }
        }
    },
    created() {
        this.setInitValue();
    },
    methods: {
        getColorStatus(val) {
            val -= 1
            const arr = [
                'rgba(255, 84, 4, 0.2);',
                'rgba(39, 217, 31, 0.2);',
                'rgba(25, 118, 210, 0.2);',
                'rgba(25, 118, 210, 0.5);',
                '#EBDCF9',
                'rgba(79, 79, 79, 0.1);'
            ]
            return arr[val] || '#FFFFFF'
        },
        selectItem(value, index) {
            this.$emit('input', value);
            this.search = value
            this.openListItem = false;
            this.currentIndex = index
        },
        onInputChanged(event) {
            this.openListItem = true;
            this.search = event.target.value;
            if (this.search === '') {
                this.$emit('input', '');
            }
        },
        setInitValue() {
            // eslint-disable-next-line eqeqeq
            const item = this.items.find((item) => item.value == this.value)
            // eslint-disable-next-line eqeqeq
            this.currentIndex = this.items.findIndex((item) => item.value == this.value)
            if (item) {
                this.search = item.text
            }
        },
        clickOutside() {
            this.openListItem = false
        }
    }
};
</script>

<style lang="scss" scoped>
.status-control{
    padding: 10px 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
}
.status_value{
    position: absolute;
    left: 10px;
    top: 8px;
    padding: 10px 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
}
.form-select {
    outline: none;
    border-radius: 4px !important;
    max-height: 48px!important;
    width: 100%;
    position: relative;

    .select-arrow {
        position: absolute;
        right: 16px;
        top: 50%;
        margin-top: -11px;
        cursor: pointer;
        svg {
            transition: transform .2s ease;
            transform-origin: center center;
            width: 8px;
            transform: rotate(-90deg);
        }
    }
    &.success-border {
        border: 1px solid #27d91f;
    }
    &.grey-border {
        border: 1px solid #D3D9E2;
    }
    &__icon {
        position: absolute;
        top: 10px;
        left: 12px;
    }

    &__fieldset {
        position: relative;
        border: none ;
        outline: none;

    }

    &__legend {
        display: table;
        color: #b3bbc6;
        caret-color: #b3bbc6;
        padding: 2px 5px 2px 5px;
        font-size: 80%;
        position: absolute;
        left: 6px;
        top: -8px;
        background-color: white;
        border: none;
    }

    &__input {
        box-sizing: border-box;
        display: inline-block;
        width: 100%;
        cursor: pointer;
        border: 1px solid #D3D9E2;
        outline: none;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px ;
        font-family: 'Mark Pro', sans-serif ;
        font-size: 14px;
        max-height: 48px;
        height: 48px;
        padding: 1px 10px;
        font-size: 0;
    }

    &__list {
        overflow-y: scroll;
        max-height: 270px;
        background-color: white;
        position: absolute;
        z-index: 10;
        top: 55px;
        text-align: left;
        width: 100%;
        margin: 0;
        padding: 0;
        -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
        border-radius: 4px;
    }

    &__list-item {
        padding: 15px;
        background-color: #fff;
        margin-bottom: 1px;
        cursor: pointer;
        &:hover {
            background-color: lightgrey;
            //color: #FFF;
        }
    }
    &__error {
        display: inline;
        width: auto;
        text-align: left;
        color: #ff5252;
        caret-color: #ff5252;
        font-size: 12px;
        min-height: 14px;
        position: absolute;
        left: 13px;
        bottom: -7px;
        background: white;
        padding: 0 3px;
    }
}

.active {
    .select-arrow svg {
        transform: rotate(90deg);
    }
}

.form-select__input.success-border {
    border: 1px solid #27d91f;
}
</style>
