<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            r="11.25"
            transform="matrix(-1 0 0 1 12 12)"
            stroke="#FF0000"
            stroke-width="1.5"
        />
        <path
            d="M20 4.5L4.5 20"
            stroke="#FF0000"
            stroke-width="1.5"
        />
    </svg>
</template>

<script>
export default {
    name: 'StatusError'
}
</script>
