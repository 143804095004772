<template>
    <div class="custom-table__row-status">
        <div class="custom-table__row-cell__status">
            <tooltip-component
                v-if="row.paid_at"
                :text="row.paid_at"
            >
                <status-ok
                    class="custom-table__row-cell__status-ok"
                />
            </tooltip-component>
            <status-error
                v-else
                class="custom-table__row-cell__status-off"
            />
        </div>
    </div>
</template>

<script>
import TooltipComponent from '@/components/TooltipComponent'

export default {
    name: 'RowPaidAt',
    components: {
        TooltipComponent
    },
    props: {
        row: {}
    }
}
</script>

<style lang="scss" scoped>
    .custom-table__row-cell__status {
        &-ok, &-off {
            width: 28px;
            height: 28px;
        }
    }
    .custom-table__row-cell__status {
        display: flex;
        justify-content: center;
    }
</style>
