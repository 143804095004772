import { EssayPost } from '@/models/Content/EssayPost'

export const state = {
    loading: true,
    essay: {
        form_data: new EssayPost(),
        editing: false,
        default_form_data: new EssayPost(),
        unfinished_session: false,
        unfinished_url: null,
        unfinished_type: null,
        mode_type: ''
    }
}
