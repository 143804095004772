import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import { phone } from '@/store/modules/phone/index.js'
import { auth } from '@/store/modules/auth/index.js'
import { permissionsEdit } from '@/store/modules/permissions/permissions-edit/index.js'
import { permissionsList } from '@/store/modules/permissions/permissions-list/index.js'
import { contentEdit } from '@/store/modules/content/content-edit/index.js'
import { contentLandingEdit } from '@/store/modules/content/content-edit/landings/index.js'
import { contentBlogEdit } from '@/store/modules/content/content-edit/blogs/index.js'
import { contentEssayEdit } from '@/store/modules/content/content-edit/essay/index.js'
import { contentTaskEdit } from '@/store/modules/content/content-edit/tasks/index.js'
import { contentList } from '@/store/modules/content/content-list/index.js'
import { seoList } from '@/store/modules/seo/seo-list/index.js'
import { seoEdit } from '@/store/modules/seo/seo-edit/index.js'
import { contentCategory } from '@/store/modules/content/content-category';
import { contentRevisions } from '@/store/modules/content/content-revisions';
import { reviews } from '@/store/modules/content/reviews';
import { activityLog } from '@/store/modules/activity-log/index.js';
import { files } from '@/store/modules/files/index.js'
import { filesAdditional } from '@/store/modules/files-additional/index.js'
import { tasks } from '@/store/modules/tasks/index.js'

// helper class
import { VuexHelper } from '@/helpers/vuex/index.js'

Vue.use(Vuex)

// !!!!!!! --- this is important --- !!!!!!
const instance = new VuexHelper(process.env.VUE_APP_BUILD)
if (instance.hasChanged() === true) {
    // delete vuex in local storage
    console.log('%c Resetting vuex store!', 'background: #222; color: #bada55')
    instance.reset()
    instance.setNewId()
}
// !!!!!!! --- this is important --- !!!!!!

export default new Vuex.Store({
    plugins: [
        createPersistedState()
    ],
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        phone,
        auth,
        contentEdit,
        contentList,
        contentCategory,
        permissionsList,
        permissionsEdit,
        contentRevisions,
        seoList,
        seoEdit,
        contentLandingEdit,
        contentBlogEdit,
        contentEssayEdit,
        contentTaskEdit,
        reviews,
        files,
        filesAdditional,
        activityLog,
        tasks
    }
})
