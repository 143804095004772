import { updateField } from 'vuex-map-fields';

import {
    SET_CATEGORIES,
    UPDATE_CATEGORIES,
    ADD_CATEGORY,
    DELETE_CATEGORY,
    DELETE_SUBCATEGORY,
    SET_CATEGORIES_SELECT,
    SET_LOADING
} from './mutation-types'

export const mutations = {
    updateField,
    [SET_LOADING](state, payload) {
        state.loading = payload
    },
    [SET_CATEGORIES](state, { name, data }) {
        switch (name) {
        case 'landing':
            state.landing_categories = data;
            break;
        case 'blog':
            state.blog_categories = data;
            break;
        case 'essay':
            state.essay_categories = data;
            break;
        default:
            break
        }
    },
    [UPDATE_CATEGORIES](state, { name, data }) {
        let index = null
        switch (name) {
        case 'landing':
            index = state.landing_categories.findIndex((item) => item.id === data.id)
            if (index !== -1) state.landing_categories.splice(index, 1, data);
            break;
        case 'blog':
            index = state.blog_categories.findIndex((item) => item.id === data.id)
            if (index !== -1) state.blog_categories.splice(index, 1, data);
            break;
        case 'essay':
            index = state.essay_categories.findIndex((item) => item.id === data.id)
            if (index !== -1) state.essay_categories.splice(index, 1, data);
            break;
        default:
            break
        }
    },
    [ADD_CATEGORY](state, { name, data }) {
        switch (name) {
        case 'landing':
            state.landing_categories.push(data)
            break;
        case 'blog':
            state.blog_categories.push(data)
            break;
        case 'essay':
            state.essay_categories.push(data)
            break;
        default:
            break
        }
    },
    [DELETE_CATEGORY](state, { name, id }) {
        let index = null
        switch (name) {
        case 'landing':
            index = state.landing_categories.findIndex((item) => item.id === id)
            if (index !== -1) state.landing_categories.splice(index, 1)
            break;
        case 'blog':
            index = state.blog_categories.findIndex((item) => item.id === id)
            if (index !== -1) state.blog_categories.splice(index, 1)
            break;
        case 'essay':
            index = state.essay_categories.findIndex((item) => item.id === id)
            if (index !== -1) state.essay_categories.splice(index, 1)
            break;
        default:
            break
        }
    },
    [DELETE_SUBCATEGORY](state, { name, id, subId }) {
        let catIndex = null
        let subIndex = null
        switch (name) {
        case 'landing':
            catIndex = state.landing_categories.findIndex((item) => item.id === id)
            subIndex = state.landing_categories[catIndex].categories.findIndex((item) => item.id === subId)
            state.landing_categories[catIndex].categories.splice(subIndex, 1)
            break;
        case 'blog':
            catIndex = state.blog_categories.findIndex((item) => item.id === id)
            subIndex = state.blog_categories[catIndex].categories.findIndex((item) => item.id === subId)
            state.blog_categories[catIndex].categories.splice(subIndex, 1)
            break;
        case 'essay':
            catIndex = state.essay_categories.findIndex((item) => item.id === id)
            subIndex = state.essay_categories[catIndex].categories.findIndex((item) => item.id === subId)
            state.essay_categories[catIndex].categories.splice(subIndex, 1)
            break;
        default:
            break
        }
    },
    [SET_CATEGORIES_SELECT](state, { name, data }) {
        state.selectCategories[name] = data
    }
}
