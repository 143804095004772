<template>
    <div class="notice-messages">
        <transition-group
            name="notice-messages-list"
            tag="div"
        >
            <div
                v-for="notice in reversedList"
                :key="notice.id"
                :class="{
                    'notice-messages__item-success': notice.type === 'success',
                    'notice-messages__item-info': notice.type === 'info',
                    'notice-messages__item-error': notice.type === 'error',
                }"
                class="notice-messages__item"
            >
                <div class="notice-messages__item-inside">
                    <span class="notice-messages__item-inside__icon">
                        <img
                            v-if="notice.type === 'success'"
                            src="@/assets/img/svg/snack_success.svg"
                            alt=""
                        >
                        <img
                            v-if="notice.type === 'info'"
                            src="@/assets/img/svg/snack_info.svg"
                            alt=""
                        >
                        <svg
                            v-if="notice.type === 'error'"
                            x="0px"
                            y="0px"
                            viewBox="0 0 512 512"
                            style="enable-background:new 0 0 512 512;"
                            xml:space="preserve"
                            fill="#fff"
                        >
                            <g>
                                <path
                                    d="M507.494,426.066L282.864,53.537c-5.677-9.415-15.87-15.172-26.865-15.172c-10.995,0-21.188,5.756-26.865,15.172
                                L4.506,426.066c-5.842,9.689-6.015,21.774-0.451,31.625c5.564,9.852,16.001,15.944,27.315,15.944h449.259
                                c11.314,0,21.751-6.093,27.315-15.944C513.508,447.839,513.336,435.755,507.494,426.066z M256.167,167.227
                                c12.901,0,23.817,7.278,23.817,20.178c0,39.363-4.631,95.929-4.631,135.292c0,10.255-11.247,14.554-19.186,14.554
                                c-10.584,0-19.516-4.3-19.516-14.554c0-39.363-4.63-95.929-4.63-135.292C232.021,174.505,242.605,167.227,256.167,167.227z
                                 M256.498,411.018c-14.554,0-25.471-11.908-25.471-25.47c0-13.893,10.916-25.47,25.471-25.47c13.562,0,25.14,11.577,25.14,25.47
                                C281.638,399.11,270.06,411.018,256.498,411.018z"
                                />
                            </g>
                        </svg>
                    </span>

                    <div
                        class="notice-messages__item-inside__text"
                        v-html="notice.message"
                    />

                    <button
                        class="notice-messages__item-inside__close"
                        @click="delItem(notice.id)"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24px"
                            height="24px"
                        >
                            <path
                                d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41z"
                                fill="#fff"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </transition-group>
    </div>
</template>

<script>
import { eventBus } from '@/helpers/event-bus';

export default {
    name: 'SnackBar',
    data: () => ({
        list: []
    }),
    computed: {
        reversedList() {
            const list = [...this.list];
            return list.reverse();
        }
    },
    created() {
        eventBus.$on('showSnackBar', (msg, type) => {
            function randomInteger() {
                let rand = 0 - 0.5 + Math.random() * (999999999999999 + 1);
                rand = Math.round(rand);
                return rand;
            }

            const el = {
                message: msg,
                type,
                id: randomInteger()
            };

            // Сhecking for the same error messages
            // if (this.list.length > 0) {
            //     for (const item of this.list) {
            //         if (item.message === el.message) {
            //             return;
            //         }
            //     }
            // }

            this.list.push(el);
            setTimeout(this.delItem, 6000, el.id);
        });
    },
    methods: {
        delItem(id) {
            for (let i = 0; i < this.list.length; i += 1) {
                if (this.list[i].id === id) {
                    this.list.splice(i, 1);
                    return;
                }
            }
        }
    }
}
</script>
