import RowCheckbox from './RowCheckbox'
import RowCreatedAt from './RowCreatedAt'
import RowIndex from './RowIndex'
import RowPrice from './RowPrice'
import RowIsInMenu from './RowIsInMenu'
import RowLocale from './RowLocale'
import RowLocked from './RowLocked'
import RowPublished from './RowPublished'
import RowPaidAt from './RowPaidAt'
import RowRevisions from './RowRevisions'
import RowUpdatedAt from './RowUpdatedAt'
import RowUpdatedBy from './RowUpdatedBy'
import RowUrl from './RowUrl'
import RowWordcount from './RowWordcount'
import RowActions from './RowActions';
import RowCategoryName from './RowCategoryName';
import RowPostCount from './RowPostCount';
import RowName from './RowName';
import RowCheckList from './RowCheckList';
import RowChips from './RowChips';
import RowEmail from './RowEmail';
import RowSubCategory from './RowSubCategory';
import RowLink from './RowLink';
import RowLinkOutreachAcceptor from './RowLinkOutreachAcceptor';
import RowLinkOutreachDonor from './RowLinkOutreachDonor';
import RowBold from './RowBold';
import RowReviews from './RowReviews';
import RowLogName from './RowLogName';
import RowDescription from './RowDescription';
import RowProperties from './RowProperties';
import RowStatus from './RowStatus';
import RowDueDate from './RowDueDate';
import RowPriority from './RowPriority';
import RowTaskUrl from './RowTaskUrl';
import RowIp from './RowIp';
import RowNs from './RowNs';
import RowFileName from './RowFileName';
import RowFileType from './RowFileType';
import RowFileSize from './RowFileSize';
import RowFileComments from './RowFileComments';
import RowUserInfo from './RowUserInfo';
import RowSeoLinkType from './RowSeoLinkType';
import RowComments from './RowComments';

const RowElements = {
    install(Vue) {
        Vue.component(RowCreatedAt.name, RowCreatedAt)
        Vue.component(RowCheckbox.name, RowCheckbox)
        Vue.component(RowIndex.name, RowIndex)
        Vue.component(RowIsInMenu.name, RowIsInMenu)
        Vue.component(RowLocale.name, RowLocale)
        Vue.component(RowLocked.name, RowLocked)
        Vue.component(RowPublished.name, RowPublished)
        Vue.component(RowRevisions.name, RowRevisions)
        Vue.component(RowUpdatedAt.name, RowUpdatedAt)
        Vue.component(RowUpdatedBy.name, RowUpdatedBy)
        Vue.component(RowUrl.name, RowUrl)
        Vue.component(RowWordcount.name, RowWordcount)
        Vue.component(RowActions.name, RowActions)
        Vue.component(RowCategoryName.name, RowCategoryName)
        Vue.component(RowPostCount.name, RowPostCount)
        Vue.component(RowName.name, RowName)
        Vue.component(RowSubCategory.name, RowSubCategory)
        Vue.component(RowCheckList.name, RowCheckList)
        Vue.component(RowChips.name, RowChips)
        Vue.component(RowEmail.name, RowEmail)
        Vue.component(RowLink.name, RowLink)
        Vue.component(RowLinkOutreachAcceptor.name, RowLinkOutreachAcceptor)
        Vue.component(RowLinkOutreachDonor.name, RowLinkOutreachDonor)
        Vue.component(RowBold.name, RowBold)
        Vue.component(RowPrice.name, RowPrice)
        Vue.component(RowReviews.name, RowReviews)
        Vue.component(RowLogName.name, RowLogName)
        Vue.component(RowDescription.name, RowDescription)
        Vue.component(RowProperties.name, RowProperties)
        Vue.component(RowStatus.name, RowStatus)
        Vue.component(RowDueDate.name, RowDueDate)
        Vue.component(RowPriority.name, RowPriority)
        Vue.component(RowTaskUrl.name, RowTaskUrl)
        Vue.component(RowIp.name, RowIp)
        Vue.component(RowNs.name, RowNs)
        Vue.component(RowFileName.name, RowFileName)
        Vue.component(RowFileType.name, RowFileType)
        Vue.component(RowFileSize.name, RowFileSize)
        Vue.component(RowFileComments.name, RowFileComments)
        Vue.component(RowUserInfo.name, RowUserInfo)
        Vue.component(RowSeoLinkType.name, RowSeoLinkType)
        Vue.component(RowPaidAt.name, RowPaidAt)
        Vue.component(RowComments.name, RowComments)
    }
}

export default RowElements
