import Api from '@/helpers/api/index.js'

import {
    ACTION_CREATE_PERMISSION_POST,
    ACTION_CREATE_ROLE_POST,
    ACTION_CREATE_USER
} from './action-types'

import {
    RESET_CREATED_FORM_DATA
} from './mutation-types'

import {
    UPDATE_LIST_ITEM
} from '../permissions-list/mutation-types'
import { ACTION_GET_PERMISSIONS_LIST, ACTION_GET_ROLES_LIST } from '../permissions-list/action-types';

export const actions = {
    async [ACTION_CREATE_PERMISSION_POST]({ commit, dispatch, rootState }, payload) {
        try {
            const response = await Api.post('/api/permissions/permission/create', payload)
            if (!rootState.permissionsList.permissions) {
                await dispatch(`permissionsList/${ACTION_GET_PERMISSIONS_LIST}`, {}, { root: true })
            }
            commit(RESET_CREATED_FORM_DATA, { name: 'permission' })
            commit(`permissionsList/${UPDATE_LIST_ITEM}`, { name: 'permissions', data: response.data }, { root: true })
            return response.data
        } catch (e) {
            console.log(e)
            return e
        }
    },
    async [ACTION_CREATE_ROLE_POST]({ commit, dispatch, rootState }, payload) {
        try {
            const response = await Api.post('/api/permissions/role/create', payload)
            if (!rootState.permissionsList.roles) {
                await dispatch(`permissionsList/${ACTION_GET_ROLES_LIST}`, {}, { root: true })
            }
            commit(RESET_CREATED_FORM_DATA, { name: 'role' })
            commit(`permissionsList/${UPDATE_LIST_ITEM}`, { name: 'roles', data: response.data }, { root: true })
            return response
        } catch (e) {
            console.log(e)
            return e
        }
    },
    async [ACTION_CREATE_USER]({ commit }, payload) {
        try {
            const response = await Api.post('/api/users/create', payload)
            commit(RESET_CREATED_FORM_DATA, { name: 'user' })
            return response
        } catch (e) {
            console.log(e)
            return e
        }
    }
}
