export const RESET_CREATED_FORM_DATA = 'RESET_CREATED_FORM_DATA'
export const RESET_EDIT_FORM_DATA = 'RESET_EDIT_FORM_DATA'
export const SET_TASK_POST = 'SET_TASK_POST'
export const SET_LOADING = 'SET_LOADING'
export const RESET_UNFINISHED_URL = 'RESET_UNFINISHED_URL'
export const SET_STATUSES_LIST = 'SET_STATUSES_LIST'
export const SET_PRIORITIES_LIST = 'SET_PRIORITIES_LIST'
export const SET_COMMENT_SINGLE = 'SET_COMMENT_SINGLE'
export const CREATE_TASK = 'CREATE_TASK'
export const SET_DELETE_SINGLE = 'SET_DELETE_SINGLE'
export const SET_EDIT_SINGLE = 'SET_EDIT_SINGLE'
