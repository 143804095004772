<template>
    <Comments
        v-click-outside="hidePopup"
        :comments="commentsList"
        :show-buttons="false"
        @hideComments="hidePopup"
        @submitComment="submitComment"
        @deleteComment="deleteComment"
        @editCommit="editCommit"
    />
</template>

<script>
import Comments from '@/components/comments/CommentsPopup.vue';

import {
    ACTION_CREATE_COMMENT,
    ACTION_DELETE_COMMENT,
    ACTION_EDIT_COMMENT
} from '@/store/modules/files/action-types'

import {
    ACTION_CREATE_ADDITIONS_COMMENT
} from '@/store/modules/files-additional/action-types'

import { createNamespacedHelpers } from 'vuex';

const {
    mapActions: mapFilesActions
} = createNamespacedHelpers('files');

const {
    mapActions: mapFilesAdditionalActions
} = createNamespacedHelpers('filesAdditional');

export default {
    components: {
        Comments
    },
    props: {
        comments: {
            type: Array,
            required: true
        },
        id: {
            type: Number,
            required: true
        }
    },
    computed: {
        commentsList() {
            return this.comments
        }
    },
    methods: {
        ...mapFilesActions([
            ACTION_CREATE_COMMENT,
            ACTION_DELETE_COMMENT,
            ACTION_EDIT_COMMENT
        ]),
        ...mapFilesAdditionalActions([
            ACTION_CREATE_ADDITIONS_COMMENT
        ]),
        async submitComment(message) {
            if (this.$route.meta.contentType === 'files') {
                try {
                    await this.ACTION_CREATE_COMMENT({
                        checked_items: [this.id],
                        comment: message
                    })
                    this.$bus.$emit('showSnackBar', 'Comment was created', 'success')
                } catch (error) {
                    this.$bus.$emit('showSnackBar', error, 'error')
                }
            }
            if (this.$route.meta.contentType === 'files-addition') {
                try {
                    await this.ACTION_CREATE_ADDITIONS_COMMENT({
                        checked_items: [this.id],
                        comment: message
                    })
                    this.$bus.$emit('showSnackBar', 'Comment was created', 'success')
                } catch (error) {
                    this.$bus.$emit('showSnackBar', error, 'error')
                }
            }
        },
        async deleteComment(id) {
            try {
                await this.ACTION_DELETE_COMMENT({
                    id,
                    task_id: this.id
                })
                this.$bus.$emit('showSnackBar', 'Comment was delete', 'success')
            } catch (error) {
                this.$bus.$emit('showSnackBar', error, 'error')
            }
        },
        async editCommit(payload, id) {
            try {
                await this.ACTION_EDIT_COMMENT({
                    id,
                    comment: payload.comment,
                    task_id: this.id
                })
                this.$bus.$emit('showSnackBar', 'Comment was update', 'success')
            } catch (error) {
                this.$bus.$emit('showSnackBar', error, 'error')
            }
        },
        hidePopup() {
            this.$emit('hideComments')
        }
    }
}
</script>

<style>

</style>
