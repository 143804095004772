import Api from '@/helpers/api/index.js'

import {
    ACTION_CREATE_ESSAY_POST,
    ACTION_GET_ESSAY_POST,
    ACTION_UPDATE_ESSAY_POST,
    ACTION_VALIDATE_ESSAY_SLUG
} from './action-types'

import {
    RESET_CREATED_FORM_DATA,
    SET_ESSAY_POST,
    SET_LOADING,
    RESET_UNFINISHED_URL
} from './mutation-types'

import {
    UPDATE_LIST_ITEM
} from '../content-list/mutation-types'

export const actions = {
    // Essay

    async [ACTION_GET_ESSAY_POST]({ commit }, payload) {
        commit(SET_LOADING, true)
        try {
            const response = await Api.get('/api/content/posts/essay/fetch-post', payload)
            commit(SET_ESSAY_POST, response.data)
            commit(SET_LOADING, false)
            return response
        } catch (e) {
            console.log(e)
            commit(SET_LOADING, false)
            return e
        }
    },
    async [ACTION_CREATE_ESSAY_POST]({ commit }, payload) {
        try {
            const response = await Api.post('/api/content/posts/essay/create', { ...payload, image_id: 1257 }) // TODO: need to change image id !!!!!!!!!!!!
            if (response.data) {
                await commit(RESET_CREATED_FORM_DATA, { name: 'essay' })
            }
            await commit(RESET_UNFINISHED_URL, { name: 'essay' })
            return response
        } catch (e) {
            console.log(e)
            return e
        }
    },
    async [ACTION_UPDATE_ESSAY_POST]({ commit }, payload) {
        try {
            const response = await Api.put('/api/content/posts/essay/update', payload)
            commit(`contentList/${UPDATE_LIST_ITEM}`, { name: 'essays', data: response.data }, { root: true })
            commit(RESET_UNFINISHED_URL, { name: 'essay' })
            return response
        } catch (e) {
            console.log(e)
            return e
        }
    },
    async [ACTION_VALIDATE_ESSAY_SLUG](ctx, payload) {
        try {
            const response = await Api.get('/api/posts/essay/validation/slug', payload)
            return response.data
        } catch (e) {
            console.log(e)
            return e
        }
    }
}
