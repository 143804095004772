import FormInput from '@/components/common/form-elements/FormInput';
import FormInputRadio from '@/components/common/form-elements/FormInputRadio';
import FormCheckbox from '@/components/common/form-elements/FormCheckbox';
import FormRadioGroup from '@/components/common/form-elements/FormRadioGroup';
import FormSelect from '@/components/common/form-elements/FormSelect';
import CustomButton from '@/components/common/form-elements/CustomButton';
import PhoneNumber from '@/components/common/form-elements/phone/PhoneNumber';
import FormSelectCategory from '@/components/common/form-elements/FormSelectCategory';
import FormTextarea from '@/components/common/form-elements/FormTextarea';
import RangeDatePicker from '@/components/common/form-elements/RangeDatePicker';
import FormDatePicker from '@/components/common/form-elements/FormDatePicker.vue'
import FormSelectPriority from '@/components/common/form-elements/FormSelectPriority.vue'
import FormSelectStatus from '@/components/common/form-elements/FormSelectStatus.vue'
import FormSelectUser from '@/components/common/form-elements/FormSelectUser.vue'

const FormElements = {
    install(Vue) {
        Vue.component(FormInput.name, FormInput)
        Vue.component(FormInputRadio.name, FormInputRadio)
        Vue.component(FormCheckbox.name, FormCheckbox)
        Vue.component(FormRadioGroup.name, FormRadioGroup)
        Vue.component(FormSelect.name, FormSelect)
        Vue.component(CustomButton.name, CustomButton)
        Vue.component(PhoneNumber.name, PhoneNumber)
        Vue.component(FormSelectCategory.name, FormSelectCategory)
        Vue.component(FormTextarea.name, FormTextarea)
        Vue.component(RangeDatePicker.name, RangeDatePicker)
        Vue.component(FormDatePicker.name, FormDatePicker)
        Vue.component(FormSelectPriority.name, FormSelectPriority)
        Vue.component(FormSelectStatus.name, FormSelectStatus)
        Vue.component(FormSelectUser.name, FormSelectUser)
    }
}

export default FormElements
