import './assets/styles/index.scss'
import Vue from 'vue'
import JsonEditor from 'vue-json-edit'
import Clipboard from 'v-clipboard'
import Vuelidate from 'vuelidate'
import { eventBus } from '@/helpers/event-bus'
import VueFroala from 'vue-froala-wysiwyg'

import { includeFroalaConfig } from '@/helpers/froala/froalaPlugins.js'
import App from './App.vue'
import router from './router'
import store from './store'

// service worker
import './registerServiceWorker'

import RowElements from './components/CustomTable/ContentTable/components/row-elements'

import FormElements from './plugins/form-elements';
import Icons from './plugins/icons';
import scrollTo from './mixins/scrollTo';

Vue.mixin(scrollTo)

includeFroalaConfig()
Vue.use(Clipboard)
Vue.use(VueFroala)
Vue.config.productionTip = false
Vue.prototype.$bus = eventBus

Vue.use(Vuelidate)

Vue.use(FormElements)
Vue.use(RowElements)
Vue.use(Icons)
Vue.use(JsonEditor)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount('#app')
