<template>
    <div class="custom-table__row--ns">
        <p
            v-if="row.ns1"
        >
            ns1: {{ row.ns1 }}
        </p>
        <p
            v-if="row.ns2"
        >
            ns2: {{ row.ns2 }}
        </p>
    </div>
</template>

<script>
export default {
    name: 'RowNs',
    props: {
        row: Object
    }
}
</script>

<style scoped>

</style>
