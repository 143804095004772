<template>
    <modal-layout
        v-if="dialog"
        :size="'large'"
        :name="name"
        :hidden="hidden"
        :style="{ 'z-index': order }"
        @closeModal="cancelModal()"
        @hideModal="hideModal(name)"
    >
        <template #header>
            <h2>{{ title }}</h2>
        </template>
        <template #content>
            <div class="section-wrap">
                <div
                    v-for="(section, index) in getSection"
                    :key="index"
                    class="section"
                >
                    <h4>{{ section.name }}:</h4>
                    <div
                        v-for="(item, itemIndex) in section.items"
                        :key="itemIndex"
                    >
                        {{ item }}
                    </div>
                </div>
            </div>
        </template>
        <template #buttons>
            <custom-button
                default
                @on-btn-click="cancelModal(name)"
            >
                Ok
            </custom-button>
        </template>
    </modal-layout>
</template>

<script>
import modalsEventBusMixins from '@/mixins/modals/modalsEventBusMixins'
import { getSection } from '@/helpers/functions/helperSectionPermission'

export default {
    name: 'PermissiosModal',
    mixins: [modalsEventBusMixins],
    props: {
        name: String,
        title: String,
        permissions: Array
    },
    computed: {
        getSection() {
            return getSection(this.permissions)
        }
    }
}
</script>

<style scoped>
    .section-wrap {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 15px;
    }
    h4{
        font-size: 18px;
        text-transform: capitalize;
    }
    .section > div {
        font-size: 16px;
        padding: 10px;
    }
</style>
