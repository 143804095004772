import landing from './landing.js'
import landingEdit from './landing-edit.js'
import landingCategories from './landing-categories.js'
import landingRevisions from './landing-revisions.js'

import blog from './blog.js'
import blogEdit from './blog-edit.js'
import blogCategories from './blog-categories.js'
import blogRevisions from './blog-revisions.js'

import essay from './essay.js'
import essayEdit from './essay-edit.js'
import essayCategories from './essay-categories.js'
import essayRevisions from './essay-revisions.js'

import tasks from './tasks.js'
import tasksEdit from './tasks-edit.js'
import guidelines from './guidelines.js'

export default [
    ...landing,
    ...landingEdit,
    ...landingCategories,
    ...landingRevisions,
    ...blog,
    ...blogEdit,
    ...blogCategories,
    ...blogRevisions,
    ...essay,
    ...essayEdit,
    ...essayCategories,
    ...essayRevisions,
    ...tasks,
    ...tasksEdit,
    ...guidelines
]
