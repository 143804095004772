<template>
    <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.9165 17.5H12.0832V15.3333H9.9165V17.5ZM10.9998 0.166626C5.01984 0.166626 0.166504 5.01996 0.166504 11C0.166504 16.98 5.01984 21.8333 10.9998 21.8333C16.9798 21.8333 21.8332 16.98 21.8332 11C21.8332 5.01996 16.9798 0.166626 10.9998 0.166626ZM10.9998 19.6666C6.22234 19.6666 2.33317 15.7775 2.33317 11C2.33317 6.22246 6.22234 2.33329 10.9998 2.33329C15.7773 2.33329 19.6665 6.22246 19.6665 11C19.6665 15.7775 15.7773 19.6666 10.9998 19.6666ZM10.9998 4.49996C8.60567 4.49996 6.6665 6.43913 6.6665 8.83329H8.83317C8.83317 7.64163 9.80817 6.66663 10.9998 6.66663C12.1915 6.66663 13.1665 7.64163 13.1665 8.83329C13.1665 11 9.9165 10.7291 9.9165 14.25H12.0832C12.0832 11.8125 15.3332 11.5416 15.3332 8.83329C15.3332 6.43913 13.394 4.49996 10.9998 4.49996Z"
            fill="#1976D2"
        />
    </svg>
</template>

<script>
export default {
    name: 'StatusUnknown'
}
</script>
