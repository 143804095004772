<template>
    <div
        class="custom-table__row-reviews"
    >
        <p
            @click="openCommentsPopup"
        >
            <span
                class="bubble"
                :class="row.comments_count === 0 && 'bubble--empty'"
            >
                {{ row.comments_count }}
            </span>
        </p>
        <component
            :is="getCommentsComponent"
            v-if="showPopup"
            :id="row.id"
            v-click-outside="hidePopup"
            :comments="row.comments"
            @hideComments="hidePopup"
        />
    </div>
</template>

<script>
import TheCommentsTask from '@/components/comments/TheCommentsTask.vue';
import TheCommentsFile from '@/components/comments/TheCommentsFile.vue';

export default {
    name: 'RowComments',
    components: {
        TheCommentsTask,
        TheCommentsFile
    },
    props: {
        row: Object
    },
    data() {
        return {
            showPopup: false
        }
    },
    computed: {
        getCommentsComponent() {
            if (this.$route.name === 'posts-list-tasks') return 'the-comments-task'
            if (this.$route.name === 'files-legacy-list') return 'the-comments-file'
            if (this.$route.name === 'files-legacy-list-additions') return 'the-comments-file'
            return false
        }
    },
    methods: {
        openCommentsPopup() {
            this.showPopup = true
        },
        hidePopup() {
            this.showPopup = false
        }
    }
}
</script>

<style lang="scss" scoped>
.bubble{
    cursor: pointer;
    width: 60px;
    height: 60px;
    color: $orange;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $round-count;
    border-radius: 50%;
    &--empty{
        background: #e0e0e0;
        color: #000;
    }
}
.custom-table__row-reviews {
    position: static;
}
</style>
