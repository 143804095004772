import { SchemaAdmin } from '@/models/SchemaAdmin.js'

export const initialState = {
    auth: false,
    adminid: null,
    admin_data: null,
    form_data: new SchemaAdmin(),
    user: null
}

export const state = { ...initialState }
