import Helpers from '@/helpers/functions/index.js'
import HttpInstance from '@/helpers/http/index.js'
import Auth from '../auth/index.js'

const axios = HttpInstance.create();

class Api {
    constructor() {
        this.networkErrorMessage = 'Backend unreachable'
    }

    post(url, payload, options = {}) {
        return new Promise((resolve, reject) => {
            axios.post(url, payload, options)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    this.processError(error, options)
                    reject(error)
                })
        });
    }

    put(url, payload, options = { dialog: true }) {
        return new Promise((resolve, reject) => {
            axios.put(url, payload)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    this.processError(error, options)
                    reject(error)
                })
        });
    }

    delete(url, payload, options = { dialog: true }) {
        return new Promise((resolve, reject) => {
            axios.delete(url, { params: { ...payload } })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    this.processError(error, options)
                    reject(error)
                })
        });
    }

    get(url, payload, options = { dialog: true }) {
        return new Promise((resolve, reject) => {
            axios.get(url, { params: payload })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    this.processError(error, options)
                    reject(error)
                })
        })
    }

    async processError(error, options) {
        if (error.response.status === 401) {
            // token has expired. Frontend tries to send a request with an expired token and the backend rejects with a 401 code.
            // We update the front-end and clean up vuex and local storage.
            try {
                const message = 'Your session has expired, please log in.'
                Helpers.consoleLogWrapper(message, options)
                await Auth.logout()
            } catch (e) {
                console.log(e)
            } finally {
                setTimeout(() => { window.location.replace(`${window.location.origin}/login`) }, 2000)
            }
        } else if (error.response) {
            // this will throw a snackbar message for all HTTP errors
            // to prevent the above behavior, we must pass { dialog: false } in options
            const { data } = error.response
            // const { errors } = data
            // if (errors
            //     && errors.id
            //     && (errors.id[0] === 'The selected id is invalid.' || errors.id[0] === 'The id must be an integer.')) {
            //     window.location.replace(`${window.location.origin}/admin/404`)
            //     return
            // }
            let text = error.response.data.message
            if (data.errors) {
                const keys = Object.keys(data.errors)
                if (keys.length) {
                    const key = keys[0]
                    // eslint-disable-next-line prefer-destructuring
                    text = data.errors[key][0]
                }
            }
            Helpers.consoleLogWrapper(text, options)
        } else {
            // network error
            // to prevent the above behavior, we must pass { dialog: false } in options
            Helpers.consoleLogWrapper(this.networkErrorMessage, options)
        }
    }
}

export default new Api()
