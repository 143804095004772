export const EssayNames = {
    locale: 'Locale',
    post_meta_title: '<Meta Title> tag',
    post_meta_description: '<Meta Description> tag',
    post_slug: 'Slug',
    post_title: 'H1 Title',
    category_id: 'Category',
    post_excerpt: 'Excerpt',
    post_content: 'Content'
}
