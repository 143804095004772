import { updateField } from 'vuex-map-fields';

import { cloneDeep } from 'lodash'

import {
    RESET_CREATED_FORM_DATA,
    SET_LOADING
} from './mutation-types'

export const mutations = {
    updateField,
    [RESET_CREATED_FORM_DATA](state, { name }) {
        switch (name) {
        case 'pbn':
            state.pbn.form_data = cloneDeep(state.pbn.default_form_data)
            break;
        case 'outreach':
            state.outreach.form_data = cloneDeep(state.outreach.default_form_data)
            break;
        default:
            break;
        }
    },
    [SET_LOADING](state, loading = true) {
        state.loading = loading
    }
}
