export class User {
    constructor({
        firstname = '',
        lastname = '',
        nickname = '',
        email = '',
        password = '',
        phone = '',
        country = ''
    } = {}) {
        this.firstname = firstname
        this.lastname = lastname
        this.nickname = nickname
        this.email = email
        this.password = password
        this.phone = phone
        this.country = country
    }
}

export function createUser(data) {
    return Object.freeze(new User(data));
}
