<template>
    <div
        class="custom-table__row-revisions"
    >
        <span v-if="row.revisions_count">
            <router-link
                :to="{
                    name: `revisions-list-${contentType}`,
                    params: {
                        id: row.id
                    }
                }"
            >
                {{ row.revisions_count }}
                <span
                    v-if="row.revisions_count_new > 0"
                    class="custom-table__row-revisions--new"
                >
                    {{ row.revisions_count_new }}
                </span>
            </router-link>
        </span>
    </div>
</template>

<script>
export default {
    name: 'RowRevisions',
    props: {
        row: {
            type: Object
        },
        contentType: String
    }
}
</script>
