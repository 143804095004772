export class Task {
    constructor({
        post_type = '',
        subject = '',
        description = '',
        priority = 1,
        status = 0,
        deadline_at = '',
        assignee_id = 0,
        locale = '',
        words = '',
        post_links = ''
    } = {}) {
        this.post_type = post_type
        this.subject = subject
        this.locale = locale
        this.description = description
        this.priority = priority
        this.status = status
        this.deadline_at = deadline_at
        this.assignee_id = assignee_id
        this.words = words
        this.post_links = post_links
    }
}

export function createTask(data) {
    return Object.freeze(new Task(data))
}
