import moment from 'moment'

export default {
    filters: {
        // eslint-disable-next-line consistent-return
        money(value) {
            if (value >= 0) {
                value = Number(value).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
                return `$${value}`
            }
        },
        credits_balance(value) {
            value = Number(value).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
            return `$${value}`
        },
        // eslint-disable-next-line consistent-return
        number(value) {
            if (value) {
                value = Number(value).toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
                return value
            }
        },
        pages(value) {
            if (value === 1) {
                return `${value} page`
            }

            return `${value} pages`
        },
        percent(value) {
            // return value * 100 + "%"
            return `${Math.round(+value * 10000) / 100}%`
        },
        short(value) {
            if (value) {
                return `${value.substr(0, 15)}...`
            }
            throw new Error('filter short error')
        },
        moment_from(value) {
            return moment(value).fromNow(true)
        },
        moment_to_now(value) {
            return moment().add(value, 'hours').format('D MMM')
        },
        moment_to_now_custom(value) {
            return moment().add(value, 'hours').fromNow(true)
        },
        moment_total(value) {
            return moment().add(value, 'hours').format('MMM D dddd, HH:mm')
        },
        moment_full_text(value) {
            return moment(value).format('LLLL')
        },
        moment_short_text(value) {
            return moment(value).format('MMM D, YYYY h:mm A')
        },
        moment_bidding_text(value) {
            return moment(value).format('MMM D, YYYY')
        },
        moment_day_text(value) {
            return moment(value).format('D MMM')
        },
        file_size(value) {
            let num = value
            if (typeof num !== 'number' || Number.isNaN(num)) {
                throw new TypeError('Expected a number');
            }
            const neg = num < 0;
            const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            if (neg) {
                num = -num;
            }
            if (num < 1) {
                return `${(neg ? '-' : '') + num} B`;
            }
            const exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
            num = (num / (1000 ** exponent)).toFixed(2) * 1;
            const unit = units[exponent];
            return `${(neg ? '-' : '') + num} ${unit}`;
        },
        shortName(value) {
            // used in the Attachments.vue
            if (!value) return '';
            value = value.toString();

            if (value.length > 15) {
                const fileType = value.split('.').slice(-1).join();
                const fileName = value.split('.').slice(0, 1).join();
                return `${fileName.substring(0, 10)}...${fileType}`;
            }
            return value
        },
        fileType(value) {
            // used in the Attachments.vue
            if (!value) return '';
            value = value.toString();

            if (value) {
                return value.split('.').slice(-1).join();
            }
            throw new Error('fileType error')
        },

        paragraph(value) {
            return value.replace(/\n/g, '<br />')
        },

        ucfirst(value) {
            return value.charAt(0).toUpperCase() + value.slice(1);
        },
        wordQuantity(value, qty) {
            return qty > 1 ? `${value}s` : value
        },
        paragraphAddBreaks(value) {
            const newValue = value.replace(/(?:\\r\\n|\\r|\\n)/g, '<br>')
            return newValue
        },
        striphtml(value) {
            const div = document.createElement('div');
            div.innerHTML = value;
            const text = div.textContent || div.innerText || '';
            return text;
        },
        orderStatusRewrittenForUser(value) {
            switch (value) {
            case 'UNPAID':
                return 'Unpaid'

            case 'PAID':
                return 'Paid'

            case 'PENDING':
                return 'In progress'

            case 'COMPLETE':
                return 'Complete'

            case 'REVISION':
                return 'Revision'

            case 'REFUND':
                return 'Refund'

            case 'RETURN':
                return 'Return'

            case 'CREDIT':
                return 'Credit'

            default:
                return value
            }
        }

    }
}
