<template>
    <div
        class="custom-table__row-updated-at custom-table__row-cell__data"
    >
        <span v-if="row.updated_at">
            {{ row.updated_at | moment_from }} ago
        </span>
        <span v-else-if="row.created_at">
            {{ row.created_at | moment_from }} ago
        </span>
    </div>
</template>

<script>
import eventsMixin from '@/mixins/content/eventsMixin'
import filtersMixin from '@/mixins/filtersMixin'

export default {
    name: 'RowUpdatedAt',
    mixins: [
        eventsMixin,
        filtersMixin
    ],
    props: {
        row: {
            type: Object
        }
    }
}
</script>

<style lang="scss">
    .custom-table__row-updated-at {
        //font-family: Roboto,sans-serif!important;
        font-weight: 300;
    }
</style>
