<template>
    <div
        ref="price_wrap"
        @click="startEdit"
    >
        <div
            v-if="!editMode"
            class="custom-table__row-name"
        >
            ${{ value || 0 }}
        </div>
        <form-input
            v-else
            v-model="editingValue"
            :classes="{
                'pr-0': true
            }"
            field="editingValue"
            legend="Price"
            name="Price"
            placeholder="Price"
            @blur="onChange"
            @keydown.enter.native="onChange"
        />
    </div>
</template>

<script>
export default {
    name: 'RowPrice',
    props: {
        value: {
            type: String
        },
        row: {}
    },
    data() {
        return {
            editMode: false,
            editingValue: ''
        }
    },
    computed: {
        isEditable() {
            return this.$route.name !== 'posts-list-landing'
        }
    },
    methods: {
        startEdit() {
            if (!this.isEditable || this.row.paid) { return }
            this.editMode = true
            this.editingValue = this.value
            this.$nextTick(() => {
                this.$refs.price_wrap.querySelector('input').focus()
            })
        },
        stopEdit() {
            this.editMode = false
        },
        onChange() {
            this.stopEdit()
            this.$emit('action', { id: this.row.id, price: this.editingValue })
        }
    }
}
</script>

<style scoped>

</style>
