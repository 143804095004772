import {
    required,
    minLength
} from 'vuelidate/lib/validators';

export const validationRules = {
    text: {
        required,
        minLength: minLength(4)
    }
};
