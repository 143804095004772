import { updateField } from 'vuex-map-fields';

import {
    SET_ACTIVITY_LOG,
    SET_ACTIVITY_LOG_FROM_ID
} from './mutation-types'

export const mutations = {
    updateField,
    [SET_ACTIVITY_LOG](state, payload) {
        state.activityLog = payload
    },

    [SET_ACTIVITY_LOG_FROM_ID](state, payload) {
        state.activityLogFromId = payload
    }
}
