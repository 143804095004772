import { updateField } from 'vuex-map-fields';

import {
    SET_PBN_LIST,
    SET_LOADING,
    SET_OUTREACH_LIST,
    UPDATE_LIST_ITEM,
    UPDATE_LIST_ITEMS,
    UPDATE_BULK_SENDING_STATUS
} from './mutation-types'

export const mutations = {
    updateField,
    [SET_PBN_LIST](state, payload) {
        state.pbn = payload
    },
    [SET_OUTREACH_LIST](state, payload) {
        state.outreach = payload
    },
    [SET_LOADING](state, value) {
        state.loading = value
    },
    [UPDATE_LIST_ITEM](state, { name, data, save_position = false }) {
        const index = state[name].data.findIndex((item) => item.id === data.id)
        if (save_position) {
            state[name].data.splice(index, 1, { ...data, is_updated: true, checked: false })
            return
        }
        if (index !== -1) state[name].data.splice(index, 1)
        data && state[name].data.unshift({ ...data, is_updated: true, checked: false })
    },
    [UPDATE_LIST_ITEMS](state, { name, data }) {
        data.forEach((row) => {
            const index = state[name].data.findIndex((item) => item.id === row.id)
            if (index !== -1) {
                state[name].data.splice(index, 1)
            }
            state[name].data.unshift({ ...row, is_updated: true, checked: false })
        })
    },
    [UPDATE_BULK_SENDING_STATUS](state, text) {
        state.bulk_status = text
    }
}
