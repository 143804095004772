// auth user
const Login = () => import(
    '@/views/Auth/Login'
)
const Register = () => import(
    '@/views/Auth/Register'
)
const Forgot = () => import(
    '@/views/Auth/ForgotPassword'
)
const ResetPassword = () => import(
    '@/views/Auth/ResetPassword'
)

export default [
    { // login
        path: '/login',
        component: Login,
        name: 'login'
    },
    { // forgot password
        path: '/forgot-password',
        component: Forgot,
        name: 'forgot-password'
    },
    { // register
        path: '/register',
        component: Register,
        name: 'register'
    },
    { // reset pass
        path: '/auth/client/password/show-reset-form/:token',
        component: ResetPassword,
        name: 'reset'
    }
]
