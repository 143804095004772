<template>
    <div class="navbar-edit__buttons">
        <custom-button
            default
            class="blue-button"
            @on-btn-click="create"
        >
            Create
        </custom-button>
        <custom-button
            default
            class="grey-button"
            @on-btn-click="reset"
        >
            Reset
        </custom-button>
    </div>
</template>

<script>
export default {
    name: 'CreateButtons',
    methods: {
        create() {
            this.$bus.$emit('createPost', this.$route.name)
        },
        reset() {
            this.$bus.$emit('resetPost', this.$route.name)
        }
    }
}
</script>

<style scoped>

</style>
