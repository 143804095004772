const LandingCategory = () => import(
    '@/views/Content/Landing/LandingCategory'
)

const CreateLandingCategory = () => import(
    '@/views/Content/Landing/CreateLandingCategory'
)

const EditLandingCategory = () => import(
    '@/views/Content/Landing/EditLandingCategory'
)

export default [
    {
        path: '/content/category/list/landing',
        component: LandingCategory,
        name: 'content-landing-categories',
        meta: {
            requiresAuth: true,
            permission: 'content/manage',
            contentType: 'landing-categories',
            link: {
                edit_permission: 'content/landing/change',
                routeAdd: 'content-landing-categories-create'
            }
        },
        props: (route) => ({
            contentType: route.meta.contentType
        })
    },
    {
        path: '/admin/dashboard/view/posts/landing-categories/create',
        component: CreateLandingCategory,
        name: 'content-landing-categories-create',
        meta: {
            requiresAuth: true,
            permission: 'content/manage',
            contentType: 'landing-categories',
            link: {
                edit_permission: 'content/landing/change',
                routeAdd: 'content-landing-categories-create'
            }
        },
        props: (route) => ({
            contentType: route.meta.contentType
        })
    },
    {
        path: '/admin/dashboard/view/posts/landing-categories/edit/:id',
        component: EditLandingCategory,
        name: 'content-landing-categories-edit',
        meta: {
            requiresAuth: true,
            permission: 'content/manage',
            contentType: 'landing-categories',
            link: {
                edit_permission: 'content/landing/change',
                routeAdd: 'content-landing-categories-create'
            }
        },
        props: (route) => ({
            contentType: route.meta.contentType
        })
    }
]
