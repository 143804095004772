<template>
    <div class="tooltip__wrap">
        <div class="tooltip__wrap--content">
            <slot />
        </div>
        <div
            v-if="text"
            class="tooltip__wrap--tooltip"
        >
            {{ text }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'TooltipComponent',
    props: {
        text: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss">
    .tooltip__wrap{
        position: relative;
        &--content {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 100%;
            overflow: hidden;
        }
        &--tooltip {
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 4px;
            background: grey;
            color: #fff;
            display: none;
            min-width: 80px;
            font-size: 14px;
            align-items: center;
            justify-content: center;
            padding: 5px;
            line-height: 15px;
         }
        &:hover {
            .tooltip__wrap--tooltip {
                display: flex;
            }
        }
    }
</style>
