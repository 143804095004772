export default {
    methods: {
        scrollTo(to) {
            const element = document.querySelector(to)
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }
        }
    }
}
